import PageTitle from '../../shared/components/page-title'
import { MainContainer } from '../../shared/components/templates/main-container/main-container'
import { ChatContainer } from './style'
const Chat = () => {
  const data = {
    title: 'Chat',
    subtitle:
      'Tenha uma comunicação ativa e sinta-se parte de uma comunidade ativa, onde você irá perceber que outros vivem as mesmas dificuldades que você. Onde você poderá ser ajudado e poderá ajudar outros também. Pois a sua dor hoje, pode ser a sua causa amanhã!'
  }
  return (
    <MainContainer>
      <ChatContainer>
        <PageTitle prop={data} />
      </ChatContainer>
    </MainContainer>
  )
}
export default Chat
