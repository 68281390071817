import { gql } from "@apollo/client";

const CREATE_USER_MUTATION = gql`
  mutation CreateUser(
    $name: String!
    $email: String!
    $password: String!
    $phoneNumber: String!
  ) {
    createUser(
      input: {
        name: $name
        email: $email
        password: $password
        phoneNumber: $phoneNumber
      }
    )
  }
`;
export default CREATE_USER_MUTATION;
