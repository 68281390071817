import { gql } from "@apollo/client";

const LIST_EXERCISE_MEMORIES = gql`
  query ListExerciseMemories($page: Int!, $perPage: Int!) {
    listExerciseMemories(input: { page: $page, perPage: $perPage }) {
      description
      _id
    }
  }
`;

export default LIST_EXERCISE_MEMORIES;
