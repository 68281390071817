import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Checkbox, Skeleton } from "@mui/material";
import { useRef, useState } from "react";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import {
  AiFillCheckCircle,
  AiFillClockCircle,
  AiFillPlayCircle,
  AiFillPlusCircle,
} from "react-icons/ai";
import { BsFillFilterCircleFill } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import { RiEdit2Fill } from "react-icons/ri";
import Swal from "sweetalert2";
import { CardTaskTitle } from "../../../pages/activities/components/activity-card/style";
import { TQuickTask } from "../../models/dashboard/pillars.model";
import { ActivitiesServices } from "../../services/activities/activities.service";
import {
  Card,
  CardHead,
  CardTask,
  CardTaskBody,
  CardTaskBodyTop,
  CardTitle,
  CardTitleToView,
  CustomFilterOption,
  FilterHeader,
  LoadingSkeleton,
} from "./style";

const TaskComponent = ({
  tasks,
  isMobile,
  openTaskModal,
  isToView,
  loading,
  filterCompletedTasks,
  filterFavoritedTasks,
  filterCycleTasks,
  onFilterChange,
  onOpenCreateTask,
  onHandleEditTask,
  refresh,
}: any) => {
  const dropdownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [hoveredTaskId, setHoveredTaskId] = useState<string | null>(null);
  const [editModeTask, setEditModeTask] = useState(false);
  const [deleteModeTask, setDeleteModeTask] = useState(false);
  const _activitiesService = new ActivitiesServices();

  const lightenColor = (color: string, percent: number) => {
    color = color.replace("#", "");
    const r = parseInt(color.slice(0, 2), 16);
    const g = parseInt(color.slice(2, 4), 16);
    const b = parseInt(color.slice(4, 6), 16);
    const newR = Math.min(255, r + (255 - r) * (percent / 100));
    const newG = Math.min(255, g + (255 - g) * (percent / 100));
    const newB = Math.min(255, b + (255 - b) * (percent / 100));
    const lighterColor = `#${Math.round(newR).toString(16)}${Math.round(
      newG
    ).toString(16)}${Math.round(newB).toString(16)}`;

    return lighterColor;
  };

  const handleDropdownClick = (e: any) => {
    e.stopPropagation();
  };
  const handleFilterClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleTaskMouseEnter = (taskId: string | null) => {
    setHoveredTaskId(taskId);
  };

  const handleTaskMouseLeave = () => {
    setHoveredTaskId(null);
  };

  const handleFilterChange = (
    completed: boolean,
    favorited: boolean,
    cycle: boolean
  ) => {
    onFilterChange(completed, favorited, cycle);
  };

  const handleDeleteTask = async (task: TQuickTask) => {
    const result = await Swal.fire({
      title: "Tem certeza?",
      text: "Você não poderá reverter isso!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00DF70",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Sim, delete!",
    });

    if (result.isConfirmed) {
      try {
        await _activitiesService.deleteQuickTask({ taskId: task._id || "" });
        Swal.fire("Deletado!", "Sua tarefa foi deletada.", "success");
        refresh();
      } catch (error) {
        Swal.fire("Erro!", "Houve um erro ao deletar a tarefa.", "error");
      }
    }
  };

  return (
    <Card
      style={{
        boxShadow: isToView || isMobile ? "none" : "10px 15px 40px #3B4B761F",
      }}
    >
      <CardHead style={{ backgroundColor: isToView ? "none" : "#00df70" }}>
        {isToView ? (
          <CardTitleToView className="d-flex w-100 justify-content-between align-items-center">
            <span className="title">Tarefas Rápidas</span>
            <div onClick={handleFilterClick}>
              <BsFillFilterCircleFill className="filter-icon"></BsFillFilterCircleFill>
              <DropdownButton
                as={ButtonGroup}
                align={{ lg: isMobile ? "start" : "end" }}
                title=""
                id="dropdown-menu-align-responsive"
                className="btn shadow-none"
                onClick={handleDropdownClick}
                show={showDropdown}
                ref={dropdownRef}
              >
                <Dropdown.Header>
                  <FilterHeader>
                    <span className="title">Filtros</span>
                  </FilterHeader>
                </Dropdown.Header>
                <CustomFilterOption
                  onClick={() =>
                    handleFilterChange(
                      (filterCompletedTasks = !filterCompletedTasks),
                      filterFavoritedTasks,
                      filterCycleTasks
                    )
                  }
                  className="d-flex align-items-center"
                >
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon htmlColor="#CCCCCC" />}
                    size="small"
                    style={{
                      color: "#3EFF9F",
                    }}
                    checked={filterCompletedTasks}
                  />
                  <span>Tarefas concluidas</span>
                </CustomFilterOption>
                <CustomFilterOption
                  onClick={() =>
                    handleFilterChange(
                      filterCompletedTasks,
                      (filterFavoritedTasks = !filterFavoritedTasks),
                      filterCycleTasks
                    )
                  }
                  className="d-flex align-items-center"
                >
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon htmlColor="#CCCCCC" />}
                    size="small"
                    style={{
                      color: "#3EFF9F",
                    }}
                    checked={filterFavoritedTasks}
                  />
                  <span>Tarefas favoritas</span>
                </CustomFilterOption>
                <CustomFilterOption
                  onClick={() =>
                    handleFilterChange(
                      filterCompletedTasks,
                      filterFavoritedTasks,
                      (filterCycleTasks = !filterCycleTasks)
                    )
                  }
                  className="d-flex align-items-center"
                >
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon htmlColor="#CCCCCC" />}
                    size="small"
                    style={{
                      color: "#3EFF9F",
                    }}
                    checked={filterCycleTasks}
                  />
                  <span>Tarefas Recorrentes</span>
                </CustomFilterOption>
              </DropdownButton>
            </div>
          </CardTitleToView>
        ) : (
          <CardTitle className="d-flex w-100 justify-content-between align-items-center">
            Tarefas diárias fáceis
          </CardTitle>
        )}
        {!isToView && (
          <>
            <AiFillPlusCircle
              onClick={onOpenCreateTask}
              size={25}
              style={{ color: "#fff", marginRight: 15, cursor: "pointer" }}
            />
            {!editModeTask && !deleteModeTask && (
              <RiEdit2Fill
                onClick={() => {
                  setEditModeTask(true);
                }}
                size={25}
                style={{ color: "#fff", marginRight: 15, cursor: "pointer" }}
              />
            )}
            {editModeTask && (
              <IoMdCloseCircle
                onClick={() => {
                  setEditModeTask(false);
                }}
                size={25}
                style={{ color: "#fff", marginRight: 15, cursor: "pointer" }}
              />
            )}
            {!deleteModeTask && !editModeTask && (
              <FaTrash
                onClick={() => {
                  setDeleteModeTask(true);
                }}
                size={18}
                style={{ color: "#fff", marginRight: 15, cursor: "pointer" }}
              />
            )}
            {deleteModeTask && (
              <IoMdCloseCircle
                onClick={() => {
                  setDeleteModeTask(false);
                }}
                size={25}
                style={{ color: "#fff", marginRight: 15, cursor: "pointer" }}
              />
            )}
          </>
        )}
      </CardHead>
      {loading ? (
        <LoadingSkeleton>
          <Skeleton variant="rounded" className="skeleton-full-width" />
        </LoadingSkeleton>
      ) : (
        <>
          <CardTaskBody
            className="row animate__animated animate__fadeIn"
            style={{
              overflowY: tasks.length > 9 || isMobile ? "auto" : "hidden",
            }}
          >
            {tasks.length > 0 ? (
              <>
                {tasks.map((task: TQuickTask) => (
                  <div
                    className={`col-12 col-md-12 col-lg-4 mb-2 ${
                      editModeTask || deleteModeTask
                        ? "animate__animated animate__pulse"
                        : ""
                    }`}
                    key={task._id}
                  >
                    <CardTask
                      style={{
                        backgroundColor:
                          (task._id === hoveredTaskId
                            ? task.color
                            : lightenColor(task?.color || "", 85)) || "#fff",
                      }}
                      onMouseEnter={() =>
                        handleTaskMouseEnter(task._id || null)
                      }
                      onMouseLeave={handleTaskMouseLeave}
                      onClick={() =>
                        deleteModeTask
                          ? handleDeleteTask(task)
                          : editModeTask
                          ? onHandleEditTask(task)
                          : openTaskModal(task)
                      }
                    >
                      <CardTaskBodyTop>
                        <CardTaskTitle
                          className="title"
                          style={{
                            color: task?.color || "",
                            overflow: "hidden",
                          }}
                        >
                          {task.title}
                        </CardTaskTitle>
                      </CardTaskBodyTop>

                      {task.expiresTime ? (
                        <AiFillClockCircle
                          className="icon"
                          style={{ float: "right", color: task?.color || "" }}
                          size={25}
                        />
                      ) : (
                        <AiFillPlayCircle
                          className="icon"
                          style={{ float: "right", color: task?.color || "" }}
                          size={25}
                        />
                      )}
                      {task.isCompleted && (
                        <AiFillCheckCircle
                          className="me-1 icon"
                          style={{ float: "right", color: task?.color || "" }}
                          size={25}
                        />
                      )}
                    </CardTask>
                  </div>
                ))}
              </>
            ) : (
              <>
                <h4 className="d-flex justify-content-center align-items-center">
                  Nenhuma tarefa foi encontrada para exibição.
                </h4>
              </>
            )}
          </CardTaskBody>
        </>
      )}
    </Card>
  );
};

export default TaskComponent;
