import { gql } from "@apollo/client";

const GET_ALL_CONTENTS = gql`
  query GetAllContents($page: Float, $perPage: Float) {
    getAllContents(page: $page, perPage: $perPage) {
      _id
      name
    }
  }
`;

export default GET_ALL_CONTENTS;
