import { useState } from "react";
import { IoIosClose } from "react-icons/io";
import badFaceIcon from "../../../assets/img/bad_face_icon.svg";
import happyFaceIcon from "../../../assets/img/happy_face_icon.svg";
import neutralFaceIcon from "../../../assets/img/neutral_face_icon.svg";
import sadFaceIcon from "../../../assets/img/sad_face_icon.svg";
import smileFaceIcon from "../../../assets/img/smile_face_icon.svg";
import { CloseBtn } from "../../../pages/activities/components/modal-care/style";
import { Mood } from "../../enum/moodEnum";
import { CalendarHumorProps } from "../../models/humor/humor.modal";
import MoodService from "../../services/mood/mood.service";
import { ModalContent, ModalOverlay, MoodChoiceButton } from "./style";
const MoodSelection: React.FC<CalendarHumorProps> = ({
  onClose,
  receivedDate,
}) => {
  const [selectedMood, setSelectedMood] = useState<Mood | null>(null);
  const _moodService = new MoodService();
  const handleMoodSelection = (mood: Mood) => {
    setSelectedMood(mood);
  };
  const submitMood = async () => {
    const date = receivedDate || new Date().setUTCHours(0, 0, 0, 0);
    const dateObject = new Date(date);
    await _moodService.insertMoodByDay(dateObject, selectedMood || Mood.Empty);
    onClose();
  };
  return (
    <ModalOverlay className="modal-overlay">
      <ModalContent className="d-flex align-items-center flex-column justify-content-center position-relative">
        {receivedDate && (
          <CloseBtn className="position-absolute end-0 top-0 m-3">
            <IoIosClose
              onClick={onClose}
              size={28}
              style={{ color: "gray", marginRight: 25, cursor: "pointer" }}
            />
          </CloseBtn>
        )}
        <h1 className="animate__animated animate__fadeIn">
          Como está seu dia?
        </h1>
        <div className="d-flex animate__animated items animate__fadeIn align-items-center mt-5 mb-3">
          <MoodChoiceButton
            role="button"
            src={badFaceIcon}
            className={`ms-4 icon ${
              selectedMood === Mood.Bad ? "selected" : ""
            }`}
            onClick={() => handleMoodSelection(Mood.Bad)}
          />
          <MoodChoiceButton
            role="button"
            src={sadFaceIcon}
            className={`ms-4 icon ${
              selectedMood === Mood.Sad ? "selected" : ""
            }`}
            onClick={() => handleMoodSelection(Mood.Sad)}
          />
          <MoodChoiceButton
            role="button"
            src={neutralFaceIcon}
            className={`ms-4 icon ${
              selectedMood === Mood.Neutral ? "selected" : ""
            }`}
            onClick={() => handleMoodSelection(Mood.Neutral)}
          />
          <MoodChoiceButton
            role="button"
            src={smileFaceIcon}
            className={`ms-4 icon ${
              selectedMood === Mood.Smile ? "selected" : ""
            }`}
            onClick={() => handleMoodSelection(Mood.Smile)}
          />
          <MoodChoiceButton
            role="button"
            src={happyFaceIcon}
            className={`icon ms-4 ${selectedMood === Mood.Happy ? "selected" : ""}`}
            onClick={() => handleMoodSelection(Mood.Happy)}
          />
        </div>
        <div className="submit-mood-selection d-flex justify-content-center align-items-center mt-5 animate__animated animate__fadeIn">
          <button
            disabled={selectedMood == null}
            onClick={submitMood}
            className="btn"
            type="button"
          >
            Enviar
          </button>
        </div>
      </ModalContent>
    </ModalOverlay>
  );
};
export default MoodSelection;
