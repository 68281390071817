import axiosInstance from "../helpers/axios";
import DELETE_FILE from "./mutations/deleteFile.mutation";
import GET_FILE from "./queries/getFile.query";
import { requestService } from "./request.service";

class S3AwsService {
  private readonly url: string = process.env.REACT_APP_AWS_API || "";

  async uploadFile(file: File): Promise<any> {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axiosInstance.post(this.url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error: any) {
      throw new Error(`Erro ao fazer upload: ${error.message}`);
    }
  }

  async deleteFile(key: string): Promise<any> {
    try {
      const result = await requestService.mutate(DELETE_FILE, {
        input: { key },
      });
      return result;
    } catch (error: any) {
      throw new Error(`Erro ao excluir arquivo: ${error.message}`);
    }
  }

  async getFile(input: { key: string }): Promise<string | null> {
    try {
      const result = await requestService.get(GET_FILE, {
        input,
      });
      return result?.data?.getFile?.url || null;
    } catch (error: any) {
      throw new Error(`Erro ao buscar arquivo: ${error.message}`);
    }
  }

  extractKeyFromUrl = (url: string): string => {
    const parts = url.split("/");
    return parts[parts.length - 1];
  };
}

export const s3AwsService = new S3AwsService();
