import { requestService } from "../../../core/services/request.service";
import {
  TCreateQuickTaskInput,
  TQuickTask,
} from "../../models/dashboard/pillars.model";
import CREATE_EXERCISE_MEMORY from "./mutations/createExerciseMemory.mutation";
import CREATE_SELF_CARE_HABIT from "./mutations/createSelfCareHabits.mutation";
import CREATE_QUICK_TASK from "./mutations/createTasks.mutation";
import CREATE_THOUGHT_JOURNAL from "./mutations/createThoughtJournal.mutation";
import DELETE_EXERCISE_MEMORY from "./mutations/deleteExerciseMemory.mutation";
import DELETE_SELF_CARE_HABIT from "./mutations/deleteSelfCareHabits.mutation";
import DELETE_QUICK_TASK from "./mutations/deleteTasks.mutation";
import DELETE_THOUGHT_JOURNAL from "./mutations/deleteThoughtJournal.mutation";
import EDIT_EXERCISE_MEMORY from "./mutations/editExerciseMemory.mutation";
import EDIT_SELF_CARE_HABIT from "./mutations/editSelfCareHabits.mutation";
import EDIT_QUICK_TASK from "./mutations/editTasks.mutation";
import EDIT_THOUGHT_JOURNAL from "./mutations/editThoughtJournal.mutation";
import LIST_EXERCISE_MEMORIES from "./queries/listExerciseMemory.query";
import LIST_SELF_CARE_HABITS from "./queries/listSelfCare.query";
import LIST_QUICK_TASKS from "./queries/listTasks.query";
import LIST_THOUGHT_JOURNALS from "./queries/listThoughtJournal.query";
export type TDeleteQuickTaskInput = {
  taskId: string;
};
export class ActivitiesServices {
  async editQuickTask(data: any) {
    try {
      const result = requestService.mutate(EDIT_QUICK_TASK, {
        input: data,
      });
      return result;
    } catch (error: any) {
      console.log(error.message);
    }
  }

  async createQuickTask(data: TCreateQuickTaskInput) {
    try {
      const result = requestService.mutate(CREATE_QUICK_TASK, {
        input: data,
      });
      return result;
    } catch (error: any) {
      console.log(error.message);
    }
  }

  async deleteQuickTask(data: TDeleteQuickTaskInput) {
    try {
      const result = await requestService.mutate(DELETE_QUICK_TASK, {
        input: data,
      });
      return result;
    } catch (error: any) {
      console.log(error.message);
    }
  }

  async listQuickTasks(page: number, perPage: number): Promise<TQuickTask[]> {
    try {
      const result = await requestService.get(LIST_QUICK_TASKS, {
        page,
        perPage,
      });
      return result?.data?.listQuickTasks;
    } catch (error: any) {
      console.log(error.message);
      throw error;
    }
  }

  async createSelfCareHabit(data: CreateSelfCareHabitInput) {
    try {
      const result = await requestService.mutate(CREATE_SELF_CARE_HABIT, {
        input: data,
      });
      return result;
    } catch (error: any) {
      console.log(error.message);
      throw error;
    }
  }

  async editSelfCareHabit(data: EditSelfCareHabitInput) {
    try {
      const result = await requestService.mutate(EDIT_SELF_CARE_HABIT, {
        input: data,
      });
      return result;
    } catch (error: any) {
      console.log(error.message);
      throw error;
    }
  }

  async deleteSelfCareHabit(data: DeleteSelfCareHabitInput) {
    try {
      const result = await requestService.mutate(DELETE_SELF_CARE_HABIT, {
        input: data,
      });
      return result;
    } catch (error: any) {
      console.log(error.message);
      throw error;
    }
  }

  async listSelfCareHabits(
    page: number,
    perPage: number
  ): Promise<ListSelfCareHabitsResponse["listSelfCareHabits"]> {
    try {
      const result = await requestService.get(LIST_SELF_CARE_HABITS, {
        page,
        perPage,
      });
      return result?.data?.listSelfCareHabits;
    } catch (error: any) {
      console.log(error.message);
      throw error;
    }
  }

  async listThoughtJournals(
    page: number,
    perPage: number
  ): Promise<ListThoughtJournalsResponse["listThoughtJournals"]> {
    try {
      const result = await requestService.get(LIST_THOUGHT_JOURNALS, {
        page,
        perPage,
      });
      return result?.data?.listThoughtJournals;
    } catch (error: any) {
      console.log(error.message);
      throw error;
    }
  }

  async createThoughtJournal(data: CreateThoughtJournalInput) {
    try {
      const result = await requestService.mutate(CREATE_THOUGHT_JOURNAL, {
        input: data,
      });
      return result;
    } catch (error: any) {
      console.log(error.message);
      throw error;
    }
  }

  async editThoughtJournal(data: EditThoughtJournalInput) {
    try {
      const result = await requestService.mutate(EDIT_THOUGHT_JOURNAL, {
        input: data,
      });
      return result;
    } catch (error: any) {
      console.log(error.message);
      throw error;
    }
  }

  async deleteThoughtJournal(data: DeleteThoughtJournalInput) {
    try {
      const result = await requestService.mutate(DELETE_THOUGHT_JOURNAL, {
        input: data,
      });
      return result;
    } catch (error: any) {
      console.log(error.message);
      throw error;
    }
  }

  async listExerciseMemories(
    page: number,
    perPage: number
  ): Promise<ListExerciseMemoriesResponse["listExerciseMemories"]> {
    try {
      const result = await requestService.get(LIST_EXERCISE_MEMORIES, {
        page,
        perPage,
      });
      return result?.data?.listExerciseMemories;
    } catch (error: any) {
      console.log(error.message);
      throw error;
    }
  }

  async createExerciseMemory(data: CreateExerciseMemoryInput) {
    try {
      const result = await requestService.mutate(CREATE_EXERCISE_MEMORY, {
        input: data,
      });
      return result;
    } catch (error: any) {
      console.log(error.message);
      throw error;
    }
  }

  async editExerciseMemory(data: EditExerciseMemoryInput) {
    try {
      const result = await requestService.mutate(EDIT_EXERCISE_MEMORY, {
        input: data,
      });
      return result;
    } catch (error: any) {
      console.log(error.message);
      throw error;
    }
  }

  async deleteExerciseMemory(data: DeleteExerciseMemoryInput) {
    try {
      const result = await requestService.mutate(DELETE_EXERCISE_MEMORY, {
        input: data,
      });
      return result;
    } catch (error: any) {
      console.log(error.message);
      throw error;
    }
  }
}

export interface CreateExerciseMemoryInput {
  description: string;
}

export interface EditExerciseMemoryInput {
  memoryId: string;
  description?: string;
}

export interface DeleteExerciseMemoryInput {
  memoryId: string;
}

export interface ListExerciseMemoriesResponse {
  listExerciseMemories: Array<ExerciseMemoryItem>;
}

export interface ExerciseMemoryItem {
  description: string;
  _id: string;
}

export interface CreateThoughtJournalInput {
  title: string;
  description: string;
}

export interface EditThoughtJournalInput {
  journalId: string;
  description?: string;
  title?: string;
}

export interface DeleteThoughtJournalInput {
  journalId: string;
}

export interface ListThoughtJournalsResponse {
  listThoughtJournals: Array<ThoughtJournalItem>;
}

export interface ThoughtJournalItem {
  description: string;
  userId: string;
  title: string;
  date: string;
  hour: string;
  _id: string;
}
export interface CreateSelfCareHabitInput {
  id?: string | null;
  type: SelfCareHabitsTypeResponseEnum;
  description: string;
}

export interface CreateSelfCareHabitResponse {
  createSelfCareHabit: {
    type: SelfCareHabitsTypeEnum;
    description: string;
  };
}

export interface EditSelfCareHabitInput {
  habitId: string;
  description?: string;
  type?: SelfCareHabitsTypeResponseEnum;
}

export interface EditSelfCareHabitResponse {
  editSelfCareHabit: {
    habitId: string;
    description: string;
    type: SelfCareHabitsTypeEnum;
  };
}

export interface DeleteSelfCareHabitInput {
  habitId: string;
}

export interface DeleteSelfCareHabitResponse {
  deleteSelfCareHabit: boolean;
}

export interface ListSelfCareHabitsResponse {
  listSelfCareHabits: Array<SelfCareItem>;
}

export interface SelfCareItem {
  description: string;
  type: SelfCareHabitsTypeResponseEnum;
  _id: string;
}

export enum SelfCareHabitsTypeEnum {
  ToAdopt = 1,
  ToAbandon = 2,
}
export enum SelfCareHabitsTypeResponseEnum {
  ToAdopt = "ToAdopt",
  ToAbandon = "ToAbandon",
}
