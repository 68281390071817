import PageTitle from '../../shared/components/page-title'
import {
  BottonBall,
  MainContainer,
  TopBall
} from '../../shared/components/templates/main-container/main-container'
import ActivityCard from './components/activity-card'
import { ActivitiesContainer, MainContent } from './style'
const Activities = () => {
  const isMobile: boolean = /Mobi|Android/i.test(navigator.userAgent)

  const data = {
    title: 'Atividades',
    subtitle:
      'Para conquistar grandes avanços, é necessário dar pequenos passos diários. Através de atividades, boas memórias e bom hábitos.'
  }

  return (
    <MainContainer>
      <TopBall style={{ display: isMobile ? 'none' : '' }} />
      <BottonBall style={{ display: isMobile ? 'none' : '' }} />
      <ActivitiesContainer>
        <PageTitle prop={data} />
        <MainContent>
          <ActivityCard></ActivityCard>
        </MainContent>
      </ActivitiesContainer>
    </MainContainer>
  )
}
export default Activities
