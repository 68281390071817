import styled from "styled-components";

export const DashboardContainer = styled.div`
  width: 82vw;
  margin-left: 10vw;

  @media (max-width: 768px) {
    width: 100% !important;
    margin-left: 0vw;
    overflow-x: hidden;
  }

  @media only screen and (max-height: 700px) {
    width: 87vw;
  }

  .short-cards {
    @media (max-width: 768px) {
      width: 100% !important;
      margin-top: 1.3rem;
    }
  }
`;

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MainContent = styled.div`
  width: 100%;
  margin-top: 9px;
`;
export const Session = styled.div`
  margin-bottom: 10px;
  @media (max-width: 768px) {
    flex-direction: column !important;
  }
`;
export const SessionArea = styled.div`
  border: 1px solid #f6f6f6;
  box-shadow: 10px 15px 40px #3b4b761f;
  @media (max-width: 762px) {
    box-shadow: none;
  }
  background: white;
  border-radius: 14px;
  @media (max-width: 768px) {
    width: 100% !important;
    margin-top: 1.3rem;
    background-color: rgba(248, 248, 248, 1);
  }
`;

export const CardPillar = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 40px;
  opacity: 1;
`;

export const NextMeeting = styled.div`
  width: 100%;
  height: 22rem;
  border: 1px solid #ffffff66;
  border-radius: 40px;
  opacity: 1;
  /* @media only screen and (max-width: 1540px) {
    height: 16rem;
  } */
`;

export const CalendarCard = styled.div`
  width: 100%;
  height: 22rem;
  padding: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff66;
  border-radius: 40px;
  opacity: 1;
  /* @media only screen and (max-width: 1540px) {
    height: 16rem;
  } */
`;
export const ArrowLink = styled.a`
  margin-left: 38%;
  color: black;
`;

export const GrafficCard = styled.div`
  width: 100%;
  height: 21rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff66;
  border-radius: 40px;
  opacity: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* @media only screen and (max-width: 1540px) {
    height: 12rem;
  } */
`;

export const HumorCard = styled.div`
  width: 100%;
  height: 12rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff66;
  border-radius: 40px;
  opacity: 1;
  @media (max-width: 762px) {
    background-color: rgba(248, 248, 248, 1);
  }
`;

export const NextTask = styled.div`
  width: 100%;
  height: 21rem;
  border: 1px solid #ffffff66;
  border-radius: 40px;
  opacity: 1;
  /* @media only screen and (max-width: 1540px) {
    height: 12rem;
  } */
`;
export const Title = styled.div`
  text-align: left;
  font: normal normal bold 27px/34px Raleway;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 1.6em;
  margin-top: 10px;
  margin-bottom: 10px;
  /* @media only screen and (max-width: 1540px) {
    font-size: 1.3em;
  } */
`;

export const TitleCalendarButton = styled.button`
  background-color: #f0f0f1;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  border: none;
  float: right;
  padding-bottom: 5px;
  transition: 0.25s;
  a {
    text-decoration: none;
    color: black !important;
  }
  &:hover {
    background: black !important;

    a {
      color: white !important;
    }
  }
`;
