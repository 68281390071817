import FullCalendar from "@fullcalendar/react";
import styled from "styled-components";

export const CalendarContainer = styled.div`
  width: 80vw;
  margin-left: 10vw;
`;
export const CalendarArea = styled.div`
  width: 100%;
  margin-top: 30px;
  background-color: white;
  border-radius: 14px;
  box-shadow: 10px 15px 40px #3b4b761f;
  @media (max-width: 762px) {
    box-shadow: none;
  }
  padding: 25px;

  .fc-toolbar-chunk {
    display: flex;
  }

  .fc-prev-button {
    border-radius: 50%;
    color: black;
    padding: 0px 7px;
    border: 1px solid #21252965;
    margin-right: 10px;
    padding-left: 4px;
    padding-right: 5px;
    background: white !important;
    border-bottom-right-radius: 50% !important;
    border-top-right-radius: 50% !important;
    transition: 0.25s;
    box-shadow: none !important;
    &:hover {
      background: black !important;
      color: white !important;
    }
  }

  .fc-dayGridMonth-button,
  .fc-timeGridWeek-button {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 30px;
    color: black !important;
    font-weight: 600;
    border: none !important;
    box-shadow: none;
    background: #f0f0f2 !important;
    box-shadow: none !important;
    transition: 0.25 !important;
  }

  .fc-customButton-button {
    background: black !important;
    color: white !important;
    border: none !important;
    box-shadow: none !important;
    padding: 3px 20px !important;
    transition: 0.25s;
    &:hover {
      background: white !important;
      border: 1px solid black !important;
      box-shadow: 10px 15px 40px #3b4b761f !important;
      @media (max-width: 762px) {
        box-shadow: none;
      }
      color: black !important;
    }
  }

  .fc-timeGridWeek-button {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }

  .fc-button-active {
    color: white !important;
    font-weight: 600;
    background: black !important;
    transition: 0.25s;
  }

  .fc-next-button {
    border-radius: 50%;
    color: black;
    border: 1px solid #21252965;
    padding: 0px 7px;
    margin-right: 10px;
    padding-left: 4px;
    padding-right: 5px;
    background: white !important;
    border-bottom-left-radius: 50% !important;
    border-top-left-radius: 50% !important;
    transition: 0.25s;
    box-shadow: none !important;
    &:hover {
      background: black !important;
      color: white !important;
    }
  }

  .fc-scrollgrid {
    border: none !important;
  }

  .fc .fc-daygrid-day.fc-day-today {
    background: none !important;
    a {
      color: white !important;
      border-radius: 50%;
      padding: 10px 15px;
      background: #35db88 !important;
    }
  }

  .fc-scrollgrid-sync-inner {
    text-align: end;
  }
  .fc-toolbar-title {
    text-transform: capitalize;
  }

  a {
    color: gray;
    cursor: pointer;
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: none;
  }

  .fc-col-header-cell {
    border: none !important;
  }

  /* .fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
    border-radius: 50% !important;
    background-color: transparent !important;
    margin-right: 10px;
    width: 43px !important;
    border: gray 1px solid;
  }
  .fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
    border-radius: 50% !important;
    background-color: transparent !important;
    margin-right: 10px;
    width: 43px !important;
    border: gray 1px solid;
  }
  .fc .fc-button .fc-icon {
    color: black !important;
    margin-left: -2px !important;
  } */
`;
export const StyledFullCalendar = styled(FullCalendar)`
  /* .fc-daygrid-day {
    border: gray;
  } */
`;
