import { gql } from "@apollo/client";

const GET_USER_SUPPORT_CONTENT = gql`
  query getUserSupportContent($_supportContentId: String!) {
    getUserSupportContent(_supportContentId: $_supportContentId) {
      _id
      _supportContentId
      isFavorited
      isCompleted
    }
  }
`;

export default GET_USER_SUPPORT_CONTENT;
