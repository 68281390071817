import styled from "styled-components";

export const HumorContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HumorHeader = styled.div`
  width: 100%;
  height: 10%;
  padding: 6px 5px;
  z-index: 100;
`;

export const HumorTitle = styled.span`
  text-align: left;
  font: normal normal 600 29px/34px Raleway;
  letter-spacing: 0px;
  color: #212529;
  opacity: 1;
  margin-left: 40%;
`;
export const HumorTitleCalendar = styled.button`
  background-color: #ebf9f5;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  border: none;
  float: right;
  margin-right: 10px;
  margin-top: -5px;
  transition: 0.25s;
  outline: none;
  &:hover {
    color: white;
    background-color: #00df70;
  }
`;

export const HumorMessage = styled.span`
  text-align: center;
  letter-spacing: 0px;
  color: black;
  margin-left: 30px;
  font-size: 1.5rem;
  font-weight: 600;
  opacity: 1;
  @media (max-width: 762px) {
    font-size: 1rem;
  }
`;
export const HumorImageArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;
export const HumorImage = styled.img`
  width: 120px;
`;
