import styled from "styled-components";

export const FavoritesContainer = styled.div`
  width: 100%;
  height: 14rem;
  background: white;
  display: flex;
  padding: 1.2rem;
  flex-direction: column;
  align-items: center;
  @media (max-width: 762px) {
    background-color: rgba(248, 248, 248, 1);
  }
  .favorite-items {
    display: flex;
    margin-top: 1rem;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: auto;
    .favorite-item {
      margin-right: 20px;
      padding: 0.5rem;
      width: 100%;
      max-width: fit-content;
      display: flex;
      cursor: pointer;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      box-shadow: 10px 15px 40px #3b4b761f;
      @media (max-width: 762px) {
        box-shadow: none;
      }
      border: 1px solid #f6f6f6;
      border-radius: 14px;

      .title-favorite {
        border-radius: 8px;
      }
      .description-favorite {
        font-size: 1.2rem;
        text-overflow: ellipsis;
        white-space: pre-wrap;
        overflow: hidden;
        width: 150px;
      }
    }
  }
`;

export const CarrouselControls = styled.div`
  display: flex;
  align-items: center;

  div {
    border-radius: 50%;
    padding: 3px;
    display: flex;
    align-items: center;
    border: 1px solid #21252965;

    &:last-child {
      margin-left: 0.3rem;
    }
  }

  svg {
    color: black;
  }
`;

export const FavoritesHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.25s;

  .active {
    cursor: pointer;
  }
  .disabled {
    opacity: 0.4;
    cursor: default;
  }

  span {
    font-weight: bold;
    font-size: 1.8rem;
    color: #212529;
    @media (max-width: 762px) {
      font-size: 1rem;
    }
  }
`;
