import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  min-height: 100vh;
  height: 100vh;
`;
export const TopBall = styled.div`
  background-color: #b6eada;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  position: fixed;

  animation: moveBall2 2s ease-in-out forwards;
  @keyframes moveBall2 {
    0% {
      right: -300px;
      top: 50px;
    }
    100% {
      right: -300px;
      top: -200px;
    }
  }

  @media only screen and (max-height: 700px) {
    display: none;
  }
`;

export const TopCenterBall = styled.div`
  background-color: #b6eada;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  position: fixed;

  animation: moveBall3 2s ease-in-out forwards;
  @keyframes moveBall3 {
    0% {
      right: -300px;
      top: -300px;
    }
    100% {
      right: 300px;
      top: -350px;
    }
  }
`;

export const BottonBall = styled.div`
  background-color: #b6eada;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  position: fixed;
  left: 0;
  bottom: -10;
  animation: moveBall 1s ease-in-out forwards;
  @keyframes moveBall {
    0% {
      left: 0;
      bottom: 0;
    }
    100% {
      left: -250px;
      bottom: -250px;
    }
  }
  @media (max-width: 762px) {
    display: none;
  }
`;
