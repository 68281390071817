import { gql } from "@apollo/client";

const GET_ALL_PILLARS = gql`
  query GetAllPillars {
    getAllPillars {
      _id
      name
      color
    }
  }
`;

export default GET_ALL_PILLARS;
