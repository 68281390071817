import PageTitle from '../../shared/components/page-title'
import { MainContainer } from '../../shared/components/templates/main-container/main-container'
import { HelpContainer } from './style'
const Help = () => {
  const data = {
    title: 'Ajuda',
    subtitle:
      'Com o objetivo de conscientização, buscar melhoraria e visualizar sua evolução.'
  }
  return (
    <MainContainer>
      <HelpContainer>
        <PageTitle prop={data} />
      </HelpContainer>
    </MainContainer>
  )
}
export default Help
