import React from "react";
import { Modal } from "react-bootstrap";
import { IoIosClose } from "react-icons/io";
import CreateEditArticle from "../../../pages/articles/article-create-edit";
import { CloseBtn, ModalContainer } from "../modal-content/style";

type ModalProps = {
  showModal: boolean;
  closeModal: () => void;
  link: string | null;
  articleId?: string;
  name: string | null;
};

const EmbedModal: React.FC<ModalProps> = ({
  showModal,
  closeModal,
  link,
  name,
  articleId,
}) => {
  const isYouTubeLink = (url: string | null) => {
    if (!url) return false;
    const youtubeRegex =
      /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/; //eslint-disable-line
    return youtubeRegex.test(url);
  };

  const getYouTubeEmbedLink = (url: string) => {
    const youtubeRegex =
      /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/; //eslint-disable-line
    const match = url.match(youtubeRegex);
    if (match && match[1]) {
      return `https://www.youtube.com/embed/${match[1]}`;
    }
    return url;
  };

  const getEmbedLink = (url: string | null) => {
    if (isYouTubeLink(url)) {
      return getYouTubeEmbedLink(url as string);
    }
    return url;
  };

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      size="lg"
      className="custom-modal"
    >
      <div className="modal-header d-flex justify-content-between align-items-center">
        <h5 className="m-0">{name}</h5>

        <CloseBtn onClick={closeModal}>
          <IoIosClose
            size={28}
            style={{ color: "gray", marginRight: 25, cursor: "pointer" }}
          />
        </CloseBtn>
      </div>
      <ModalContainer
        className="row display-flex w-100 ms-0 p-4"
        style={{ height: "85vh", overflow: "auto" }}
      >
        {articleId && (
          <CreateEditArticle articleId={articleId} viewMode={true} />
        )}
        {link && !articleId && (
          <iframe
            src={getEmbedLink(link) || ""}
            title="Embedded Content"
            style={{ width: "100%", height: "100%" }}
            allowFullScreen
          />
        )}
      </ModalContainer>
    </Modal>
  );
};

export default EmbedModal;
