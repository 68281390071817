/* eslint-disable react-hooks/exhaustive-deps */
import ClearIcon from "@mui/icons-material/Clear"; // Ícone de limpeza
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import TextField, {
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  TextFieldVariants,
} from "@mui/material/TextField";
import { ClassAttributes, LiHTMLAttributes, useEffect, useState } from "react";

const CustomAutocomplete = styled(Autocomplete)<any>({
  "& .MuiAutocomplete-popupIndicator": {
    transform: "rotate(0deg)",
    transition: "transform 0.3s ease",
  },
  '&[aria-expanded="true"] .MuiAutocomplete-popupIndicator': {
    transform: "rotate(180deg)",
  },
});

interface SearchComponentProps<T> {
  options: T[];
  onInputChange: (query: string) => void;
  getOptionLabel: (option: T) => string;
  isLoading?: boolean;
  onOptionSelect?: (option: T | null) => void;
  onClear?: () => void;
  label?: string;
  disabled?: boolean;
  selectedId?: string;
}

const SearchComponent = <T extends { id: string; name: string }>({
  options,
  onInputChange,
  getOptionLabel,
  isLoading = false,
  onOptionSelect,
  onClear,
  label,
  selectedId,
  disabled,
}: SearchComponentProps<T>) => {
  const [selectedValue, setSelectedValue] = useState<T | null>(null);
  const [inputValue, setInputValue] = useState<string>("");

  useEffect(() => {
    if (selectedId) {
      const selectedOption = options.find((option) => option.id === selectedId);
      setSelectedValue(selectedOption || null);
    }
  }, [selectedId, options]);

  return (
    <div style={{ width: "100%" }}>
      <label className="form-label shadow-none">{label}</label>
      <CustomAutocomplete
        freeSolo
        disabled={disabled}
        clearIcon={<ClearIcon />}
        options={options}
        value={selectedValue}
        inputValue={inputValue}
        getOptionLabel={(option: T) => getOptionLabel(option as T)}
        onChange={(_: any, value: T | null) => {
          setSelectedValue(value);
          if (onOptionSelect) onOptionSelect(value);
        }}
        onInputChange={(_: any, value: string) => {
          setInputValue(value);
          onInputChange(value);
          if (value === "" && onClear) {
            onClear();
          }
        }}
        renderOption={(
          props: JSX.IntrinsicAttributes &
            ClassAttributes<HTMLLIElement> &
            LiHTMLAttributes<HTMLLIElement>,
          option: T
        ) => (
          <li {...props} key={(option as T).id}>
            {getOptionLabel(option as T)}
          </li>
        )}
        renderInput={(
          params: JSX.IntrinsicAttributes & {
            variant?: TextFieldVariants | undefined;
          } & Omit<
              | FilledTextFieldProps
              | OutlinedTextFieldProps
              | StandardTextFieldProps,
              "variant"
            >
        ) => (
          <TextField
            {...params}
            placeholder="Selecione"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
              style: {
                fontSize: "14px",
                fontWeight: "bold",
              },
            }}
            InputProps={{
              ...params.InputProps,
              style: {
                fontSize: "14px",
                padding: "10px 12px",
              },
              endAdornment: (
                <>
                  {isLoading && <CircularProgress color="inherit" size={20} />}
                  {params?.InputProps?.endAdornment}
                </>
              ),
            }}
          />
        )}
        clearOnEscape
      />
    </div>
  );
};

export default SearchComponent;
