import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import React from "react";

export interface TogglePasswordProps {
  showPassword: boolean;
  togglePassword: () => void;
}
const PasswordToggle: React.FC<TogglePasswordProps> = ({
  showPassword,
  togglePassword,
}) => {
  return (
    <InputAdornment position="end">
      <IconButton
        onClick={togglePassword}
        edge="end"
        data-testid="toggle-password-button"
      >
        {!showPassword ? (
          <VisibilityOff
            htmlColor="#CECECE"
            data-testid="visibility-off-icon"
            id="visibility-off-icon"
          />
        ) : (
          <Visibility
            id="visibility-icon"
            htmlColor="#CECECE"
            data-testid="visibility-icon"
          />
        )}
      </IconButton>
    </InputAdornment>
  );
};

export default PasswordToggle;
