import { gql } from "@apollo/client";

const COMPLETE_OR_FAVORITE_SUPPORT_CONTENT = gql`
  mutation CompleteOrFavoriteSupportContent(
    $input: CompleteOrFavoriteSupportContentInput!
  ) {
    completeOrFavorite(input: $input)
  }
`;

export default COMPLETE_OR_FAVORITE_SUPPORT_CONTENT;
