/* eslint-disable react-hooks/exhaustive-deps */
import "animate.css";
import "bootstrap/dist/css/bootstrap.css";
import { useEffect, useState } from "react";
import logoImageMobile from "../../../assets/svg/LogoFineAndYou_login.svg";
import AuthService, { UserOutput } from "../../services/auth/auth.service";
import UserAvatar from "../user-avatar";
import UserMessages from "../user-messages";
import {
  FlexDiv,
  InfoArea,
  SubTitle,
  TextArea,
  Title,
  TitleContainer,
} from "./style";

const PageTitle = (props: any) => {
  const isMobile: boolean = /Mobi|Android/i.test(navigator.userAgent);
  const [user, setUser] = useState<UserOutput | null>(null);

  const authService = new AuthService();
  const data = props.prop;

  const fetchUser = async (): Promise<any> => {
    const result = await authService.getUser();
    setUser(result);
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <TitleContainer>
      <FlexDiv>
        {isMobile && <img className="logo" src={logoImageMobile} alt="" />}
        <TextArea>
          <Title className="animate__animated animate__fadeInDown">
            {data.title}
          </Title>
        </TextArea>
        <InfoArea>
          <UserMessages />
          <UserAvatar name={user?.name || ''} />
        </InfoArea>
      </FlexDiv>
      <SubTitle className="animate__animated animate__fadeInDown">
        {data.subtitle}
      </SubTitle>
    </TitleContainer>
  );
};
export default PageTitle;
