import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const SeeMoreModalContainer = styled(Modal)`
  .modal-content {
    background: white 0% 0% no-repeat padding-box;
    box-shadow: 10px 15px 40px #3b4b761f;
    @media (max-width: 762px) {
      box-shadow: none;
      overflow: hidden;
    }
    border: 1px solid #f6f6f6;
    border-radius: 14px;
    min-height: 80dvh;
    height: 80dvh;
  }
  @media (max-width: 762px) {
    .btn-close {
      position: absolute !important;
      right: -20px;
    }
    .category-selector {
      width: 100% !important;
    }
    .item-content {
      flex-direction: column;
    }
    .item-image {
      margin-top: 1rem;
      width: 100% !important;
    }
    .item-description {
      margin-top: 1rem !important;
      max-width: 100% !important;
      width: 100% !important;
    }
    .see-more {
      margin-top: 1rem;
      width: 100% !important;
    }
  }

  .content-modal {
    max-height: 87%;
    overflow: auto;
  }

  .item-container {
    border: 2px solid #f0f0f1;
    border-radius: 14px;
    .item-image {
      width: 94px;
      object-fit: cover;
      height: 100px;
      box-shadow: 10px 15px 40px #3b4b761f;
      @media (max-width: 762px) {
        box-shadow: none;
      }
      border: 1px solid #f6f6f6;
      border-radius: 14px;
    }
    .item-description {
      font-size: 0.9rem;
      max-width: 80%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      overflow: hidden;
      -webkit-box-orient: vertical;
    }
  }

  .category-selector {
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    overflow: hidden;
  }

  .category-selector button {
    padding: 5px 15px;
    border: none;
    cursor: pointer;
    transition: 0.25s;
    &:hover {
      color: white;
      background: #03001c;
    }
  }

  .category-selector button.active {
    background-color: #03001c;
    color: #fff;
  }
`;

export const DropdownContainer = styled.div`
  width: 45%;
  .dropdown-toggle {
    background-color: #03001c;
    color: #ffffff;
    border-radius: 14px;
    border: none;
    min-width: 215px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 762px) {
      min-width: auto;
    }
    &::after {
      display: none;
    }

    &.show {
      background: white !important;
      outline: 1px solid #707070;
      color: black;
    }
  }

  .white-icon {
    color: #ffffff !important;
  }

  .dropdown-menu {
    max-width: 215px;
    background-color: white;
    padding-left: 1rem;
    padding-right: 1rem;
    a {
      color: black;
    }

    .dropdown-item {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid #d9d9d9;
      &:last-child {
        border-bottom: none;
      }
    }
  }
`;
