import { gql } from "@apollo/client";

const GET_ARTICLE = gql`
  query GetArticle($articleId: String!) {
    getArticle(input: { articleId: $articleId }) {
      _id
      title
      description
      image
    }
  }
`;

export default GET_ARTICLE;
