import { gql } from "@apollo/client";

const GET_MOODS_BY_MONTH = gql`
  query GetMoodsByMonth($date: DateTime!) {
    getMoodsByMonth(input: { date: $date }) {
      userId
      date
      type
      _id
    }
  }
`;

export default GET_MOODS_BY_MONTH;
