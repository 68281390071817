import styled from "styled-components";

export const Avatar = styled.button`
  width: 50px;
  height: 50px;
  border: none;
  padding: 0;
  object-fit: cover;
  box-shadow: 10px 20px 40px #00000029;
  border-radius: 50%;
  background-color: #00df70;
`;
export const Image = styled.img`
  width: 100%;
  border-radius: 50%;
`;
