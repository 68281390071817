import { gql } from "@apollo/client";

const LIST_QUICK_TASKS = gql`
  query ListQuickTasks($page: Int!, $perPage: Int!) {
    listQuickTasks(input: { page: $page, perPage: $perPage }) {
      title
      link
      isCompleted
      _id
      isFavorite
      pillarId
      expiresTime
      description
      pillarId
      color
      type
    }
  }
`;

export default LIST_QUICK_TASKS;
