import { gql } from "@apollo/client";

const LIST_THOUGHT_JOURNALS = gql`
  query ListThoughtJournals($page: Int!, $perPage: Int!) {
    listThoughtJournals(input: { page: $page, perPage: $perPage }) {
      description
      userId
      date
      hour
      _id
      title
    }
  }
`;

export default LIST_THOUGHT_JOURNALS;