import styled from "styled-components";

export namespace SignUpStyle {
  export const Form = styled.form`
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: black !important;
    }

    @media (max-width: 762px) {
      width: 100% !important;
      padding: 0 !important;
    }

    .MuiFormLabel-root.Mui-focused {
      color: black !important;
    }

    .MuiOutlinedInput-root {
      border-radius: 9px;
    }

    .MuiFormControlLabel-label {
      font-size: 0.9rem !important;
    }

    .subscribe-text a,
    .forgot-passoword {
      font-size: 0.9rem !important;
      color: black;
      transition: 0.25s;
      cursor: pointer;
    }

    .subscribe-text a:hover,
    .forgot-passoword:hover {
      color: #3eff9f;
    }

    .submit-button {
      text-decoration: none;
      border: none;
      background: #03001c;
      border-radius: 12px;
      color: white;
      font-weight: 300;
      font-size: 1.3rem;
      transition: 0.25s;
    }

    .submit-button:hover {
      background: #3eff9f;
    }

    .subscribe-text {
      color: #626262 !important;
      font-size: 0.8rem;
    }

    .subscribe-text a {
      font-size: 0.8rem;
    }
  `;
}
