import ptBrLocale from "@fullcalendar/core/locales/pt-br";
import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useRef } from "react";
import PageTitle from "../../shared/components/page-title";
import {
  MainContainer,
  TopCenterBall,
} from "../../shared/components/templates/main-container/main-container";
import { CalendarArea, CalendarContainer } from "./style";

const Calendar = () => {
  const data = {
    title: "Calendário",
    subtitle:
      "Uma visão diária para que você visualize o que já foi feito e do que você ainda irá fazer.",
  };

  const calendarRef: any = useRef(null);

  const todayText = "Hoje";
  const monthText = "Mês";
  const weekText = "Semana";

  const handleCreateEvent = () => {
    // Lógica para criar um novo evento
    // ...
  };

  const handleMonthView = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().changeView("dayGridMonth");
    }
  };

  const handleWeekView = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().changeView("timeGridWeek");
    }
  };

  return (
    <MainContainer>
      <TopCenterBall />
      <CalendarContainer>
        <h5
          className="animate__animated animate__fadeIn"
          style={{
            position: "fixed",
            left: 0,
            display: "flex",
            width: "100vw",
            pointerEvents: "none",
            height: "100vh",
            zIndex: 10000,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Em Breve
        </h5>
        <PageTitle prop={data} />
        <CalendarArea style={{ filter: "blur(5px)" }}>
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin]}
            initialView="dayGridMonth"
            contentHeight="auto"
            buttonText={{
              today: todayText,
              month: monthText,
              week: weekText,
            }}
            headerToolbar={{
              left: "title prev,next",
              center: "dayGridMonth,timeGridWeek",
              right: "customButton",
            }}
            customButtons={{
              customButton: {
                text: "Criar",
                click: handleCreateEvent,
              },
              monthButton: {
                text: " Mês ",
                click: handleMonthView,
              },
              weekButton: {
                text: "Semana",
                click: handleWeekView,
              },
            }}
            firstDay={2}
            locale={ptBrLocale}
            dayHeaderFormat={{
              weekday: "long",
            }}
            titleFormat={{
              month: "long",
            }}
          />
        </CalendarArea>
      </CalendarContainer>
    </MainContainer>
  );
};

export default Calendar;
