import styled from "styled-components";

export const HistoricContainer = styled.div`
  width: 80vw;
  margin-left: 10vw;
  @media only screen and (max-width: 760px) {
    width: 90vw;
    margin-left: 8.5vw;
  }
`;

export const MainContent = styled.div`
  width: 100%;
  margin-top: 30px;
`;
export const Section = styled.div``;
export const Grafic = styled.div`
  margin-top: 20px;
  background-color: white;
  @media (max-width: 762px) {
    background-color: #f8f8f8;
  }
  border-radius: 14px;
  padding: 10px 0px 20px 0;
  box-shadow: 10px 15px 40px #3b4b761f;
  @media (max-width: 762px) {
    box-shadow: none;
  }
`;
export const Title = styled.span`
  background: transparent url("img/Pilares.png") 0% 0% no-repeat padding-box;
  margin-left: 30px;
  font: normal normal bold 27px/34px Raleway;
  color: #000000;
  font-size: 1.4em;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const VerticalCard = styled.div`
  margin-top: 20px;
  background-color: white;
  @media (max-width: 762px) {
    background-color: #f8f8f8;
  }
  border: 1px solid #ffffff66;
  border-radius: 40px;
  padding: 15px 20px;
  box-shadow: 10px 15px 40px #3b4b761f;
  @media (max-width: 762px) {
    box-shadow: none;
  }
`;
export const TitleVertical = styled.span`
  font: normal normal bold 27px/34px Raleway;
  color: #000000;
  font-size: 1.4em;
  margin-top: 10px;
  margin-bottom: 10px;
`;
export const PillarItem = styled.div`
  width: 100%;
  height: 60px;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
`;
export const PillarTitle = styled.span`
  width: 100px;
  font: normal normal bold 27px/34px Raleway;
  color: #000000;
  font-size: 1.2em;
  margin-bottom: 10px;
`;

export const HighLight = styled.div`
  width: 100%;
  margin-top: 20px;
  border: 1px solid #d9d9d9;
  border-radius: 40px;
  padding: 10px 10px 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const HighLightItem = styled.div`
  width: 100%;
  height: 85px;
  border-radius: 40px;
  display: flex;
  align-items: center;
`;

export const HighLightIcon = styled.div`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HighLightinfo = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
`;
export const HighlightTitle = styled.span`
  font: normal normal bold 27px/34px Raleway;
  color: #000000;
  font-size: 1.4em;
`;
export const HighlightDescription = styled.span`
  color: #000000;
  font-size: 0.7em;
`;
export const HighLightPercentage = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.3em;
  font-weight: bold;
`;

export const Stepper = styled.div`
  width: 100%;
`;

export const Gauge = styled.div`
  width: 100%;
`;
