import styled from "styled-components";

export const ContentsContainer = styled.div`
  .card-content {
    border: 1px solid #f6f6f6 !important;
    transition: 0.25s;
    box-shadow: 5px 5px 10px #3b4b761f;
    &:hover {
      box-shadow: 10px 15px 10px #3b4b761f;
    }
  }
`;
