import { gql } from "@apollo/client";

const LIST_QUESTIONS = gql`
  query listQuestions(
    $page: Int!
    $perPage: Int!
    $sort: String!
    $sortDir: String!
    $filter: String!
  ) {
    listQuestions(
      input: {
        page: $page
        perPage: $perPage
        sort: $sort
        sortDir: $sortDir
        filter: $filter
      }
    ) {
      _id
      title
      maxChoices
      description
      type
      score
      answerOptions {
        _id
        name
      }
    }
  }
`;

export default LIST_QUESTIONS;
