import PageTitle from '../../shared/components/page-title'
import { MainContainer } from '../../shared/components/templates/main-container/main-container'
import { OverviewContainer } from './style'
const Overview = () => {
  const data = {
    title: 'Visão Geral',
    subtitle:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod'
  }
  return (
    <MainContainer>
      <OverviewContainer>
        <PageTitle prop={data} />
      </OverviewContainer>
    </MainContainer>
  )
}
export default Overview
