import { Chart } from "react-google-charts";

const VerticalLineChart = (props: any) => {
  const data = props.prop;
  const options = {
    colors: data.colors,
    hAxis: {
      format: " ",
      textStyle: {
        fontSize: 14,
      },
    },
    vAxis: {
      textPosition: "none",
      gridlines: { count: 0 },
      minValue: 0,
    },
    title: "",
    legend: "none",
    curveType: "function",
    pointShape: "circle",
    pointSize: 8,
    series: data.series,
    backgroundColor: data.backgroundColor,
  };

  return (
    <Chart
      width={"100%"}
      height={"250px"}
      chartType="LineChart"
      data={data.data}
      options={options}
    />
  );
};

export default VerticalLineChart;
