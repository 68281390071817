import { requestService } from "../../../core/services/request.service";
import { Mood } from "../../enum/moodEnum";
import INSERT_MOOD_BY_DAY from "./mutations/insert-mood-by-day.mutation";
import GET_MOODS_BY_MONTH from "./queries/get-moods-by-month.query";

class MoodService {
  async insertMoodByDay(date: Date, mood: Mood) {
    try {
      const result = requestService.mutate(INSERT_MOOD_BY_DAY, {
        input: {
          date,
          mood,
        },
      });
      return result;
    } catch (error: any) {
      console.log(error.message);
    }
  }

  async getMoodByMonth(date: Date): Promise<any> {
    try {
      const result = await requestService.get(GET_MOODS_BY_MONTH, {
        date,
      });
      return result.data.getMoodsByMonth;
    } catch (error: any) {
      console.log(error.message);
      return null;
    }
  }
}

export default MoodService;
