/* eslint-disable react-hooks/exhaustive-deps */
import { SearchOutlined } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  SpeedDial,
  SpeedDialIcon,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Toolbar,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../../shared/components/page-title";
import {
  MainContainer,
  TopCenterBall,
} from "../../../shared/components/templates/main-container/main-container";
import { ArticleService } from "../../../shared/services/article/article.service";
import { MessageServices } from "../../../shared/services/message/message.service";
import { ContentsContainer } from "../../contents/style";
import { MonitoringContainer } from "../../monitoring/style";
import {
  ActionTableCell,
  HighlightedTableCell,
  StyledTableCell,
  StyledTableContainer,
  StyledTableHead,
  StyledTablePagination,
  StyledTableRow,
  StyledTableSortLabel,
} from "./style";

interface Article {
  _id: string;
  title: string;
  description: string;
}

interface ListArticleProps {}

const ListArticle: React.FC<ListArticleProps> = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [search, setSearch] = useState<string>("");
  const [orderDirection, setOrderDirection] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<keyof Article>("title");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [articleToDelete, setArticleToDelete] = useState<Article | null>(null);
  const articleService = new ArticleService();
  const messageServices = new MessageServices();

  const fetchArticles = useCallback(async () => {
    setLoading(true);
    try {
      const sortDir = orderDirection === "asc" ? "asc" : "desc";
      const result = await articleService.listArticles(
        page + 1,
        rowsPerPage,
        orderBy,
        sortDir,
        search
      );
      setTotalCount(result?.total);
      setArticles(result?.items || []);
    } catch (error) {
      messageServices.error("Erro ao buscar artigos", error as string);
    } finally {
      setLoading(false);
    }
  }, [orderDirection, page, rowsPerPage, orderBy, search]);

  useEffect(() => {
    fetchArticles();
  }, [fetchArticles]);

  const handleSort = (property: keyof Article) => {
    const isAsc = orderBy === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDialog = (article: Article) => {
    setArticleToDelete(article);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setArticleToDelete(null);
  };

  const cleanAndLimitDescription = (html: string, maxLength: number = 30) => {
    const strippedString = html.replace(/<[^>]*>/g, "");
    return strippedString.length > maxLength
      ? strippedString.substring(0, maxLength) + "..."
      : strippedString;
  };

  const handleDelete = async () => {
    if (articleToDelete) {
      try {
        await articleService.deleteArticle({ id: articleToDelete._id });
        await fetchArticles();
        messageServices.success("Artigo excluído com sucesso");
      } catch (error) {
        messageServices.error("Erro ao excluir artigo", error as string);
      }
      handleCloseDialog();
    }
  };

  return (
    <ContentsContainer>
      <MainContainer className="position-relative">
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{ position: "absolute", bottom: 16, right: 16 }}
          icon={<SpeedDialIcon />}
          onClick={() => {
            navigate("/create-edit-article");
          }}
          FabProps={{
            sx: {
              bgcolor: "#00df70",
              "&:hover": {
                bgcolor: "#00df70",
              },
            },
          }}
        />
        <TopCenterBall />
        <MonitoringContainer style={{ height: "98%" }}>
          <PageTitle
            prop={{
              title: "Artigos",
              subtitle: "Esse quadro apresenta todos os artigos.",
            }}
          />
          <Toolbar className="p-0">
            <TextField
              fullWidth
              label="Buscar por título"
              variant="outlined"
              size="small"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              color="primary"
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <SearchOutlined />
                  </IconButton>
                ),
              }}
            />
          </Toolbar>

          <StyledTableContainer
            component={Paper}
            style={{ height: "70%", width: "100%" }}
          >
            <div style={{ maxHeight: "90%", overflowY: "auto" }}>
              <Table sx={{ borderCollapse: "separate", tableLayout: "fixed" }}>
                <StyledTableHead>
                  <TableRow>
                    <StyledTableCell variant="head">
                      <StyledTableSortLabel
                        active={orderBy === "title"}
                        direction={orderBy === "title" ? orderDirection : "asc"}
                        onClick={() => handleSort("title")}
                      >
                        Título
                      </StyledTableSortLabel>
                    </StyledTableCell>

                    <StyledTableCell variant="head">
                      <StyledTableSortLabel
                        active={orderBy === "description"}
                        direction={
                          orderBy === "description" ? orderDirection : "asc"
                        }
                        onClick={() => handleSort("description")}
                      >
                        Descrição
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <ActionTableCell variant="head">Ações</ActionTableCell>
                  </TableRow>
                </StyledTableHead>

                <TableBody>
                  {loading ? (
                    <StyledTableRow>
                      <TableCell colSpan={3} align="center">
                        <CircularProgress />
                      </TableCell>
                    </StyledTableRow>
                  ) : articles.length > 0 ? (
                    articles.map((article) => (
                      <StyledTableRow key={article._id}>
                        <HighlightedTableCell
                          onClick={() => {
                            navigate(`/article/${article._id}`);
                          }}
                        >
                          {article.title}
                        </HighlightedTableCell>
                        <HighlightedTableCell
                          onClick={() => {
                            navigate(`/article/${article._id}`);
                          }}
                        >
                          {cleanAndLimitDescription(article.description)}
                        </HighlightedTableCell>
                        <ActionTableCell>
                          <IconButton
                            onClick={() => {
                              navigate(`/create-edit-article/${article._id}`);
                            }}
                            color="primary"
                            aria-label="editar"
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            color="error"
                            aria-label="excluir"
                            onClick={() => handleOpenDialog(article)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ActionTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <StyledTableRow>
                      <TableCell colSpan={3} align="center">
                        Nenhum artigo encontrado
                      </TableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <StyledTablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Linhas por página"
              getLabelDisplayedRows={(paginationInfo: { from: any; to: any; count: any; }) => {
                const { from, to, count } = paginationInfo;
                return `${from}–${to} de ${count}`;
              }}
            />
          </StyledTableContainer>
        </MonitoringContainer>
      </MainContainer>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirmar Exclusão"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Tem certeza que deseja excluir o artigo{" "}
            <strong>{articleToDelete?.title}</strong>? Essa ação não pode ser
            desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleDelete} color="error" autoFocus>
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </ContentsContainer>
  );
};

export default ListArticle;
