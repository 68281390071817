import { gql } from "@apollo/client";

const LIST_SELF_CARE_HABITS = gql`
  query ListSelfCareHabits($page: Int!, $perPage: Int!) {
    listSelfCareHabits(input: { page: $page, perPage: $perPage }) {
      description
      userId
      type
      _id
    }
  }
`;

export default LIST_SELF_CARE_HABITS;
