import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#00DF70",
      light: "#00DF70",
      dark: "#00DF70",
      contrastText: "#fff",
    },

    secondary: {
      main: "#03001C",
      light: "#03001C",
      dark: "#03001C",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Arial, sans-serif",
    h1: {
      fontWeight: "bold",
      fontSize: "2rem",
    },
    h2: {
      fontWeight: "bold",
      fontSize: "1.5rem",
    },
    body1: {
      fontSize: "1rem",
      color: "#000",
    },
  },
});

export default theme;
