import { gql } from "@apollo/client";

const GET_QUICK_TASK = gql`
  query GetQuickTask($taskId: String!) {
    getQuickTask(input: { taskId: $taskId }) {
      title
      _id
      description
      color
      type
      link
      isFavorite
      isCompleted
      expiresTime
    }
  }
`;

export default GET_QUICK_TASK;
