import { useEffect, useState } from "react";
import { BiCalendarHeart } from "react-icons/bi";
import {
  BsArrowDownLeft,
  BsArrowUpRight,
  BsChatSquareDots,
} from "react-icons/bs";
import { FaPrayingHands } from "react-icons/fa";
import { MdOutlineMedicalServices } from "react-icons/md";
import { RiPulseFill } from "react-icons/ri";
import PageTitle from "../../shared/components/page-title";
import {
  BottonBall,
  MainContainer,
  TopBall,
} from "../../shared/components/templates/main-container/main-container";
import RadialChart from "./components/gauges/mini-gauge";
import TaskGauge from "./components/gauges/task-gauge";
import WaveChart from "./components/grafics";
import VerticalLinearStepper from "./components/stepper";
import {
  Gauge,
  Grafic,
  HighLight,
  HighLightIcon,
  HighLightItem,
  HighLightPercentage,
  HighLightinfo,
  HighlightDescription,
  HighlightTitle,
  HistoricContainer,
  MainContent,
  PillarItem,
  PillarTitle,
  Section,
  Stepper,
  Title,
  TitleVertical,
  VerticalCard,
} from "./style";
const Historic = () => {
  const data = {
    title: "Histórico",
    subtitle:
      "Com o objetivo de conscientização, buscar melhoraria e visualizar sua evolução.",
  };
  const [isMobile, setIsMobile] = useState(false);

  const singleChartData = {
    data: [
      ["Média Geral", "Mês"],
      ["Jan", 100],
      ["Fev", 200],
      ["Mar", 100],
      ["Abr", 180],
      ["Mai", 190],
      ["Jun", 185],
      ["Jul", 250],
    ],
    colors: ["#00df70"],
    series: {
      0: { lineWidth: 3 },
    },
  };
  const multipleChartData = {
    data: [
      ["Mês", "Biológico", "Essencial", "Social", "Emocional", "Espiritual"],
      ["Jan", 100, 50, 80, 70, 12],
      ["Fev", 200, 12, 85, 100, 48],
      ["Mar", 100, 25, 50, 10, 50],
      ["Abr", 180, 70, 60, 60, 80],
      ["Mai", 190, 60, 70, 90, 100],
      ["Jun", 185, 90, 80, 80, 90],
      ["Jul", 180, 70, 60, 100, 200],
    ],
    colors: ["#482aa7", "#5b8fb9", "#00df70", "#01c2b5", "#888888"],
    series: {
      0: { lineWidth: 3 },
      1: { lineWidth: 3 },
      2: { lineWidth: 3 },
      3: { lineWidth: 3 },
      4: { lineWidth: 3 },
    },
  };
  const pillars = [
    { id: 1, value: 67, type: 1 },
    { id: 2, value: 46, type: 2 },
    { id: 3, value: 15, type: 3 },
    { id: 4, value: 67, type: 4 },
    { id: 5, value: 46, type: 5 },
  ];

  const highlights = [
    {
      id: 1,
      type: 1,
      description: "Lorem ipsum dolor sit amet, ",
      currentUp: true,
      last: 17,
    },
    {
      id: 2,
      type: 3,
      description: "Lorem ipsum dolor sit amet, ",
      currentUp: false,
      last: 25,
    },
  ];
  const steps = [
    {
      id: 1,
      label: "Respiração Profunda",
      time: "2023-05-02 12:41:00",
    },
    {
      id: 2,
      label: "Tempo ao ar livre",
      time: "2023-05-02 11:00:00",
    },
    {
      id: 3,
      label: "Rir",
      time: "2023-05-02 09:00:00",
    },
    {
      id: 4,
      label: "Rir",
      time: "2023-05-02 17:45:00",
    },
    {
      id: 5,
      label: "Escutar música relaxante",
      time: "2023-05-02 23:30:00",
    },
  ];

  const getColor = (type: number): string => {
    switch (type) {
      case 1:
        return "#00df70";
      case 2:
        return "#01c2b5";
      case 3:
        return "#5b8fb9";
      case 4:
        return "#482aa7";
      case 5:
        return "#888888";
      default:
        return "";
    }
  };

  const getIcon = (type: number, size: number, setcolor: boolean) => {
    switch (type) {
      case 1:
        return (
          <RiPulseFill
            size={size}
            style={{
              color: setcolor ? getColor(type) : "white",
              margin: setcolor ? 20 : 0,
            }}
          />
        );
      case 2:
        return (
          <MdOutlineMedicalServices
            size={size}
            style={{
              color: setcolor ? getColor(type) : "white",
              margin: setcolor ? 20 : 0,
            }}
          />
        );
      case 3:
        return (
          <BsChatSquareDots
            size={size}
            style={{
              color: setcolor ? getColor(type) : "white",
              margin: setcolor ? 20 : 0,
            }}
          />
        );
      case 4:
        return (
          <BiCalendarHeart
            size={size}
            style={{
              color: setcolor ? getColor(type) : "white",
              margin: setcolor ? 20 : 0,
            }}
          />
        );
      case 5:
        return (
          <FaPrayingHands
            size={size}
            style={{
              color: setcolor ? getColor(type) : "white",
              margin: setcolor ? 20 : 0,
            }}
          />
        );
      default:
        return (
          <RiPulseFill
            size={size}
            style={{
              color: setcolor ? getColor(type) : "white",
              margin: setcolor ? 20 : 0,
            }}
          />
        );
    }
  };

  const getTitle = (type: number): string => {
    switch (type) {
      case 1:
        return "Biológico";
      case 2:
        return "Essencial";
      case 3:
        return "Social";
      case 4:
        return "Emocional";
      case 5:
        return "Espiritual";
      default:
        return "";
    }
  };

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(/Mobi|Android/i.test(navigator.userAgent));
    };
    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  return (
    <MainContainer>
      <TopBall />
      <BottonBall />
      <HistoricContainer>
        <h5
          className="animate__animated animate__fadeIn"
          style={{
            position: "fixed",
            left: 0,
            display: "flex",
            width: "100vw",
            pointerEvents: "none",
            height: "100vh",
            zIndex: 10000,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Em Breve
        </h5>
        <PageTitle prop={data} />
        <MainContent className="row" style={{ filter: "blur(13px)" }}>
          <Section className="col-12 col-md-6 col-lg-5">
            <Grafic className="animate__animated animate__fadeInLeft animate__delay-0.5s">
              <Title>Geral</Title>
              <WaveChart
                prop={{
                  ...singleChartData,
                  backgroundColor: isMobile ? "#f8f8f8" : "#ffffff",
                }}
              ></WaveChart>
            </Grafic>
            <br />
            <Grafic className="animate__animated animate__fadeInLeft animate__delay-0.5s">
              <Title>Pilares</Title>
              <WaveChart
                prop={{
                  ...multipleChartData,
                  backgroundColor: isMobile ? "#f8f8f8" : "#ffffff",
                }}
              ></WaveChart>
            </Grafic>
          </Section>
          <Section className="col-12 col-md-6 col-lg-4">
            <VerticalCard>
              <TitleVertical>Média Pilares</TitleVertical>
              {pillars.map((item) => (
                <PillarItem
                  key={item.id}
                  className="animate__animated animate__fadeInDown animate__delay-0.5s"
                >
                  <RadialChart prop={item}></RadialChart>
                  {getIcon(item.type, 30, true)}
                  <PillarTitle>{getTitle(item.type)}</PillarTitle>
                </PillarItem>
              ))}
              <TitleVertical>Destaques</TitleVertical>
              <HighLight>
                {highlights.map((item, index) => (
                  <HighLightItem className="animate__animated animate__fadeInUp animate__delay-0.5s">
                    <HighLightIcon
                      style={{ backgroundColor: getColor(item.type) }}
                    >
                      {getIcon(item.type, 25, false)}
                    </HighLightIcon>
                    <HighLightinfo>
                      <HighlightTitle>{getTitle(item.type)}</HighlightTitle>
                      <HighlightDescription>
                        {item.description}
                      </HighlightDescription>
                    </HighLightinfo>
                    <HighLightPercentage
                      style={{ color: item.currentUp ? "#35DB88" : "red" }}
                    >
                      {item.currentUp ? (
                        <BsArrowUpRight
                          size={18}
                          style={{ color: "#35DB88" }}
                        />
                      ) : (
                        <BsArrowDownLeft size={18} style={{ color: "red" }} />
                      )}
                      {item.last}%
                    </HighLightPercentage>
                  </HighLightItem>
                ))}
              </HighLight>
            </VerticalCard>
          </Section>
          <Section className="col-12 col-md-6 col-lg-3 animate__animated animate__fadeInRight animate__delay-0.5s">
            <VerticalCard>
              <TitleVertical>Tarefas diárias concluídas</TitleVertical>
              <Stepper>
                <VerticalLinearStepper prop={steps}></VerticalLinearStepper>
              </Stepper>
              <Gauge>
                <TaskGauge prop={pillars[0]}></TaskGauge>
              </Gauge>
            </VerticalCard>
          </Section>
        </MainContent>
      </HistoricContainer>
    </MainContainer>
  );
};
export default Historic;
