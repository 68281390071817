import { requestService } from "../../../core/services/request.service";
import { TPillar } from "../../models/dashboard/pillars.model";
import GET_ALL_PILLARS from "./queries/get-all-pillars.query";

class PillarService {
  async getAllPillars(): Promise<TPillar[] | null> {
    try {
      const result = await requestService.getAll(GET_ALL_PILLARS);
      return result.data.getAllPillars;
    } catch (error: any) {
      console.error("Error fetching all pillars:", error.message);
      return null;
    }
  }
}

export default PillarService;
