import { gql } from "@apollo/client";

const GET_SUPPORT_DATA = gql`
  query GetSupportData {
    getSupportData {
      inspiration {
        name
        linkImage
        color
        classificationType
        _pillarId
        _contentId
        _id
        description
        contentTypeName
        isVideo
        contentLink
        _articleId
      }
      care {
        name
        linkImage
        color
        classificationType
        _pillarId
        _contentId
        _id
        description
        contentTypeName
        isVideo
        contentLink
        _articleId
      }
      suggestion {
        name
        linkImage
        color
        classificationType
        _pillarId
        _contentId
        _id
        isVideo
        contentTypeName
        description
        contentLink
        _articleId
      }
    }
  }
`;

export default GET_SUPPORT_DATA;
