import { gql } from "@apollo/client";

const LIST_ARTICLES = gql`
  query ListArticles(
    $page: Int!
    $perPage: Int!
    $sort: String
    $sortDir: String
    $filter: String
  ) {
    listArticles(
      input: {
        page: $page
        perPage: $perPage
        sort: $sort
        sortDir: $sortDir
        filter: $filter
      }
    ) {
      total
      items {
        _id
        title
        image
        description
      }
    }
  }
`;

export default LIST_ARTICLES;
