import styled from "styled-components";

export const StyledDropzone = styled.div<{ isDragActive: boolean }>`
  border: 2px dashed #007bff;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  max-height: 150px;
  height: 150px;
  align-items: center;
  transition: border-color 0.3s ease;
  background-color: ${(props) => (props.isDragActive ? "#e7f3ff" : "#fff")};
  p {
    margin: 0;
  }
  &:hover {
    border-color: #0056b3;
  }
`;

export const FooterButtonsContainer = styled.div`
  .submit-button {
    text-decoration: none;
    border: none;
    background: #03001c;
    border-radius: 12px;
    color: white;
    font-weight: 300;
    font-size: 1.3rem;
    transition: 0.25s;
    padding: 5px;

    &:disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed !important;
    }
  }

  .submit-button:hover {
    background: #3eff9f;
  }

  .cancel-button {
    text-decoration: none;
    border: none;
    background: #03001c;
    border-radius: 12px;
    color: white;
    font-weight: 300;
    font-size: 1.3rem;
    transition: 0.25s;
    padding: 5px;
  }

  .cancel-button:hover {
    background: white;
    color: black;
    outline: 1px solid black;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #007bff;
  max-height: 150px;
  height: 150px;

  img {
    border-radius: 4px;
    width: 100px;
    height: 80px;
  }

  .remove-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.7);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    padding: 10px;
    transition: background 0.3s;

    &:hover {
      background: rgba(255, 0, 0, 0.7);
    }
  }
`;

export const TitleInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;
