import styled from "styled-components";

export const ContentButton = styled.button`
  transition: 0.25s;
  background: white;
  border-radius: 50%;
  color: black;
  display: flex;
  border: none;
  background: #f0f0f1;
  justify-content: center;
  padding: 10px;
  align-items: center;
  transform: scale(0.8);

  &:hover {
    color: white !important;
    cursor: pointer;
    background: black !important;
  }
`;

export const ModalContainer = styled.div`
  width: 100%;
  @media (max-width: 762px) {
    flex-direction: column;
  }
`;

export const ModalImg = styled.img`
  width: 100%;
  height: 100%;
  min-width: 300px;
  min-height: 500px;
  object-fit: cover;
  border-radius: 38px 0 0 38px;
  @media (max-width: 762px) {
    border-radius: 0;
  }
`;

export const ModalIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border-radius: 38px 0 0 38px;
  @media (max-width: 762px) {
    min-height: 400px;
    border-radius: 0;
    margin-top: 60px;
  }
`;

export const LeftArea = styled.div`
  min-height: 300px;
  @media (max-width: 762px) {
    width: 100%;
    border-radius: 0;
  }
`;

export const FavoriteButton = styled.div`
  .star {
    transition: 0.25s;
    background: white;
    border-radius: 50%;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0.8);
  }
  &:hover {
    .star {
      color: white !important;
      cursor: pointer;
      background: black !important;
    }
  }
`;

export const CloseBtn = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  @media (max-width: 762px) {
    position: absolute;
    top: 12px;
    right: 12px;
  }
`;

export const RightArea = styled.div`
  display: grid;
  justify-items: end;
  grid-auto-rows: auto;
  padding: 2%;

  @media (max-width: 762px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;
export const ModalTitle = styled.h1`
  width: 100%;
  text-align: left;
  font: normal normal bold 29px/34px Raleway;
  color: #000000;
  padding: 0 2% 0 2%;
  @media (max-width: 762px) {
    width: auto;
  }
`;
export const ModalDescription = styled.span`
  width: 100%;
  max-width: 370px;
  padding: 0 3% 0 3%;
  word-break: break-word;
  @media (max-width: 762px) {
    width: auto;
    word-break: break-word;
  }
`;
export const CheckBtn = styled.div`
  border-color: #ccc;
  border-radius: 15px;
  padding: 0 12px 20px 15px;
  height: 25px;
  cursor: pointer;
  @media (max-width: 762px) {
    width: auto;
  }
`;
export const CheckBtnArea = styled.div`
  width: 100%;
  float: left;
  @media (max-width: 762px) {
    width: auto;
  }
`;
