/* eslint-disable @typescript-eslint/no-unused-vars */
import { Skeleton } from "@mui/material";
import React, { useState } from "react";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import ContentModal from "../../../../shared/components/modal-content";
import { FavoritesDashboardTypeEnum } from "../../../../shared/enum/dashboardEnum";
import { SupportContentTypeEnum } from "../../../../shared/enum/support.enum";
import { TDashboardFavorites } from "../../../../shared/models/dashboard/pillars.model";
import { FavoritesProps } from "../../../../shared/models/favorites/favorites.model";
import { ISupportContent } from "../../../../shared/models/support/support.model";
import QuickTaskService from "../../../../shared/services/quickTasks/quickTasks.service";
import SupportService from "../../../../shared/services/support/support.service";
import {
  CarrouselControls,
  FavoritesContainer,
  FavoritesHeader,
} from "./style";
import "./style.scss";

const Favorites: React.FC<FavoritesProps> = ({
  favoriteItems,
  openTaskModal,
  loading,
  closeFavoriteModal,
}) => {
  const [currentItem, setCurrentItem] = useState(0);
  const [hoveredTaskId, setHoveredTaskId] = useState<string | null>(null);
  const _quickTaskService = new QuickTaskService();
  const handlePrevious = () => {
    setCurrentItem((prevItem) => (prevItem === 0 ? prevItem : prevItem - 4));
  };
  const [supportContentModalData, setSupportContentModalData] =
    useState<ISupportContent | null>(null);
  const [showSupportContentModal, setShowSupportContentModal] = useState(false);

  const supportService = new SupportService();

  const handleNext = () => {
    setCurrentItem((prevItem) =>
      prevItem >= favoriteItems.length - 4 ? prevItem : prevItem + 4
    );
  };

  const handleGetQuickTask = async (id: string) => {
    const result = await _quickTaskService.getQuickTaskById(id);
    openTaskModal(result);
  };

  const openSupportContentModal = async (itemFavorite: TDashboardFavorites) => {
    const result = await supportService.getUserSupportContent(
      itemFavorite._id || ""
    );
    const item: ISupportContent = {
      _id: result?._supportContentId,
      isFavorite: result?.isFavorited || false,
      isCompleted: result?.isCompleted || false,
      _contentId: itemFavorite?.contentTypeId || "",
      name: itemFavorite.title,
      description: itemFavorite.description,
      isVideo: itemFavorite.isVideo || false,
      color: itemFavorite.color,
      linkImage: itemFavorite?.linkImage || "",
      _pillarId: itemFavorite._pillarId,
      contentTypeName: itemFavorite.typeName || "",
      classificationType:
        itemFavorite?.classificationType || SupportContentTypeEnum.Inspiration,
      contentLink: itemFavorite.contentLink || "",
      _articleId: itemFavorite?._articleId,
    };
    setSupportContentModalData(item ? item : null);
    setShowSupportContentModal(true);
  };

  const getTypeName = (type: number, typeName?: string) => {
    switch (type) {
      case FavoritesDashboardTypeEnum.QuickTask:
        return "Tarefa";
      case FavoritesDashboardTypeEnum.Custom:
        return typeName;
    }
  };

  const getClassName = (type: number) => {
    switch (type) {
      case FavoritesDashboardTypeEnum.QuickTask:
        return "favorite-type3";
      case FavoritesDashboardTypeEnum.Custom:
        return "favorite-type2";
    }
  };

  const handleSwitchTypeFavoriteClick = (
    type: FavoritesDashboardTypeEnum,
    id: string,
    item?: TDashboardFavorites
  ) => {
    switch (type) {
      case FavoritesDashboardTypeEnum.QuickTask:
        handleGetQuickTask(id);
        break;

      case FavoritesDashboardTypeEnum.Custom:
        item && openSupportContentModal(item);
        break;

      default:
        handleGetQuickTask(id);
        break;
    }
  };

  const closeSupportContentModal = () => {
    setShowSupportContentModal(false);
    closeFavoriteModal(true);
  };

  const handleTaskMouseEnter = (taskId: string | null) => {
    setHoveredTaskId(taskId);
  };

  const handleTaskMouseLeave = () => {
    setHoveredTaskId(null);
  };

  const lightenColor = (color: string, percent: number) => {
    color = color.replace("#", "");
    const r = parseInt(color.slice(0, 2), 16);
    const g = parseInt(color.slice(2, 4), 16);
    const b = parseInt(color.slice(4, 6), 16);
    const newR = Math.min(255, r + (255 - r) * (percent / 110));
    const newG = Math.min(255, g + (255 - g) * (percent / 110));
    const newB = Math.min(255, b + (255 - b) * (percent / 110));
    const lighterColor = `#${Math.round(newR).toString(16)}${Math.round(
      newG
    ).toString(16)}${Math.round(newB).toString(16)}`;

    return lighterColor;
  };

  return (
    <>
      <FavoritesContainer>
        <FavoritesHeader>
          <span>Favoritos</span>
          <CarrouselControls>
            <div
              className={`previous ${
                currentItem === 0 ? "disabled" : "active"
              }`}
              onClick={handlePrevious}
            >
              <BsArrowLeftShort />
            </div>
            <div
              className={`next ${
                currentItem >= favoriteItems.length - 4 ? "disabled" : "active"
              }`}
              onClick={handleNext}
            >
              <BsArrowRightShort />
            </div>
          </CarrouselControls>
        </FavoritesHeader>
        {loading ? (
          <Skeleton variant="rounded" className="skeleton-full-width" />
        ) : (
          <div className="favorite-items">
            {favoriteItems.length === 0 ? (
              <div className="d-flex w-100 align-items-center justify-content-center">
                <h4>Nenhum item encontrado.</h4>
              </div>
            ) : (
              favoriteItems
                .slice(currentItem, currentItem + 4)
                .map((item, index) => {
                  const isActive = index < 4;
                  return (
                    <div
                      onClick={() =>
                        handleSwitchTypeFavoriteClick(item.type, item._id, item)
                      }
                      onMouseEnter={() =>
                        handleTaskMouseEnter(item._id || null)
                      }
                      onMouseLeave={handleTaskMouseLeave}
                      style={{
                        backgroundColor:
                          (item._id === hoveredTaskId
                            ? item.color
                            : lightenColor(item?.color || "", 85)) || "#fff",
                      }}
                      key={index}
                      className={`${getClassName(item?.type)} favorite-item ${
                        isActive ? "animate__animated animate__fadeIn" : ""
                      }`}
                    >
                      <div className="w-100">
                        <span className="px-2 py-1 title-favorite">
                          {getTypeName(item.type, item.typeName)}
                        </span>
                      </div>
                      <div className="description-favorite">{item?.title}</div>
                    </div>
                  );
                })
            )}
          </div>
        )}
      </FavoritesContainer>
      <ContentModal
        showModal={showSupportContentModal}
        closeModal={closeSupportContentModal}
        modalData={supportContentModalData}
      />
    </>
  );
};

export default Favorites;
