import Chart from "react-apexcharts";

const RadialChart = (props: any) => {
  const data = props.prop;

  const setColor = (type: number): string => {
    switch (type) {
      case 1:
        return "#00df70";
      case 2:
        return "#01c2b5";
      case 3:
        return "#5b8fb9";
      case 4:
        return "#482aa7";
      case 5:
        return "#888888";
      default:
        return "";
    }
  };
  const options = {
    chart: {
      type: "radialBar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "45%",
        },
        track: {
          background: "#ccc",
        },
        dataLabels: {
          showOn: "always",
          name: {
            show: false,
          },
          value: {
            show: true,
            fontSize: "18px",
            fontWeight: "bold",
            color: setColor(data.type),
            offsetY: 6,
            offsetX: 0,
            formatter: function (val: any) {
              return val;
            },
          },
          total: {
            show: false,
          },
        },
      },
    },
    series: [data.value],
    labels: ["Progresso"],

    fill: {
      colors: [setColor(data.type)],
    },
  };

  return (
    <div style={{ margin: 0, padding: 0, width: "65px" }}>
      <Chart
        className="chart"
        options={options as ApexCharts.ApexOptions}
        series={options.series}
        type="radialBar"
        height={120} // Altura do gráfico
      />
    </div>
  );
};

export default RadialChart;
