/* eslint-disable react-hooks/exhaustive-deps */
import "animate.css";
import { useEffect, useState } from "react";
import { BsCalendarEvent, BsClipboard2Data, BsFillGridFill, BsListTask } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import { RiHandHeartLine } from "react-icons/ri";
import { RxCardStack, RxExit } from "react-icons/rx";
import { useLocation, useNavigate } from "react-router-dom";
import logoImageClosed from "../../../assets/img/logo.svg";
import logoImageOpened from "../../../assets/img/logo2.svg";
import AuthService from "../../services/auth/auth.service";
import {
  FooterLinks,
  Hamburger,
  Nav,
  NavigationBar,
  Row,
  RowTitle,
  Span,
  StyledButton,
  StyledCloseIcon,
  StyledImageCloused,
  StyledImageOpened,
  StyledLink,
  StyledNavLink,
  Title,
  TopLinks,
} from "./style";
const SideBar = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const authService = new AuthService();
  const route = useLocation();
  const currentRoute: string = route.pathname;
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const navigate = useNavigate();
  const [isContentCreator, setIsContentCreator] = useState<boolean | null>(
    null
  );

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  const closeMobile = () => setIsHovered(isHovered ? false : true);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(/Mobi|Android/i.test(navigator.userAgent));
    };
    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      const user = await authService.getUser();
      if (user) {
        setIsContentCreator(user.isContentCreator);
      }
    };
    fetchUser();
  }, []);

  return (
    <>
      <Hamburger
        className="d-block d-md-none"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={closeMobile}
      >
        {isHovered ? (
          <StyledCloseIcon size={28}></StyledCloseIcon>
        ) : (
          <GiHamburgerMenu size={25}></GiHamburgerMenu>
        )}
      </Hamburger>
      <NavigationBar
        id="menu"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          width: isHovered ? "300px" : isMobile ? "0px" : "90px",
          transition: "width 0.4s ease",
        }}
      >
        <Nav>
          <StyledButton
            onClick={toggleMenu}
            className="btn-img animate__animated animate__fadeIn"
          >
            {isHovered ? (
              <StyledImageOpened src={logoImageOpened}></StyledImageOpened>
            ) : (
              <StyledImageCloused src={logoImageClosed}></StyledImageCloused>
            )}
          </StyledButton>

          <TopLinks className="links">
            <RowTitle>
              <Title
                className={
                  isHovered
                    ? "animate__animated animate__fadeInDown animate__delay-0.5s"
                    : ""
                }
              >
                <Span
                  className={
                    isHovered
                      ? "animate__animated animate__fadeInDown animate__delay-0.5s"
                      : ""
                  }
                >
                  {isHovered ? " GERAL" : <hr></hr>}
                </Span>
              </Title>
            </RowTitle>
            <Row>
              <StyledNavLink
                to="/dashboard"
                className={currentRoute === "/dashboard" ? "active" : ""}
              >
                <BsFillGridFill size={25} />
                <Span
                  className={
                    isHovered
                      ? "animate__animated animate__fadeInDown animate__delay-0.5s"
                      : ""
                  }
                >
                  {isHovered ? " DashBoard" : ""}
                </Span>
              </StyledNavLink>
            </Row>
            <Row>
              <StyledNavLink
                to="/monitoring"
                className={currentRoute === "/monitoring" ? "active" : ""}
              >
                <RiHandHeartLine size={25} />
                <Span
                  className={
                    isHovered
                      ? "animate__animated animate__fadeInDown animate__delay-0.5s"
                      : ""
                  }
                >
                  {isHovered ? " Apoio" : ""}
                </Span>
              </StyledNavLink>
            </Row>
            <Row>
              <StyledNavLink
                to="/historic"
                className={currentRoute === "/historic" ? "active" : ""}
              >
                <BsClipboard2Data size={25} />
                <Span
                  className={
                    isHovered
                      ? "animate__animated animate__fadeInDown animate__delay-0.5s"
                      : ""
                  }
                >
                  {isHovered ? " Histórico" : ""}
                </Span>
              </StyledNavLink>
            </Row>
            <Row>
              <RowTitle
                className={
                  isHovered
                    ? "animate__animated animate__fadeInDown animate__delay-0.5s"
                    : ""
                }
              >
                <Title>
                  <Span>{isHovered ? " FERRAMENTAS" : <hr></hr>}</Span>
                </Title>
              </RowTitle>
              <StyledNavLink to="/activities">
                <BsListTask size={25} />
                <Span
                  className={
                    isHovered
                      ? "animate__animated animate__fadeInDown animate__delay-0.5s"
                      : ""
                  }
                >
                  {isHovered ? " Atividades" : ""}
                </Span>
              </StyledNavLink>
            </Row>
            {isContentCreator && (
              <Row>
                <StyledNavLink to="/contents">
                  <RxCardStack size={25} />
                  <Span
                    className={
                      isHovered
                        ? "animate__animated animate__fadeInDown animate__delay-0.5s"
                        : ""
                    }
                  >
                    {isHovered ? " Conteúdos" : ""}
                  </Span>
                </StyledNavLink>
              </Row>
            )}
            <Row>
              <StyledNavLink to="/calendar">
                <BsCalendarEvent size={25} />
                <Span
                  className={
                    isHovered
                      ? "animate__animated animate__fadeInDown animate__delay-0.5s"
                      : ""
                  }
                >
                  {isHovered ? " Calendário" : ""}
                </Span>
              </StyledNavLink>
            </Row>
            {/* <Row>
              <StyledNavLink to="/chat">
                <BsChatSquareDots size={25} />
                <Span
                  className={
                    isHovered
                      ? "animate__animated animate__fadeInDown animate__delay-0.5s"
                      : ""
                  }
                >
                  {isHovered ? " Chat" : ""}
                </Span>
              </StyledNavLink>
            </Row> */}
            {/* <RowTitle
              className={
                isHovered
                  ? "animate__animated animate__fadeInDown animate__delay-0.5s"
                  : ""
              }
            >
              <Title>
                <Span>
                  <hr></hr>
                </Span>
              </Title>
            </RowTitle>
            <Row>
              <StyledNavLink to="">
                <img src={sosIcon} alt="" />
                <Span
                  className={
                    isHovered
                      ? "animate__animated animate__fadeInDown animate__delay-0.5s"
                      : ""
                  }
                >
                  {isHovered ? "Preciso de ajuda" : ""}
                </Span>
              </StyledNavLink>
            </Row> */}
          </TopLinks>
          <FooterLinks>
            {/* <Row>
              <br />
              <StyledNavLink to="/help">
                <BsQuestionCircleFill size={25} />
                <Span
                  className={
                    isHovered
                      ? "animate__animated animate__fadeInDown animate__delay-0.5s"
                      : ""
                  }
                >
                  {isHovered ? " Ajuda" : ""}
                </Span>
              </StyledNavLink>
            </Row> */}
            <Row>
              <StyledLink onClick={logout}>
                <RxExit size={25} />
                <Span
                  className={
                    isHovered ? "animate__animated animate__fadeInDown" : ""
                  }
                >
                  {isHovered ? " Log Out" : ""}
                </Span>
              </StyledLink>
            </Row>
          </FooterLinks>
        </Nav>
      </NavigationBar>
    </>
  );
};
export default SideBar;
