import styled from 'styled-components'

export const ModalContainer = styled.div`
  width: 100%;
  padding: 10px;
`

export const ModalHeader = styled.div`
  width: 100%;
  padding: 10px;
`
export const ModalTitle = styled.span`
  text-align: left;
  font: normal normal bold 22px/26px Raleway;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
`
export const CloseBtn = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
`

export const ModalBody = styled.div`
  width: 100%;
  padding: 10px;
`

export const ModalBtnAdd = styled.button`
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
  background-color: #5b8fb9;
  border: none;
  color: white;
`
