import { IoMdClose } from "react-icons/io";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const NavigationBar = styled.div`
  @media (max-width: 762px) {
    display: none !important;
  }
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  background-color: #03001c;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  overflow: hidden;
  z-index: 1000;
  hr {
    margin: 0;
    width: 28px;
    position: relative;
    left: -3px;
  }
`;
export const Nav = styled.nav``;
export const Span = styled.span`
  white-space: nowrap;
  width: 500px;
`;

export const StyledCloseIcon = styled(IoMdClose)`
  color: white;
`;

export const StyledButton = styled.button`
  /* height: 100px; */
  width: 95%;
  padding: 5px;
  margin-top: 30px;
  margin-right: 10px;
  background-color: transparent;
  border: none;
`;
export const Hamburger = styled.div`
  width: 50px;
  height: 50px;
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1001;
  @media (max-width: 762px) {
    display: none !important;
  }
`;
export const TopLinks = styled.div`
  padding: 30px 0;
`;
export const FooterLinks = styled.div`
  position: absolute;
  bottom: 0px;
`;
export const StyledImageOpened = styled.img`
  width: 200px;
`;
export const StyledImageCloused = styled.img`
  width: 64px;
`;

export const Row = styled.div`
  margin-bottom: 35px !important;
  width: 500px;
  @media (max-width: 768px) {
    margin-bottom: 18px !important;
  }
  @media only screen and (max-width: 1366px) {
    margin-bottom: 12px !important;
  }
`;

export const RowTitle = styled.div``;

export const StyledLink = styled.a`
  color: #fff;
  width: 300px;
  cursor: pointer;
  text-decoration: none;
  border-left: 4px solid #03001c;
  padding-left: 25px;
  transition: 0.25s;
  svg {
    margin-right: 10px;
  }

  &:hover {
    color: #00df70;
    border-left: 4px solid #00df70;
    svg {
      color: #00df70;
    }
  }
  &.active {
    color: #00df70;
    border-left: 4px solid #00df70;
    svg {
      color: #00df70;
    }
  }
  img {
    width: 31px;
    margin-right: 10px;
  }
  @media (max-width: 768px) {
    svg,
    img {
      width: 20px;
    }
  }
`
export const StyledNavLink = styled(NavLink)`
  color: #fff;
  width: 300px;
  text-decoration: none;
  border-left: 4px solid #03001c;
  padding-left: 25px;
  transition: 0.25s;
  svg {
    margin-right: 10px;
  }

  &:hover {
    color: #00df70;
    border-left: 4px solid #00df70;
    svg {
      color: #00df70;
    }
  }
  &.active {
    color: #00df70;
    border-left: 4px solid #00df70;
    svg {
      color: #00df70;
    }
  }
  img {
    width: 31px;
    margin-right: 10px;
  }
  @media (max-width: 768px) {
    svg,
    img {
      width: 20px;
    }
  }
`;

export const StyledNavLinkFooter = styled(NavLink)`
  color: #fff;
  width: 100vh;
  text-decoration: none;
  border-left: 4px solid #00df70;
  padding-left: 25px;
  margin-bottom: 15px;
  margin-top: 15px;
  transition: 0.25s;
  svg {
    margin-right: 10px;
  }
  &:hover {
    color: #00df70;
    border-left: 4px solid #00df70;
    svg {
      color: #00df70;
    }
  }
`;

export const Title = styled.h1`
  color: white;
  font-size: 15px;
  margin-left: 32px;
  margin-bottom: 20px;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
