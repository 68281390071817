import axios, { AxiosError, AxiosInstance } from "axios";
import { MessageServices } from "../../shared/services/message/message.service";

enum HttpStatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  InternalServerError = 500,
}

const messageService = new MessageServices();
const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_KEY,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const handleUnauthorized = () => {
  messageService.error("Você não tem permissão para acessar.");
  window.location.href = "/login";
};

const handleForbidden = () => {
  messageService.error("Você não tem permissão para acessar este recurso.");
};

const handleNotFound = () => {
  messageService.error("Recurso não encontrado.");
};

const handleServerError = () => {
  messageService.error("Erro interno no servidor. Tente novamente mais tarde.");
};

const errorHandlers: Record<number, () => void> = {
  [HttpStatusCode.Unauthorized]: handleUnauthorized,
  [HttpStatusCode.Forbidden]: handleForbidden,
  [HttpStatusCode.NotFound]: handleNotFound,
  [HttpStatusCode.InternalServerError]: handleServerError,
};

axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response && error.response.status) {
      const handler = errorHandlers[error.response.status];
      handler && handler();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
