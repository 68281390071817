export enum PillarDashboardEnum {
  Biological = "Biológico",
  Essential = "Essencial",
  Social = "Social",
  Emotional = "Emocional",
  Spiritual = "Espiritual",
}

export enum MonthPerformanceTypeEnum {
  Positive = 1,
  Negative = 2,
  Neutral = 3,
}

export enum QuickTaskTypeEnum {
  Image = 1,
  Video = 2,
}

export enum FavoritesDashboardTypeEnum {
  QuickTask = 1,
  Custom = 2,
}
