import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const PillarContainer = styled.div`
  width: 100%;
  padding: 10px 20px;
  height: 100%;
`;

export const PillarHeader = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ViewHistoryButton = styled(NavLink)`
  padding: 1rem;
  padding-top: 0.2rem;
  border-radius: 7px;
  cursor: pointer;
  padding-bottom: 0.2rem;
  background: #f0f0f1 0% 0% no-repeat padding-box;
  white-space: nowrap;
  color: black;
  display: flex;
  text-decoration: none !important;
  align-items: center;
  font-weight: 400;
  transition: 0.25s;
  svg {
    margin-right: 0.5rem;
  }

  &:hover {
    background: black;
    color: white;
  }
`;

export const PillarHeaderTitle = styled.span`
  width: 100%;
  font-family: Raleway;
  font-weight: bold;
  font-size: 28px;
  line-height: 33px;
  @media only screen and (max-width: 1540px) {
    font-size: 25px;
  }
`;
export const PillarCards = styled.div`
  width: 100%;
  height: 55%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
`;

export const PillarCenterCard = styled.div`
  color: white;
  border-radius: 14px;
  width: 20%;
  height: 100%;
  display: flex;
  margin-left: 5px;
  margin-right: 5px;
  background: white;
  box-shadow: 10px 15px 40px #3b4b761f;
  @media (max-width: 762px) {
    box-shadow: none;
  }
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const PillarCardTitle = styled.span``;
export const PillarCardValue = styled.span`
  font-size: 26px;
`;

export const PillarFooter = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: end;
  align-items: center;
`;
export const PillarFooterTitle = styled.span`
  text-align: left;
  font-family: "Times New Roman", Times, serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
  opacity: 1;
  @media only screen and (max-width: 1540px) {
    font-size: 18px;
  }
`;
export const PillarFooterPercentage = styled.span`
  text-align: left;
  font-weight: 600;
  font-size: 42px;
  line-height: 63px;
  color: #000000;
  opacity: 1;
  margin-left: 10px;
  @media only screen and (max-width: 1540px) {
    font-size: 25px;
  }
`;
export const PillarFooterPerLast = styled.div`
  border-radius: 6px;
  opacity: 1;
  margin-left: 10px;
  color: white;
  padding: 4.5px 12px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  svg {
    margin-right: 10px;
  }
  @media only screen and (max-width: 1540px) {
    padding: 3px 10px;
  }
`;
