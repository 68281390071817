import { Dialog, DialogTitle, Skeleton } from "@mui/material";
import { useState } from "react";
import { BiCalendarHeart } from "react-icons/bi";
import {
  BsArrowDownLeft,
  BsArrowUpRight,
  BsChatSquareDots,
  BsClockHistory,
} from "react-icons/bs";
import { FaPrayingHands } from "react-icons/fa";
import { MdOutlineMedicalServices } from "react-icons/md";
import { RiPulseFill } from "react-icons/ri";
import {
  MonthPerformanceTypeEnum,
  PillarDashboardEnum,
} from "../../../../shared/enum/dashboardEnum";
import {
  IPillarsDashboard,
  TPillar,
} from "../../../../shared/models/dashboard/pillars.model";
import MobilePillars from "./mobile";
import {
  PillarCardTitle,
  PillarCardValue,
  PillarCards,
  PillarCenterCard,
  PillarContainer,
  PillarFooter,
  PillarFooterPerLast,
  PillarFooterPercentage,
  PillarFooterTitle,
  PillarHeader,
  PillarHeaderTitle,
  ViewHistoryButton,
} from "./style";
import "./style.scss";
const Pillar: React.FC<IPillarsDashboard & { loading: boolean }> = (props) => {
  const isMobile: boolean = /Mobi|Android/i.test(navigator.userAgent);
  const {
    loading,
    pillars,
    monthPerformanceValue,
    overallMonthlyAssessment,
    monthPerformance,
  } = props;

  const [open, setOpen] = useState(false);
  const [selectedPillar, setSelectedPillar] = useState<TPillar | null>(null);

  const handleClickOpen = (pillar: TPillar | null) => {
    setSelectedPillar(pillar);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPillar(null);
  };

  const changeClassNamePillar = (pillarTitle: string) => {
    switch (pillarTitle) {
      case PillarDashboardEnum.Essential:
        return "essential";
      case PillarDashboardEnum.Emotional:
        return "blue-puple";
      case PillarDashboardEnum.Spiritual:
        return "gray";
      case PillarDashboardEnum.Biological:
        return "green";
      case PillarDashboardEnum.Social:
        return "blue-gray";
      default:
        return "gray";
    }
  };

  const changeClassNameMonthPerformance = (monthPerformance: number) => {
    switch (monthPerformance) {
      case MonthPerformanceTypeEnum.Positive:
        return "green-status";
      case MonthPerformanceTypeEnum.Negative:
        return "red-status";
      case MonthPerformanceTypeEnum.Neutral:
        return "gray-status";
      default:
        return "gray-status";
    }
  };

  const changeIconPillar = (pillarTitle: string) => {
    switch (pillarTitle) {
      case PillarDashboardEnum.Essential:
        return <MdOutlineMedicalServices size={isMobile ? 30 : 50} />;
      case PillarDashboardEnum.Emotional:
        return <BiCalendarHeart size={isMobile ? 30 : 50} />;
      case PillarDashboardEnum.Spiritual:
        return <FaPrayingHands size={isMobile ? 30 : 50} />;
      case PillarDashboardEnum.Biological:
        return <RiPulseFill size={isMobile ? 30 : 50} />;
      case PillarDashboardEnum.Social:
        return <BsChatSquareDots size={isMobile ? 30 : 50} />;
      default:
        return <RiPulseFill size={isMobile ? 30 : 50} />;
    }
  };

  const changeIconArrow = (monthPerformance: number) => {
    switch (monthPerformance) {
      case MonthPerformanceTypeEnum.Positive:
        return <BsArrowUpRight size={15} />;
      case MonthPerformanceTypeEnum.Negative:
        return <BsArrowDownLeft size={15} />;
      case MonthPerformanceTypeEnum.Neutral:
        return <></>;
      default:
        return <></>;
    }
  };

  return (
    <>
      {!isMobile ? (
        <PillarContainer>
          <PillarHeader>
            <PillarHeaderTitle>Pilares</PillarHeaderTitle>
            <ViewHistoryButton to="/historic">
              <BsClockHistory size={15} />
              Ver Histórico
            </ViewHistoryButton>
          </PillarHeader>
          <PillarCards>
            {loading ? (
              <Skeleton variant="rounded" className="skeleton-full-width" />
            ) : (
              <>
                {pillars.map((pillarCardData) => (
                  <PillarCenterCard
                    style={{ cursor: "pointer" }}
                    key={pillarCardData.name}
                    className={
                      changeClassNamePillar(pillarCardData.name) +
                      " animate__animated animate__fadeIn animate__delay-0.5s"
                    }
                    onClick={() => handleClickOpen(pillarCardData)}
                  >
                    {changeIconPillar(pillarCardData.name)}
                    <PillarCardTitle>
                      {isMobile ? "" : pillarCardData.name}
                    </PillarCardTitle>
                    <PillarCardValue>
                      {Number.isInteger(pillarCardData?.value)
                        ? pillarCardData?.value
                        : pillarCardData?.value?.toFixed(2) || "0"}
                    </PillarCardValue>
                  </PillarCenterCard>
                ))}
              </>
            )}
          </PillarCards>
          <PillarFooter>
            {loading ? (
              <Skeleton variant="text" width={250} sx={{ fontSize: "2rem" }} />
            ) : (
              <>
                <PillarFooterTitle className="animate__animated animate__fadeIn">
                  Avaliação geral mensal
                </PillarFooterTitle>
                <PillarFooterPercentage className="animate__animated animate__fadeIn">
                  {Number.isInteger(overallMonthlyAssessment)
                    ? overallMonthlyAssessment
                    : overallMonthlyAssessment.toFixed(2) || "0"}
                  %
                </PillarFooterPercentage>
                <PillarFooterPerLast
                  className={
                    changeClassNameMonthPerformance(monthPerformance) +
                    " animate__animated animate__fadeIn"
                  }
                >
                  {changeIconArrow(monthPerformance)}
                  {Number.isInteger(monthPerformanceValue)
                    ? monthPerformanceValue
                    : monthPerformanceValue.toFixed(2) || "0"}
                  %
                </PillarFooterPerLast>
              </>
            )}
          </PillarFooter>
        </PillarContainer>
      ) : (
        <MobilePillars
          biologicalScore={
            pillars.find(
              (pillar) => pillar.name === PillarDashboardEnum.Biological
            )?.value || 0
          }
          essentialScore={
            pillars.find(
              (pillar) => pillar.name === PillarDashboardEnum.Essential
            )?.value || 0
          }
          socialScore={
            pillars.find((pillar) => pillar.name === PillarDashboardEnum.Social)
              ?.value || 0
          }
          mentalScore={
            pillars.find(
              (pillar) => pillar.name === PillarDashboardEnum.Emotional
            )?.value || 0
          }
          spiritualScore={
            pillars.find(
              (pillar) => pillar.name === PillarDashboardEnum.Essential
            )?.value || 0
          }
          handleClickOpen={(pillarName: PillarDashboardEnum) => {
            handleClickOpen(
              pillars.find((pillar) => pillar.name === pillarName) || null
            );
          }}
        ></MobilePillars>
      )}

      <Dialog
        style={{ overflowX: "hidden" }}
        open={open}
        onClose={handleClose}
        fullWidth
      >
        <DialogTitle
          style={{ border: 'none', fontWeight: '600' }}
          className={changeClassNamePillar(selectedPillar?.name || "")}
        >
          {selectedPillar?.name || ""}
        </DialogTitle>
        <PillarCenterCard
          style={{ borderRadius: 0, minHeight: "300px" }}
          key={selectedPillar?.name || ""}
          className={
            changeClassNamePillar(selectedPillar?.name || "") +
            " animate__animated animate__fadeIn animate__delay-0.5s w-100 h-100 m-0"
          }
        >
          {changeIconPillar(selectedPillar?.name || "")}
          <PillarCardTitle style={{ textAlign: "center" }} className="mt-3">
            {selectedPillar?.description || ""}
          </PillarCardTitle>
        </PillarCenterCard>
      </Dialog>
    </>
  );
};

export default Pillar;
