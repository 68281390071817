import { BsCalendar3Event } from "react-icons/bs";

import { Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import badFaceIcon from "../../../../assets/img/bad_face_icon.svg";
import emptyFaceIcon from "../../../../assets/img/empty_face_icon.svg";
import happyFaceIcon from "../../../../assets/img/happy_face_icon.svg";
import neutralFaceIcon from "../../../../assets/img/neutral_face_icon.svg";
import sadFaceIcon from "../../../../assets/img/sad_face_icon.svg";
import smileFaceIcon from "../../../../assets/img/smile_face_icon.svg";
import CalendarHumor from "../../../../shared/components/humor-calendar";
import MoodSelection from "../../../../shared/components/mood-selection";
import { Mood, TypeDailyMessage } from "../../../../shared/enum/moodEnum";
import { TDashboardMood } from "../../../../shared/models/dashboard/pillars.model";
import {
  HumorContainer,
  HumorHeader,
  HumorImage,
  HumorImageArea,
  HumorMessage,
  HumorTitleCalendar,
} from "./style";

const Humor = (props: {
  prop: TDashboardMood | null;
  loading: boolean;
  onCloseMoodSelection: (
    completed: boolean,
    favorited: boolean,
    cycle: boolean
  ) => Promise<void>;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showMoodSelection, setShowMoodSelection] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
    showModal && props.onCloseMoodSelection(false, false, false);
  };

  const toggleMoodSelection = () => {
    setShowMoodSelection(!showMoodSelection);
    props.onCloseMoodSelection(false, false, false);
  };

  useEffect(() => {
    const hasQuestion = sessionStorage?.getItem("hasQuestions") === "true";
    setShowMoodSelection(!hasQuestion && props.prop?.mood === Mood.Empty);
  }, [props.prop?.mood]);

  const getMoodIcon = (mood: Mood) => {
    switch (mood) {
      case Mood.Happy:
        return <HumorImage src={happyFaceIcon} alt="" />;
      case Mood.Sad:
        return <HumorImage src={sadFaceIcon} alt="" />;
      case Mood.Smile:
        return <HumorImage src={smileFaceIcon} alt="" />;
      case Mood.Bad:
        return <HumorImage src={badFaceIcon} alt="" />;
      case Mood.Neutral:
        return <HumorImage src={neutralFaceIcon} alt="" />;
      default:
        return <HumorImage src={emptyFaceIcon} alt="" />;
    }
  };

  return (
    <HumorContainer>
      {props.loading ? (
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          <Skeleton variant="circular" width={90} height={90} />
          <div className="d-flex justify-content-center align-items-center flex-column w-80 ms-4">
            <Skeleton
              className="w-100"
              variant="text"
              sx={{ fontSize: "1.3rem" }}
            />
            <Skeleton
              className="w-90"
              variant="text"
              sx={{ fontSize: "1.3rem" }}
            />
          </div>
        </div>
      ) : (
        <>
          <HumorHeader className="animate__animated animate__fadeIn">
            <HumorTitleCalendar onClick={toggleModal}>
              <BsCalendar3Event size={15} />
            </HumorTitleCalendar>
          </HumorHeader>
          <HumorImageArea className="animate__animated animate__fadeIn">
            {getMoodIcon(props?.prop?.mood || Mood.Empty)}
            <HumorMessage>
              "{props.prop?.motivationalMessage}
              {props.prop?.typeMessage === TypeDailyMessage.Biblical
                ? "- " + props.prop.sourceMessage
                : ""}
              "
            </HumorMessage>
          </HumorImageArea>
        </>
      )}
      {showMoodSelection && <MoodSelection onClose={toggleMoodSelection} />}
      {showModal && <CalendarHumor onClose={toggleModal} />}
    </HumorContainer>
  );
};

export default Humor;
