import { gql } from "@apollo/client";

const GET_ALL_SPECIALITIES = gql`
  query GetAllSpecialities {
    getAllSpecialities {
      _id
      name
    }
  }
`;

export default GET_ALL_SPECIALITIES;
