import { Dropdown } from "react-bootstrap";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-top: 30px;
  height: 80vh;
`;

export const Card = styled.div`
  border-radius: 14px;
  background: white;
  @media (max-width: 762px) {
    background-color: rgba(248, 248, 248, 1);
  }
  height: 22rem;
`;

export const CardHead = styled.div`
  border-radius: 14px 14px 0 0;
  height: 12%;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
`;

export const CardTitle = styled.span`
  color: white;
  margin-left: 25px;
  text-align: left;
  font: normal normal bold 18px/21px Raleway;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  @media (max-width: 762px) {
    font-size: 0.9rem !important;
    margin-left: 0;
  }
`;
export const CardTitleToView = styled.span`
  color: #212529;
  font-weight: bold;
  text-align: left;
  font-size: 1.7rem;
  letter-spacing: 0px;
  opacity: 1;
  @media (max-width: 762px) {
    .title {
      font-size: 14px;
    }
  }
  .filter-icon {
    cursor: pointer;
    transition: 0.25s;
    &:hover {
      color: #00df70;
    }
    @media (max-width: 762px) {
      font-size: 1.2rem;
    }
  }
`;
export const CardTaskBody = styled.div`
  padding: 10px 10px 10px 20px;
  height: 85%;
  width: 102%;
`;
export const CardTaskBodyTop = styled.div`
  height: 70%;
`;
export const CardTask = styled.div`
  border-radius: 15px;
  padding: 13px;
  height: 90px;
  cursor: pointer;
  transition: 0.25s !important;
  .icon,
  .title {
    transition: 0.25s;
  }
  &:hover {
    .icon,
    .title {
      color: #ffffff !important;
    }
  }
`;
export const LoadingSkeleton = styled.div`
  width: 100%;
  height: 85%;
`;

export const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  .title {
    text-align: left;
    font: normal normal bold 18px/21px Raleway;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
`;

export const CustomFilterOption = styled(Dropdown.Item)`
  &:hover {
    background: #e1faed;
  }
`;
