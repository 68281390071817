import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { PillarDashboardEnum } from "../../../../../shared/enum/dashboardEnum";

interface HealthScoreProps {
  biologicalScore: number;
  essentialScore: number;
  socialScore: number;
  mentalScore: number;
  spiritualScore: number;
  handleClickOpen: (pillarCardData: PillarDashboardEnum) => void;
}

function MobilePillars({
  biologicalScore,
  essentialScore,
  socialScore,
  mentalScore,
  spiritualScore,
  handleClickOpen,
}: HealthScoreProps) {
  const navigate = useNavigate();
  return (
    <HealthScoreContainer>
      <Header>
        <Title>Geral</Title>
        <HistoryLink>
          <HistoryIcon
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/67b46ad5434bcddac258af67ceb3becdcdf09f613b7c6470ac1f6c664f8a83c1?apiKey=a7d67dd512364d7190520da778c77636&"
            alt="History icon"
          />
          <HistoryText
            onClick={() => {
              navigate("/historic");
            }}
          >
            Ver histórico
          </HistoryText>
        </HistoryLink>
      </Header>
      <ScoreItem
        onClick={() => {
          handleClickOpen(PillarDashboardEnum.Biological);
        }}
      >
        <ScoreDetails>
          <BiologicalIcon
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/e4b8bbfeba0829bb8bff00192c04d3dff1930b9e590b3ab52725324111f70f2e?apiKey=a7d67dd512364d7190520da778c77636&"
            alt="Biological icon"
          />
          <ScoreLabel>Biológico</ScoreLabel>
        </ScoreDetails>
        <ScoreValue backgroundColor="#27c275">{biologicalScore}</ScoreValue>
      </ScoreItem>
      <ScoreItem
        onClick={() => {
          handleClickOpen(PillarDashboardEnum.Essential);
        }}
      >
        <ScoreDetails>
          <EssentialIcon
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/643ebbfc0be077f45378bde53eacb47204fec345f95763768e287363b62228e9?apiKey=a7d67dd512364d7190520da778c77636&"
            alt="Essential icon"
          />
          <ScoreLabel>Essencial</ScoreLabel>
        </ScoreDetails>
        <ScoreValue backgroundColor="#01c2b5">{essentialScore}</ScoreValue>
      </ScoreItem>
      <ScoreItem
        onClick={() => {
          handleClickOpen(PillarDashboardEnum.Social);
        }}
      >
        <ScoreDetails>
          <SocialIcon
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/febf6bbcfea17daae8c15fe0bdc5314676c0b06c62e42be9a941e901a83e531e?apiKey=a7d67dd512364d7190520da778c77636&"
            alt="Social icon"
          />
          <ScoreLabel>Social</ScoreLabel>
        </ScoreDetails>
        <ScoreValue backgroundColor="#5b8fb9">{socialScore}</ScoreValue>
      </ScoreItem>
      <ScoreItem
        onClick={() => {
          handleClickOpen(PillarDashboardEnum.Emotional);
        }}
      >
        <ScoreDetails>
          <MentalIcon
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/9a05836082b1c0c2dd038ea1df414a1addd56305e672e71d57b11f026f80746a?apiKey=a7d67dd512364d7190520da778c77636&"
            alt="Mental icon"
          />
          <ScoreLabel>Emocional</ScoreLabel>
        </ScoreDetails>
        <ScoreValue backgroundColor="#482aa7">{mentalScore}</ScoreValue>
      </ScoreItem>
      <ScoreItem
        onClick={() => {
          handleClickOpen(PillarDashboardEnum.Spiritual);
        }}
      >
        <ScoreDetails>
          <SpiritualIcon
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/c90b5d72db37c60a1303f12fb9da8e48f2fd3c98147ab14750cc2b4e1a651a81?apiKey=a7d67dd512364d7190520da778c77636&"
            alt="Spiritual icon"
          />
          <ScoreLabel>Espiritual</ScoreLabel>
        </ScoreDetails>
        <ScoreValue backgroundColor="#888">{spiritualScore}</ScoreValue>
      </ScoreItem>
    </HealthScoreContainer>
  );
}
export default MobilePillars;
const HealthScoreContainer = styled.div`
  border-radius: 20px;
  background-color: rgba(248, 248, 248, 1);
  border: 1px solid rgba(246, 246, 246, 1);
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px 14px;
`;

const Header = styled.header`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

const Title = styled.h2`
  color: #000;
  margin: 0;
  font: 600 14px Poppins, sans-serif;
`;

const HistoryLink = styled.div`
  border-radius: 4px;
  background-color: #f0f0f1;
  display: flex;
  gap: 5px;
  font-size: 12px;
  color: #03001c;
  font-weight: 400;
  padding: 5px 6px;
  align-items: center;
`;

const HistoryIcon = styled.img`
  width: 11px;
`;

const HistoryText = styled.span`
  font-family: Poppins, sans-serif;
`;

const ScoreItem = styled.div`
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  margin-top: 10px;
  width: 100%;
  padding-left: 9px;
  gap: 20px;
  white-space: nowrap;
  text-align: center;
  justify-content: space-between;
  align-items: center;
`;

const ScoreDetails = styled.div`
  display: flex;
  gap: 16px;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
`;

const BiologicalIcon = styled.img`
  width: 33px;
  fill: #27c275;
`;

const EssentialIcon = styled.img`
  width: 26px;
`;

const SocialIcon = styled.img`
  width: 27px;
`;

const MentalIcon = styled.img`
  width: 24px;
`;

const SpiritualIcon = styled.img`
  width: 27px;
`;

const ScoreLabel = styled.span`
  font-family: Poppins, sans-serif;
`;

const ScoreValue = styled.div<{ backgroundColor: string }>`
  border-radius: 8px 0px 0px 8px;
  background-color: ${(props) => props.backgroundColor};
  color: #fff;
  justify-content: center;
  padding: 12px 10px;
  font: 700 16px Poppins, sans-serif;
`;
