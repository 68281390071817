import styled from 'styled-components'

export const ActivitiesContainer = styled.div`
  width: 80vw;
  margin-left: 10vw;
  @media only screen and (max-width: 760px) {
    width: 89.5vw;
    margin-left: 8.5vw;
  }
`
export const MainContent = styled.div`
  width: 100%;
  margin-top: 30px;
`
