import { gql } from "@apollo/client";

const GET_FILE = gql`
  query GetFile($input: FileGetFileInput!) {
    getFile(input: $input) {
      url
    }
  }
`;

export default GET_FILE;
