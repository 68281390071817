import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export function SelfCareChoiceMobile() {
  const navigate = useNavigate();

  return (
    <Div>
      <Div2
        className="animate__animated animate__fadeInDown"
        onClick={() => {
          navigate("/activities");
        }}
      >
        <Img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/7ffbf144e6de71b0b5b542f4d11ee768668213ff334c878b386f4b0f777ba839?apiKey=a7d67dd512364d7190520da778c77636&"
        />
        <Div3>Hábitos para autocuidado</Div3>
      </Div2>
      <Div4
        onClick={() => {
          navigate("/activities/selfCareHabits/adopt");
        }}
        className="animate__animated animate__fadeInLeft"
      >
        Hábitos que
        <br />
        quero <span style={{ fontWeight: 800 }}>adotar</span>
      </Div4>
      <Div5
        onClick={() => {
          navigate("/activities/selfCareHabits/abadon");
        }}
        className="animate__animated animate__fadeInRight"
      >
        Hábitos que
        <br />
        quero <span style={{ fontWeight: 800 }}>abandonar</span>
      </Div5>
    </Div>
  );
}
const Div = styled.div`
  background-color: #fdfdfd;
  display: flex;
  max-width: 480px;
  width: 100%;
  padding-bottom: 19px;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  color: #000;
  font-weight: 500;
  line-height: 20px;
  margin: 0 auto;
`;

const Div2 = styled.div`
  backdrop-filter: blur(10px);
  border-color: rgba(246, 246, 246, 1);
  border-style: solid;
  border-width: 1px;
  background-color: rgba(255, 255, 255, 0.4);
  align-self: stretch;
  display: flex;
  gap: 6px;
  font-size: 14px;
  font-weight: 600;
  line-height: 99%;
  padding: 58px 16px 29px;
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 16px;
  align-self: start;
`;

const Div3 = styled.div`
  font-family: Poppins, sans-serif;
  flex-grow: 1;
  flex-basis: auto;
`;

const Div4 = styled.div`
  font-family: Poppins, sans-serif;
  border-radius: 20px;
  background-color: rgba(246, 246, 246, 1);
  border-color: rgba(248, 248, 248, 1);
  border-style: solid;
  border-width: 1px;
  margin-top: 20px;
  width: 350px;
  align-items: start;
  justify-content: center;
  aspect-ratio: 1;
  padding: 75px 47px 38px;
`;

const Div5 = styled.div`
  font-family: Poppins, sans-serif;
  border-radius: 20px;
  background-color: rgba(246, 246, 246, 1);
  border-color: rgba(248, 248, 248, 1);
  border-style: solid;
  border-width: 1px;
  margin-top: 12px;
  width: 350px;
  align-items: start;
  justify-content: center;
  aspect-ratio: 1;
  padding: 75px 48px 38px;
`;
