/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { RingLoader } from "react-spinners";
import styled from "styled-components";
import {
    ActivitiesServices,
    ExerciseMemoryItem,
} from "../../../../../shared/services/activities/activities.service";
import { MessageServices } from "../../../../../shared/services/message/message.service";
import { ExercisiesActions } from "../../../components/activity-card/style";
import ExerciciesModal from "../../../components/modal-execisies";

export function ExerciseMemory() {
  const [Exercises, setExercisesItems] = useState<ExerciseMemoryItem[] | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  const _activitiesService = new ActivitiesServices();
  const _messageService = new MessageServices();
  const [showExerciseModal, setShowExerciseModal] = useState(false);
  const [modalExerciseData, setModalExerciseData] =
    useState<ExerciseMemoryItem | null>(null);
  const navigate = useNavigate();

  const loadExerciseData = async () => {
    setLoading(true);
    const response = await _activitiesService.listExerciseMemories(1, 100);
    setExercisesItems(response);
    setLoading(false);
  };

  const openExerciseModal = (item?: ExerciseMemoryItem) => {
    setModalExerciseData(item ? item : null);
    setShowExerciseModal(true);
  };

  const removeExercise = async (item: ExerciseMemoryItem): Promise<void> => {
    await _activitiesService.deleteExerciseMemory({ memoryId: item._id });
    await loadExerciseData();
    _messageService.success("Sucesso!", "Item removido com sucesso.");
  };
  const closeExerciseModal = () => {
    setShowExerciseModal(false);
  };

  useEffect(() => {
    loadExerciseData();
  }, []);

  return (
    <>
      <ExerciciesModal
        showModal={showExerciseModal}
        closeModal={closeExerciseModal}
        modalData={modalExerciseData}
        onSave={async function (): Promise<void> {
          await loadExerciseData();
        }}
      />
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 100000000000,
          }}
        >
          <RingLoader color="#00df70" loading={true} />
        </div>
      )}
      <Div>
        <Div2 className="animate__animated animate__fadeInDown">
          <Div3
            onClick={() => {
              navigate("/activities");
            }}
            className="animate__animated animate__fadeInDown"
          >
            <Img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/8f98bf3b77f58c285e765b7c9d0b3eb4d23f167ea3881a010a1be60118766311?apiKey=a7d67dd512364d7190520da778c77636&"
            />
            <Div4>Exercícios de trazer à memória</Div4>
          </Div3>
          <Img2
            onClick={() => openExerciseModal()}
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/1fcc0d6a27459c90ef9a04c386588551300765a74202f97754b83adca60baafc?apiKey=a7d67dd512364d7190520da778c77636&"
          />
        </Div2>

        {Exercises?.map((item) => {
          return (
            <Div5>
              <Div6
                className="my-2 d-flex justify-content-between align-items-center "
                key={item._id}
              >
                {item.description}
                <ExercisiesActions>
                  <MdEdit style={{ float: "right" }} size={20} />
                  <Dropdown>
                    <Dropdown.Toggle
                      style={{ marginTop: -20, marginLeft: -15 }}
                      variant="secondary"
                      id="dropdown-button"
                    ></Dropdown.Toggle>
                    <Dropdown.Menu className="options-dropdown">
                      <Dropdown.Item
                        style={{
                          color: "#ccc",
                          fontWeight: "bold",
                        }}
                        onClick={() => openExerciseModal(item)}
                      >
                        Editar
                      </Dropdown.Item>
                      <Dropdown.Item
                        style={{ color: "red", fontWeight: "bold" }}
                        onClick={() => removeExercise(item)}
                      >
                        Excluir
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </ExercisiesActions>
              </Div6>
            </Div5>
          );
        })}
        {Exercises?.length === 0 && (
          <Div5 className="animate__animated animate__fadeInLeft">
            <Div6>Não há items para exibir.</Div6>
          </Div5>
        )}
      </Div>
    </>
  );
}

const Div = styled.div`
  background-color: #fdfdfd;
  display: flex;
  max-width: 480px;
  width: 100%;
  padding-bottom: 80px;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
  color: #000;
  font-weight: 400;
  line-height: 100.5%;
  margin: 0 auto;
`;

const Div2 = styled.div`
  backdrop-filter: blur(10px);
  border-color: rgba(246, 246, 246, 1);
  border-style: solid;
  border-width: 1px;
  background-color: rgba(255, 255, 255, 0.4);
  align-self: stretch;
  display: flex;
  width: 100%;
  align-items: start;
  gap: 20px;
  font-weight: 500;
  justify-content: space-between;
  padding: 56px 18px 29px;
`;

const Div3 = styled.div`
  display: flex;
  gap: 6px;
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 16px;
`;

const Div4 = styled.div`
  font-family: Poppins, sans-serif;
  flex-grow: 1;
  flex-basis: auto;
`;

const Div5 = styled.div`
  align-self: center;
  display: flex;
  margin-top: 14px;
  width: 100%;
  max-width: 350px;
  flex-direction: column;
`;

const Div6 = styled.div`
  font-family: Poppins, sans-serif;
  border-radius: 8px;
  border-color: rgba(248, 248, 248, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #f6f6f6;
  align-items: start;
  justify-content: center;
  padding: 20px 14px;
`;

const Img2 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 19px;
`;
