import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { IoIosClose } from "react-icons/io";
import { RingLoader } from "react-spinners";
import { TExeciciesModalProps } from "../../../../shared/models/activities/activities.modal";
import { ActivitiesServices } from "../../../../shared/services/activities/activities.service";
import { MessageServices } from "../../../../shared/services/message/message.service";
import { CloseBtn, ModalBtnAdd, ModalContainer, ModalTitle } from "./style";
import "./style.css";

const ExerciciesModal: React.FC<TExeciciesModalProps> = ({
  showModal,
  closeModal,
  modalData,
  onSave,
}) => {
  const [loading, setLoading] = useState(false);
  const _activitiesService = new ActivitiesServices();
  const _messageService = new MessageServices();
  const [id, setId] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (modalData) {
      setId(modalData._id);
      setDescription(modalData.description);
    } else {
      cleanData();
    }
  }, [modalData]);

  const setDescriptionValue = (event: any) => {
    const value = event.target.value;
    if (value.length <= 40) {
      setDescription(value);
      setError("");
    } else {
      setError("A descrição deve conter no máximo 40 caracteres.");
    }
  };

  const saveData = async (): Promise<void> => {
    setLoading(true);
    if (!description) {
      _messageService.error("Erro", "Campo de descrição é obrigatório");
      setLoading(false);
      return;
    }
    !modalData
      ? await _activitiesService.createExerciseMemory({
          description,
        })
      : await _activitiesService.editExerciseMemory({
          description,
          memoryId: id || "",
        });
    const message =
      id === "" ? "Item cadastrado com sucesso!" : "Item editado com sucesso.";
    setLoading(false);
    _messageService.success("Sucesso", message);
    cleanData();
    closeModal();
    onSave();
  };

  const cleanData = () => {
    setId("");
    setDescription("");
    setError("");
  };

  return (
    <Modal
      show={showModal}
      onHide={() => closeModal()}
      className="custom-modal "
    >
      <ModalContainer>
        <ModalHeader>
          <ModalTitle>Exercício de trazer a memória</ModalTitle>
          <CloseBtn>
            <IoIosClose
              onClick={closeModal}
              size={28}
              style={{ color: "gray", marginRight: 25, cursor: "pointer" }}
            />
          </CloseBtn>
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <label className="form-label shadow-none">Descrição</label>
            <input
              type="text"
              className="form-control"
              value={description}
              onChange={setDescriptionValue}
              maxLength={40}
            />
            {error && <div className="text-danger">{error}</div>}
          </div>
          {loading && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <RingLoader color="#00df70" loading={true} />
            </div>
          )}
          {!loading && (
            <ModalBtnAdd style={{ background: "#482AA7" }} onClick={saveData}>
              Salvar
            </ModalBtnAdd>
          )}
        </ModalBody>
      </ModalContainer>
    </Modal>
  );
};

export default ExerciciesModal;
