import PageTitle from '../../shared/components/page-title'
import { MainContainer } from '../../shared/components/templates/main-container/main-container'
import { ConfigurationsContainer } from './style'
const Configurations = () => {
  const data = {
    title: 'Configurações',
    subtitle:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod'
  }
  return (
    <MainContainer>
      <ConfigurationsContainer>
        <PageTitle prop={data} />
      </ConfigurationsContainer>
    </MainContainer>
  )
}
export default Configurations
