import styled from "styled-components";

export const TitleContainer = styled.div`
  padding-top: 40px;
  width: 100%;
`;

export const FlexDiv = styled.div`
  display: flex;
  @media (max-width: 762px) {
    .logo {
      width: 80px;
      position: absolute;
      top: 15px;
      left: 10px;
      padding: 15px;
    }
  }
`;

export const TextArea = styled.div`
  width: 90%;
  @media (max-width: 762px) {
    position: absolute;
    top: 15px;
    left: 0;
    display: flex;
    justify-content: center;
    width: 90%;
    margin-top: 22px;
  }
`;

export const InfoArea = styled.div`
  width: 10%;
  min-width: 150px;
  margin-top: -25px;
  display: flex;
  justify-content: space-around;
  @media (max-width: 762px) {
    justify-content: end;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    .notification-container {
      margin-right: 15px;
    }
    
  }
`;

export const Title = styled.h1`
  width: 100%;
  text-align: left;
  font: normal normal bold 29px/34px Raleway;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  animation: fadeInDown;
  animation-duration: 1s;
  @media (max-width: 762px) {
    font-size: 0.9rem;
    background-color: #00df70 !important;
    width: auto;
    padding-left: 8%;
    padding-right: 8%;
    border-radius: 15px;
    color: white;
    font-weight: 500;
    margin: 0;
  }
`;

export const SubTitle = styled.span`
  width: 100%;
  text-align: left;
  font: normal normal normal 21px/26px Raleway;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  @media (max-width: 762px) {
    display: none;
  }
`;
