import styled from "styled-components";

export const ModalContainer = styled.div`
  width: 100%;
`;

export const ModalPillars = styled.div`
  .pillars {
    padding: 10px;
    &:hover {
      cursor: pointer;
      outline: 2px solid #35db88;
    }
    &.active {
      outline: 3px solid #35db88 !important; 
    }
  }
`;

export const ModalImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 38px 0 0 38px;
  min-width: 300px;
  min-height: 500px;
  object-fit: cover;
`;

export const ModalIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border-radius: 38px 0 0 38px;
`;

export const LeftArea = styled.div`
  min-height: 300px;
`;

export const FavoriteButton = styled.div`
  .star {
    transition: 0.25s;
    background: white;
    border-radius: 50%;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0.8);
  }
  &:hover {
    .star {
      color: white !important;
      cursor: pointer;
      background: black !important;
    }
  }
`;

export const CloseBtn = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
`;

export const RightArea = styled.div`
  display: grid;
  justify-items: end;
  grid-auto-rows: auto;
  padding: 2%;
`;
export const ModalTitle = styled.h1`
  width: 100%;
  text-align: left;
  font: normal normal bold 29px/34px Raleway;
  color: #000000;
  padding: 0 2% 0 2%;
`;
export const ModalDescription = styled.span`
  width: 100%;
  max-width: 370px;
  padding: 0 3% 0 3%;
`;
export const CheckBtn = styled.div`
  border-color: #ccc;
  border-radius: 15px;
  padding: 0 12px 20px 15px;
  height: 25px;
  cursor: pointer;
`;
export const CheckBtnArea = styled.div`
  width: 100%;
  float: left;
`;
