import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .icon-intro {
    width: 400px;
    max-width: 400px;

    @media (max-width: 762px) {
      position: absolute;
      bottom: 0;
      right: 5dvw;
      width: 50dvh;
    }
  }
  .exit-button {
    background: #cccccc;
    color: white;
    border-radius: 12px;
    transition: 0.25s;
    cursor: pointer;
    width: 80px;
    &:hover {
      width: 100px;
      background: black;
    }
  }

  .start-button {
    background: #3eff9f;
    color: white;
    border-radius: 12px;
    transition: 0.25s;
    cursor: pointer;
    width: 120px;
    &:hover {
      width: 130px;
      background: black;
    }
  }
  @media (min-width: 1800px) {
    .icon-intro {
      width: 550px;
      max-width: 550px;
    }
  }
`;

export const ModalContent = styled.div`
  background-color: white;
  min-width: 30vw;
  min-height: 635px;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
  transition: min-width 0.7s ease-in-out;

  .title-question {
    @media (max-width: 762px) {
      font-size: 1.5rem !important;
      position: absolute;
      top: 10px;
      left: 0;
      padding: 20px;
      width: 100%;
      justify-content: center;
      text-align: center;
    }
  }

  .content {
    @media (max-width: 762px) {
      padding: 0 !important;
    }
  }

  .fine-message {
    @media (max-width: 762px) {
      justify-content: start !important;
    }
  }

  &.visible {
    min-width: 80vw !important;
    max-width: 80vw !important;

    @media (max-width: 762px) {
      width: 100dvw !important;
      max-width: 100dvw !important;
      max-height: 100dvh !important;
      height: 100dvh !important;
    }
  }
  .helper {
    font-size: 1.5rem;
    transition: 0.25s;
    @media (max-width: 762px) {
      display: none;
    }
    &:hover {
      color: #b6eada;
    }
  }
`;

export const ModalHeader = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 10px;
`;

export const ModalControlButton = styled.button`
  background: #e9e9e9 0% 0% no-repeat padding-box;
  border: none;
  padding: 10px;
  border-radius: 58px;
  transition: 0.25s;
  &:hover {
    color: white;
    background: black;
    &:disabled {
      opacity: 0.5;
    }
  }
  &.submit-button {
    background: #b6eada !important;
  }
`;

export const Dot = styled.div`
  width: 12px;
  height: 12px;
  border: 1px solid #707070;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.25s;
  &:hover {
    background: #b6eada 0% 0% no-repeat padding-box;
  }
  &.active {
    border: none;
    background: #b6eada 0% 0% no-repeat padding-box !important;
  }
  &.unanswered {
    border: none;
    background: #ff5f5fa3;
  }
`;

export const MultipleAnswersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: auto;
  margin-bottom: 10px;
  @media (max-width: 762px) {
    height: 50%;
    justify-content: center;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .answer {
    background: #b6eada 0% 0% no-repeat padding-box;
    border-radius: 14px;
    color: #196740;
    text-align: center;
    font-size: 15px;
    cursor: pointer;
    transition: 0.25s;
    min-width: 110px;
    &.selected {
      background: black !important;
      color: white !important;
    }
    &:hover {
      background: black;
      color: white;
    }
    @media (max-width: 762px) {
      min-width: 80% !important;
    }
  }
`;

export const SingleAnswerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border: 2px solid #000000;
  border-radius: 14px;
  align-items: center;
  @media (max-width: 762px) {
    flex-direction: column;
    margin-top: 50px;
  }
  .answer {
    cursor: pointer;
    text-align: center;
    width: 100%;
    transition: 0.25s;
    border-right: 1px solid #e6e6e6;

    &:last-child {
      border-top-right-radius: 9px !important;
      border-bottom-right-radius: 9px !important;
    }
    &:first-child {
      border-top-left-radius: 9px !important;
      border-bottom-left-radius: 9px !important;
      @media (max-width: 762px) {
        border-radius: 0 !important;
        border-right: 0 !important;
      }
    }
    &:last-child {
      border-right: none;
      @media (max-width: 762px) {
        border-radius: 0 !important;
        border-right: 0 !important;
      }
    }
    &.selected {
      background: black !important;
      color: white !important;
      &:last-child {
        border-top-right-radius: 9px !important;
        border-bottom-right-radius: 9px !important;
      }
      &:first-child {
        border-top-left-radius: 9px !important;
        border-bottom-left-radius: 9px !important;
      }
    }
    &:hover {
      color: white;
      background: black;
      border-radius: none !important;

      &:last-child {
        border-top-right-radius: 9px !important;
        border-bottom-right-radius: 9px !important;
      }
      &:first-child {
        border-top-left-radius: 9px !important;
        border-bottom-left-radius: 9px !important;
      }
    }
    @media (max-width: 762px) {
      border-radius: 0 !important;
      border-right: 0 !important;
    }
  }
`;
