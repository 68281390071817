/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import { RiEdit2Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { RingLoader } from "react-spinners";
import styled from "styled-components";
import Swal from "sweetalert2";
import { TQuickTask } from "../../../../../shared/models/dashboard/pillars.model";
import { ActivitiesServices } from "../../../../../shared/services/activities/activities.service";
import ModalInsertTask from "../../../components/modal-insert-task";
import TaskModal from "../../../components/modal-task";

export function QuickTaskMobile() {
  const [showInsertTaskModal, setShowInsertTaskModal] = useState(false);
  const [showTaskModal, setShowModal] = useState(false);
  const _activitiesService = new ActivitiesServices();
  const [modalTaskData, setModalTaskData] = useState(null);
  const [tasks, setTasksItems] = useState<TQuickTask[]>([]);
  const [loading, setLoading] = useState(true);
  const [editModeTask, setEditModeTask] = useState(false);
  const [deleteModeTask, setDeleteModeTask] = useState(false);
  const navigate = useNavigate();

  const loadDataQuickTask = async () => {
    setLoading(true);
    const response = await _activitiesService.listQuickTasks(1, 100);
    setTasksItems(response);
    setLoading(false);
  };

  const openEditTask = (task: any) => {
    setModalTaskData(task);
    setShowInsertTaskModal(true);
  };

  const closeTaskModal = () => {
    setShowModal(false);
  };

  const openTaskModal = (task: any) => {
    setModalTaskData(task);
    setShowModal(true);
  };

  const handleDeleteTask = async (task: TQuickTask) => {
    const result = await Swal.fire({
      title: "Tem certeza?",
      text: "Você não poderá reverter isso!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00DF70",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Sim, delete!",
    });

    if (result.isConfirmed) {
      try {
        await _activitiesService.deleteQuickTask({ taskId: task._id || "" });
        Swal.fire("Deletado!", "Sua tarefa foi deletada.", "success");
        await loadDataQuickTask();
      } catch (error) {
        Swal.fire("Erro!", "Houve um erro ao deletar a tarefa.", "error");
      }
    }
  };

  useEffect(() => {
    loadDataQuickTask();
  }, []);

  return (
    <>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 100000000000,
          }}
        >
          <RingLoader color="#00df70" loading={true} />
        </div>
      )}
      <TaskModal
        showModal={showTaskModal}
        closeModal={closeTaskModal}
        modalData={modalTaskData}
      />
      <ModalInsertTask
        refresh={() => {
          loadDataQuickTask();
        }}
        showModal={showInsertTaskModal}
        closeModal={() => {
          setShowInsertTaskModal(false);
          setModalTaskData(null);
        }}
        modalData={modalTaskData}
      />
      <Div>
        <Div2>
          <Img
            onClick={() => {
              navigate("/activities");
            }}
            className="animate__animated animate__fadeInDown"
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2247d2f0bc8599eb4cf0c279f5e3ed68c06ef81b93e8cbc0175a5c7c2dc519ec?apiKey=a7d67dd512364d7190520da778c77636&"
          />
          <Div3
            onClick={() => {
              navigate("/activities");
            }}
            className="animate__animated animate__fadeInDown"
          >
            Tarefas diárias fáceis
          </Div3>
          <>
            <AiFillPlusCircle
              onClick={() => {
                setShowInsertTaskModal(true);
              }}
              className="animate__animated animate__fadeInDown"
              size={25}
              style={{ color: "#35db88", marginRight: 15, cursor: "pointer" }}
            />
            {!editModeTask && !deleteModeTask && (
              <RiEdit2Fill
                onClick={() => {
                  setEditModeTask(true);
                }}
                className="animate__animated animate__fadeInDown"
                size={25}
                style={{ color: "#35db88", marginRight: 15, cursor: "pointer" }}
              />
            )}
            {editModeTask && (
              <IoMdCloseCircle
                onClick={() => {
                  setEditModeTask(false);
                }}
                className="animate__animated animate__fadeInDown"
                size={25}
                style={{ color: "#35db88", marginRight: 15, cursor: "pointer" }}
              />
            )}
            {!deleteModeTask && !editModeTask && (
              <FaTrash
                onClick={() => {
                  setDeleteModeTask(true);
                }}
                className="animate__animated animate__fadeInDown"
                size={18}
                style={{ color: "#35db88", marginRight: 15, cursor: "pointer" }}
              />
            )}
            {deleteModeTask && (
              <IoMdCloseCircle
                onClick={() => {
                  setDeleteModeTask(false);
                }}
                className="animate__animated animate__fadeInDown"
                size={25}
                style={{ color: "#35db88", marginRight: 15, cursor: "pointer" }}
              />
            )}
          </>
        </Div2>
        <Div4>
          {tasks.map((task) => {
            return (
              <Div5 className="my-1 animate__animated animate__fadeInLeft" key={task._id}>
                <Div6>{task.title}</Div6>
                {!deleteModeTask && !editModeTask && (
                  <Img2
                    onClick={() => {
                      openTaskModal(task);
                    }}
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/786dd06afbdf57515c8800162c7f882b5e197fedc19dc8ba1cf03e54b950dfa3?apiKey=a7d67dd512364d7190520da778c77636&"
                  />
                )}
                {deleteModeTask && (
                  <IoMdCloseCircle
                    onClick={() => {
                      handleDeleteTask(task);
                    }}
                    size={22}
                    style={{
                      color: "#7D7D7D",
                      cursor: "pointer",
                    }}
                  />
                )}
                {editModeTask && (
                  <RiEdit2Fill
                    onClick={() => {
                      openEditTask(task);
                    }}
                    size={22}
                    style={{
                      color: "#7D7D7D",
                      cursor: "pointer",
                    }}
                  />
                )}
              </Div5>
            );
          })}
        </Div4>
      </Div>
    </>
  );
}

const Div = styled.div`
  background-color: #fdfdfd;
  display: flex;
  max-width: 480px;
  width: 100%;
  padding-bottom: 80px;
  flex-direction: column;
  color: #000;
  margin: 0 auto;
`;

const Div2 = styled.div`
  backdrop-filter: blur(10px);
  border-color: rgba(246, 246, 246, 1);
  border-style: solid;
  border-width: 1px;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  font-weight: 600;
  line-height: 99%;
  padding: 58px 16px 30px;
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 16px;
`;

const Div3 = styled.div`
  font-family: Poppins, sans-serif;
  flex-grow: 1;
  flex-basis: auto;
`;

const Div4 = styled.div`
  align-self: center;
  display: flex;
  margin-top: 12px;
  width: 100%;
  max-width: 350px;
  flex-direction: column;
  font-size: 15px;
  font-weight: 500;
  line-height: 100.5%;
`;

const Div5 = styled.div`
  border-radius: 8px;
  background-color: #f6f6f6;
  display: flex;
  gap: 20px;
  padding: 16px 17px;
`;

const Div6 = styled.div`
  font-family: Poppins, sans-serif;
  flex-grow: 1;
  flex-basis: auto;
`;

const Img2 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 19px;
  fill: #7d7d7d;
`;
