import { Dropdown } from 'react-bootstrap'
import styled from 'styled-components'

export const Avatar = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Image = styled.img`
  width: 100%;
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`
export const Notification = styled.span`
  text-align: left;
  font: normal normal bold 18px/21px Raleway;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
`
export const MarkAsReaded = styled.span`
  text-align: left;
  font: normal normal medium 14px/16px Raleway;
  letter-spacing: 0px;
  color: #35db88;
  opacity: 1;
`
export const CustomDropdownItem = styled(Dropdown.Item)`
  white-space: normal;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 100px; /* Defina a altura máxima desejada */
  text-align: left;
  font: normal normal 600 16px/20px Raleway;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
`
export const FormatedDate = styled.span`
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.51;
`
