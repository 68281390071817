import "animate.css";
import React, { useState } from "react";
import { FaUser } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { Avatar } from "./style";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const Initials = styled.span`
  color: #fff;
  font-size: 20px;
`;
const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: calc(100% + -15px);
  right: 0;
  z-index: 10000000;
  width: 160px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  animation: ${({ show }: any) => (show ? fadeIn : fadeOut)} 0.3s ease forwards;
  visibility: ${({ show }: any) => (show ? "visible" : "hidden")};
` as any;

const MenuItem = styled.div`
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const LogoutIcon = styled(FiLogOut)`
  margin-right: 8px;
`;

interface UserAvatarProps {
  name: string;
}

const UserAvatar: React.FC<UserAvatarProps> = ({ name }) => {
  const initials = name
    .split(" ")
    .map((word) => (word?.length > 0 ? word[0] : ""))
    .join("")
    .toUpperCase();
  const [showLogoutDropdown, setShowLogoutDropdown] = useState(false);
  const navigate = useNavigate();
  const isMobile: boolean = /Mobi|Android/i.test(navigator.userAgent);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  const handleMyAccount = () => {
    navigate("/account");
  };

  return (
    <DropdownContainer>
      <Avatar onClick={() => setShowLogoutDropdown(!showLogoutDropdown)}>
        <Initials>{initials}</Initials>
      </Avatar>
      {isMobile && (
        <DropdownMenu show={showLogoutDropdown as any}>
          <MenuItem onClick={handleMyAccount}>
            <FaUser className="me-3" />
            Minha conta
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <LogoutIcon />
            Sair
          </MenuItem>
        </DropdownMenu>
      )}
      {!isMobile && (
        <DropdownMenu show={showLogoutDropdown as any}>
          <MenuItem onClick={handleMyAccount}>
            <FaUser className="me-3" />
            Minha conta
          </MenuItem>
        </DropdownMenu>
      )}
    </DropdownContainer>
  );
};

export default UserAvatar;
