import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { format } from 'date-fns'

const formatDate = (date: string): string => {
  const parsedDate = new Date(date)
  return format(parsedDate, 'dd/MM/yy - hh:mm')
}

const VerticalLinearStepper = (props: any) => {
  const steps: any[] = props.prop
  return (
    <Box sx={{ maxWidth: 400, margin: 0 }}>
      <Stepper activeStep={5} orientation="vertical">
        {steps.map(item => (
          <Step
            key={item.label}
            className="animate__animated animate__fadeInDown animate__delay-0.5s"
          >
            <StepLabel>
              <strong>{item.label}</strong> <br />
              <small>{formatDate(item.time)}</small>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}
export default VerticalLinearStepper
