export enum Mood {
  Happy = 1,
  Sad = 2,
  Neutral = 3,
  Smile = 4,
  Bad = 5,
  Empty = 6,
}

export enum TypeDailyMessage {
  Generic = 1,
  Biblical = 2,
}
