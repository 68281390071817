import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-top: 30px;
  height: 80vh;
  @media (max-width: 762px) {
    display: flex;
    justify-content: center;

    .card-activity {
      position: relative;
      margin-top: 1rem !important;
      h5 {
        position: absolute;
        top: 50%;
        left: 20%;
        z-index: 100;
      }
    }
  }
`;

export const Card = styled.div`
  border-radius: 14px;
  overflow: hidden !important;
  height: 22rem;
  box-shadow: 10px 15px 40px #3b4b761f;
  @media (max-width: 762px) {
    box-shadow: none;
  }
  background-color: #fff;
`;
export const CardHead = styled.div`
  border-radius: 14px 14px 0 0;
  height: 12%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const CardTitle = styled.span`
  color: white;
  margin-left: 25px;
  text-align: left;
  font: normal normal bold 18px/21px Raleway;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;

  @media (max-width: 762px) {
    font-size: 0.9rem;
  }
`;
export const CardTaskBody = styled.div`
  padding: 10px 10px 10px 20px;
  height: 85%;
  width: 102%;
`;
export const CardTaskBodyTop = styled.div`
  height: 70%;
`;
export const CardTask = styled.div`
  border-radius: 15px;
  padding: 13px;
  word-break: break-all;
  height: 90px;
  cursor: pointer;
`;

export const ThoughtCardBody = styled.div`
  padding: 10px 10px 10px 20px;
  height: 85%;
  width: 102%;
`;

export const ThoughtCard = styled.div`
  border-radius: 15px;
  padding: 13px;
  word-break: break-all;
  cursor: pointer;
  border: #ccc solid 1px;
  height: 100%;
`;

export const MobileContainer = styled.div`
  border-radius: 20px;
  background-color: rgba(91, 143, 185, 1);
  display: flex;
  max-width: 350px;
  flex-direction: column;
  align-items: start;
  color: #fff;
  padding: 55px 72px 13px 14px;
`;

export const MobileContainer2 = styled.div`
  font: 500 20px/99% Poppins, sans-serif;
`;

export const MobileContainer3 = styled.div`
  margin-top: 9px;
  font: 300 12px/12px Poppins, sans-serif;
`;

export const ThoughtCardTop = styled.div``;
export const CardTaskTitle = styled.span`
  width: 100%;
  letter-spacing: 0px;
  white-space: nowrap;
  /* overflow: hidden; */
  display: block;
  text-overflow: ellipsis;
  opacity: 1;
  font: normal normal 600 15px/27px Raleway;
`;
export const CardTaskDate = styled.div`
  width: 100%;
  letter-spacing: 0px;
  opacity: 1;
  font: normal normal 600 13px/27px Raleway;
  color: #989494;
  margin-top: -10px;
`;

export const CardActions = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #e2e2e2;
  padding: 5px;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  margin-right: 5px;
`;
export const ThoughtText = styled.div`
  padding-top: 10px;
  font: normal normal 600 13px/27px Raleway;
  color: #989494;
`;
//exercicies

export const ExercisiesCardBody = styled.div`
  padding: 10px 10px 10px 20px;
  width: 102%;
`;

export const ExercisiesCard = styled.div`
  border-radius: 15px;
  padding: 13px;
  word-break: break-all;
  cursor: pointer;
  border: #ccc solid 1px;
`;
export const ExercisiesActions = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #e2e2e2;
  padding: 5px;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`;
//exercicies

export const AutoCareCardBody = styled.div`
  padding: 10px 10px 10px 20px;
  width: 102%;
`;

export const AutoCareCard = styled.div`
  border-radius: 15px;
  padding: 13px;
  word-break: break-all;
  cursor: pointer;
  border: #ccc solid 1px;
`;
export const AutoCareAddCard = styled.div`
  border-radius: 15px;
  padding: 8px 13px;
  width: 100%;
  cursor: pointer;
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AutoCareCardTitle = styled.span`
  width: 100%;
  letter-spacing: 0px;
  opacity: 1;
  font: normal normal 600 15px/27px Raleway;
`;
export const AutoCareCardAction = styled.div`
  border-radius: 50%;
  background-color: #5b8fb9;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`;

export const AutoCareItems = styled.div`
  margin-top: 10px;
  border-radius: 10px;
  width: 100%;
  cursor: pointer;
`;
