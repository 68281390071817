import Chart from 'react-apexcharts'

const TaskGauge = (props: any) => {
  const data = props.prop

  const setColor = (type: number): string => {
    switch (type) {
      case 1:
        return '#00df70'
      case 2:
        return '#01c2b5'
      case 3:
        return '#5b8fb9'
      case 4:
        return '#482aa7'
      case 5:
        return '#888888'
      default:
        return ''
    }
  }
  const options = {
    chart: {
      type: 'radialBar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '65%'
        },
        track: {
          background: '#ccc'
        },
        dataLabels: {
          showOn: 'always',
          name: {
            show: false
          },
          value: {
            show: true,
            fontSize: '30px',
            color: 'black',
            offsetY: 10,
            offsetX: 0,
            fontWeight: "bold",
          },
          total: {
            show: false
          }
        }
      }
    },
    series: [data.value],
    labels: ['Progresso'],
    fill: {
      colors: [setColor(data.type)]
    }
  }

  return (
    <Chart
      className="chart"
      options={options as ApexCharts.ApexOptions}
      series={options.series}
      type="radialBar"
      height={250}
    />
  )
}

export default TaskGauge
