import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

interface ImageProps {
  src: string;
  alt: string;
  aspectRatio?: string;
  width?: string;
  alignSelf?: string;
  margin?: string;
  fill?: string;
  backdropFilter?: string;
  name?: string;
}

const Image = styled.img<ImageProps>`
  aspect-ratio: ${(props) => props.aspectRatio};
  object-fit: auto;
  object-position: center;
  width: ${(props) => props.width};
  align-self: ${(props) => props.alignSelf};
  margin: ${(props) => props.margin};
  fill: ${(props) => props.fill};
  backdrop-filter: ${(props) => props.backdropFilter};
`;

const Card = styled.div`
  border-radius: 34px;
  box-shadow: 10px 15px 40px 0 rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(246, 246, 246, 1);
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  max-width: 225px;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  bottom: 5dvh;
  z-index: 1000;
  left: 20dvw;
`;

const ImageGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  padding: 5px 33px;
  .container {
    width: 100px;
    height: 40px;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    align-items: center;
    padding: 5px;
    transition: 0.25s;
    &.active {
      background: #26c274;
      img {
        filter: brightness(0) invert(1) !important;
      }
    }
  }
`;

const images = [
  {
    src: "https://cdn.builder.io/api/v1/image/assets/TEMP/6a6198b0ceca0ad67ca9c91f78cf4ee1f7dc192e08ae3cf42d445b5a48f78877?apiKey=a7d67dd512364d7190520da778c77636&",
    alt: "Image 1",
    aspectRatio: "1",
    width: "13px",
    alignSelf: "stretch",
    margin: "auto 0",
    name: "dashboard",
    route: "/dashboard",
  },

  {
    src: "https://cdn.builder.io/api/v1/image/assets/TEMP/6a8ad2e6cc6fcea7fd8ef17c05d4aa29e720d5889b21a342b414201c12a1b046?apiKey=a7d67dd512364d7190520da778c77636&",
    alt: "Image 3",
    aspectRatio: "1.3",
    width: "18px",
    alignSelf: "stretch",
    margin: "auto 0",
    name: "activities",
    route: "/activities",
  },

  {
    src: "https://cdn.builder.io/api/v1/image/assets/TEMP/83ad70a4462dba25434a42f29ba64352ffce1c21bc4ac7224ed5f5c0b93ab901?apiKey=a7d67dd512364d7190520da778c77636&",
    alt: "Image 5",
    aspectRatio: "1.09",
    width: "22px",
    margin: "auto 0",
    name: "monitoring",
    route: "/monitoring",
  },
];

function MobileMenu() {
  const [activeImage, setActiveImage] = useState("");
  const history = useNavigate();
  const location = useLocation();

  const handleClick = (route: string) => {
    setActiveImage(route);
    history(route);
  };

  return (
    <>
      <Card>
        <ImageGroup>
          {images.map((image) => (
            <div
              className={`container ${
                location.pathname === image.route ? "active" : ""
              }`}
              key={image.src}
              onClick={() => handleClick(image.route)}
            >
              <Image
                src={activeImage === image.src ? activeImage : image.src}
                alt={image.alt}
                aspectRatio={image.aspectRatio}
                width={image.width}
                alignSelf={image.alignSelf}
                margin={image.margin}
                style={{
                  filter: activeImage === image.src ? "blur(2px)" : "none",
                }}
              />
            </div>
          ))}
        </ImageGroup>
      </Card>
    </>
  );
}

export default MobileMenu;
