import "animate.css";
import "bootstrap/dist/css/bootstrap.css";
import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { BsBell } from "react-icons/bs";
import {
  Avatar,
  CustomDropdownItem,
  FormatedDate,
  Header,
  MarkAsReaded,
  Notification,
} from "./style";
import "./style.scss";

const UserMessages = () => {
  const dropdownRef: any = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleImageClick = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(/Mobi|Android/i.test(navigator.userAgent));
    };
    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  const notifications: any[] = [];

  const formatDate = (date: Date): string => {
    return format(date, "dd 'de' MMMM, 'às' HH:mm");
  };

  return (
    <Avatar
      className={
        showDropdown
          ? "message-visible notification-container"
          : "message-hidden notification-container"
      }
      onClick={handleImageClick}
    >
      <BsBell size={25} />
      {notifications.length > 0 && (
        <span className="notification-count">{notifications.length}</span>
      )}
      <DropdownButton
        as={ButtonGroup}
        align={{ lg: isMobile ? "start" : "end" }}
        title=""
        id="dropdown-menu-align-responsive-1"
        className="btn shadow-none"
        show={showDropdown}
        ref={dropdownRef}
      >
        <Dropdown.Header>
          <Header>
            <Notification>Notificações</Notification>
            <MarkAsReaded>Marcar como lida</MarkAsReaded>
          </Header>
        </Dropdown.Header>
        {notifications.map((notification) => (
          <CustomDropdownItem key={notification.id} eventKey={notification.id}>
            {notification.title} <br />
            <FormatedDate>{formatDate(notification.date)}</FormatedDate>
          </CustomDropdownItem>
        ))}
        {notifications.length === 0 && (
          <CustomDropdownItem>
            {"Sem notificações no momento..."} <br />
          </CustomDropdownItem>
        )}
        <Dropdown.Header>
          <MarkAsReaded>Ver todas as notificações</MarkAsReaded>
        </Dropdown.Header>
      </DropdownButton>
    </Avatar>
  );
};

export default UserMessages;
