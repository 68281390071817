import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background-color: white;
  min-width: 672px;
  min-height: 635px;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  @media (max-width: 762px) {
    min-height: 100dvh;
    min-width: 100dvw;
    padding: 20px !important;
  }
`;

export const ModalHeader = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 10px;
`;

export const ModalButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const ModalButton = styled.button`
  margin: 0 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

export const SelectedMood = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
`;

export const CalendarMoodImage = styled.img`
  transition: 0.25s;
  border-radius: 50%;
  &:hover {
    transform: scale(1.2);
    outline: 2px solid #35db88;
  }
`;
