import FullCalendar from "@fullcalendar/react";
import styled from "styled-components";

export const StyledFullCalendar = styled(FullCalendar)`
  max-height: 400px;
`;
export const CalendarArea = styled.div`
  width: 100%;
  height: 100%;
  border: none !important;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 !important;
    @media only screen and (max-height: 700px) {

    flex-direction: column;
  }
  .fc-daygrid-day {
    border: none;
    border-style: none !important;
  }

  .fc-toolbar-chunk:first-child {
    display: none !important;
  }

  .fc-header-toolbar {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .fc-daygrid-day-top {
    padding: 10px;
    justify-content: center !important;
  }

  .fc-col-header-cell {
    border: none !important;
  }

  .fc-col-header-cell-cushion {
    text-transform: uppercase !important;
    font-weight: 600;
  }

  .fc-daygrid-day-number {
    color: black !important;
  }

  .fc-daygrid-day-events {
    display: none !important;
  }

  .fc .fc-daygrid-day.fc-day-today {
    background: none !important;
    a {
      color: white !important;
      border-radius: 50%;
      padding: 10px 15px;
      background: #35db88 !important;
    }
  }

  .fc-scrollgrid {
    border: none !important;
  }

  .fc-direction-ltr {
    border: none;
    width: 100%;
    direction: ltr;
    text-align: left;
    height: 90%;
      @media only screen and (max-height: 700px) {

      width: 100% !important;
    }
  }
  .fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
    border-radius: 50% !important;
    background-color: transparent !important;
    margin-right: 10px;
    width: 43px !important;
    border: gray 1px solid;
  }
  .fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
    border-radius: 50% !important;
    background-color: transparent !important;
    margin-right: 10px;
    width: 43px !important;
    border: gray 1px solid;
  }
  .fc .fc-button .fc-icon {
    color: black !important;
    margin-left: -2px !important;
  }
  a {
    color: #35db88;
    text-decoration: none;
    font-size: 0.75rem;
  }
  .fc-toolbar-title {
    font-size: 20px;
    font-weight: bold;
    text-transform: capitalize;
  }

  ::-webkit-scrollbar {
    width: 8px !important;
    height: 8px !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ccc !important;
    border-radius: 4px !important;
  }

  ::-webkit-scrollbar-track {
    background-color: #f5f5f5 !important;
    border-radius: 4px 4px 0px 0px !important;
  }
`;
export const EventList = styled.div`
  display: flex;
  width: 30%;
  margin-left: 1rem;
  flex-direction: column;

    @media only screen and (max-height: 700px) {

    width: 100% !important;
    align-items: start;
    margin-top: 1.5rem !important;
    margin-left: 0 !important;
  }

  .event-content {
      @media only screen and (max-height: 700px) {

      justify-content: space-between;
      width: 100%;
      padding: 0 2rem;
    }
    .icon {
      border-radius: 10px;
      background: #ebf9f5;
      padding: 4px 10px;
      font-size: 2rem;
    }

    svg {
      transform: scaleX(-1);
    }

    h5 {
      font-size: 1.2rem;
      font-weight: bold;
    }
    span {
      font-weight: 600;
      opacity: 0.75;
    }
  }
`;
