import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import LogoutIcon from "@mui/icons-material/Logout";
import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { RingLoader } from "react-spinners";
import penguin from "../../../assets/img/pinguim.png";
import { ModalBackdrop } from "../../../pages/dashboard/style";
import {
  QuestionScoreEnum,
  QuestionTypeEnum,
} from "../../enum/questionnaire.enum";
import {
  TAnswerInput,
  TQuestion,
  TQuestionResponse,
} from "../../models/questionnaire/questionnaire.modal";
import { MessageServices } from "../../services/message/message.service";
import QuestionnaireService from "../../services/questionnaire/questionnaire.service";
import {
  Dot,
  ModalContent,
  ModalControlButton,
  ModalOverlay,
  MultipleAnswersContainer,
  SingleAnswerContainer,
} from "./style";
type QuestionnaireModalProps = {
  onClose: () => void;
  onShowQuestionnaire: (show: boolean) => void;
};

const QuestionnaireModal: React.FC<QuestionnaireModalProps> = ({
  onClose,
  onShowQuestionnaire,
}) => {
  const messageService = new MessageServices();
  const questionnaireService = new QuestionnaireService();
  const [isVisible, setIsVisible] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [activeDot, setActiveDot] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showIntro, setShowIntro] = useState(false);
  const [questions, setQuestions] = useState<TQuestion[]>([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const navigate = useNavigate();
  const [questionAnswered, setQuestionAnswered] = useState<boolean[]>([]);
  const location = useLocation();

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };
  const fetchQuestions = async () => {
    try {
      const questionsResponse = await questionnaireService.listQuestions(
        1,
        10,
        "",
        "",
        ""
      );
      if (questionsResponse?.length === 0) {
        sessionStorage.setItem("hasQuestions", "false");
        onShowQuestionnaire(false);
        onClose();
      } else {
        sessionStorage.setItem("hasQuestions", "true");
        onShowQuestionnaire(true);
      }
      const formatedQuestions: TQuestion[] = questionsResponse.map(
        (question: TQuestionResponse) => {
          return {
            id: question?._id,
            type: question?.type,
            title: question?.title,
            maxChoices: question?.maxChoices,
            description: question?.description,
            score: question?.score,
            answersOptions: question?.answerOptions,
            selectedScoreAnswer: null,
            selectedAnswers: [],
          };
        }
      );
      setQuestions(formatedQuestions);
      setQuestionAnswered(
        Array.from({ length: formatedQuestions.length }, () => false)
      );
      setLoading(false);
      setShowIntro(true);
      setIsVisible(true);
    } catch (error: any) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    if (location.pathname !== "/login") {
      fetchQuestions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const submitForm = async () => {
    setFormSubmitted(true);
    const allQuestionsAnswered = questionAnswered.every((answered) => answered);
    if (allQuestionsAnswered) {
      setLoading(true);
      const formatedData: TAnswerInput[] = questions.map((question, _index) => {
        return {
          questionId: question.id,
          questionNumber: _index + 1,
          scoreQuestion: question.score,
          type: question.type,
          selectedAnswers: question.selectedAnswers,
          selectedScoreAnswer: question.selectedScoreAnswer,
        };
      });
      await questionnaireService.answerQuestionnaire(formatedData);
      messageService.success("Sucesso", "Questionário enviado com sucesso.");
      setLoading(false);
      sessionStorage.setItem("hasQuestions", "false");
      setQuestions([]);
      onShowQuestionnaire(false);
      onClose();
    } else {
      messageService.error("Erro", "Algumas perguntas não foram respondidas.");
    }
  };

  const handleNext = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setActiveDot(currentQuestion + 1);
    }
  };

  const handlePrevious = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
      setActiveDot(currentQuestion - 1);
    }
  };

  const handleDotClick = (index: number) => {
    setCurrentQuestion(index);
    setActiveDot(index);
  };

  const handleMouseHelperEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseHelperLeave = () => {
    setShowTooltip(false);
  };

  const handleAnswerClick = (answerId: string, type: QuestionTypeEnum) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = prevQuestions.map((question, index) => {
        if (index === currentQuestion) {
          const { selectedAnswers, maxChoices } = question;
          const isSelected = selectedAnswers.includes(answerId);

          if (isSelected) {
            const updatedSelectedAnswers = selectedAnswers.filter(
              (id) => id !== answerId
            );

            return {
              ...question,
              selectedAnswers: updatedSelectedAnswers,
            };
          } else {
            if (type === QuestionTypeEnum.MultipleChoice) {
              if (selectedAnswers.length < maxChoices) {
                return {
                  ...question,
                  selectedAnswers: [...selectedAnswers, answerId],
                };
              } else {
                const updatedSelectedAnswers = selectedAnswers.slice(0, -1);
                return {
                  ...question,
                  selectedAnswers: [...updatedSelectedAnswers, answerId],
                };
              }
            }
          }
        }
        return question;
      });
      const isAnswered =
        type === QuestionTypeEnum.SingleChoice
          ? updatedQuestions[currentQuestion]?.selectedScoreAnswer !== null
          : updatedQuestions[currentQuestion]?.selectedAnswers.length > 0;
      setQuestionAnswered((prevAnswered) =>
        prevAnswered.map((val, index) =>
          index === currentQuestion ? isAnswered : val
        )
      );
      return updatedQuestions;
    });
  };

  const calculateBackgroundColor = (index: number, score: number) => {
    const totalItems = 10;
    const ratio = index / (totalItems - 1);
    const alpha = 0.3;
    let startColor = [255, 0, 0];
    let endColor = [0, 255, 0];
    let r = Math.round(startColor[0] + (endColor[0] - startColor[0]) * ratio);
    let g = Math.round(startColor[1] + (endColor[1] - startColor[1]) * ratio);
    let b = Math.round(startColor[2] + (endColor[2] - startColor[2]) * ratio);
    if (score === QuestionScoreEnum.Negative) {
      [startColor, endColor] = [endColor, startColor];
      r = Math.round(startColor[0] + (endColor[0] - startColor[0]) * ratio);
      g = Math.round(startColor[1] + (endColor[1] - startColor[1]) * ratio);
      b = Math.round(startColor[2] + (endColor[2] - startColor[2]) * ratio);
    }
    return `rgba(${r},${g},${b},${alpha})`;
  };

  return (
    <div>
      {!loading && questions?.length > 0 && (
        <>
          <ModalBackdrop />
          <ModalOverlay
            className={`modal-overlay ${isVisible ? "visible" : ""}`}
          >
            <ModalContent
              className={`p-5 h-50 modalContent animate__animated animate__fadeInDown animate__faster ${
                isVisible || showIntro ? "visible" : ""
              } position-relative `}
            >
              {showIntro && (
                <div className="d-flex align-items-center w-100 h-100 p-5 content">
                  <div className="d-flex fine-message flex-column align-items-center justify-content-center w-100 h-100 animate__fadeInUp animate__faster animate__animated animate__delay-1s">
                    <h1>Seja bem vindo ao FineAndYou!</h1>
                    <span>
                      A seguir você irá responder um questionário sobre seu
                      Bem-Estar pessoal, vamos começar?
                    </span>
                    <div className="d-flex align-items-center mt-5">
                      <span
                        onClick={logout}
                        className="exit-button d-flex justify-content-between p-2"
                      >
                        Sair <LogoutIcon />
                      </span>
                      <span
                        onClick={() => {
                          setShowIntro(false);
                        }}
                        className="start-button d-flex justify-content-between ms-5 p-2"
                      >
                        Começar <ArrowForwardIosIcon />
                      </span>
                    </div>
                  </div>
                  <img
                    src={penguin}
                    className="icon-intro animate__fadeInUp animate__animated animate__faster animate__delay-1s"
                    alt="penguin"
                  />
                </div>
              )}
              {!showIntro && isVisible && (
                <>
                  <div className="d-flex h-100 w-100 align-items-center justify-content-between">
                    <ModalControlButton
                      id="previous-button"
                      onClick={handlePrevious}
                      disabled={currentQuestion === 0}
                    >
                      <ArrowBackIcon />
                    </ModalControlButton>
                    <div className="w-65 d-flex justify-content-center flex-column h-100">
                      <div className="d-flex flex-column">
                        <h2
                          className={
                            isVisible
                              ? "animate__fadeInUp animate__animated animate__faster animate__delay-0.5s title-question d-flex align-items-center"
                              : "opacity-0 title-question d-flex align-items-center"
                          }
                        >
                          {questions[currentQuestion]?.title}{" "}
                          <div
                            className={
                              (isVisible
                                ? "animate__fadeInUp animate__animated animate__faster animate__delay-0.5s"
                                : "opacity-0") + " helper ms-2"
                            }
                          >
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  {questions[currentQuestion]?.description}
                                </Tooltip>
                              }
                              show={showTooltip}
                            >
                              <span
                                onMouseEnter={handleMouseHelperEnter}
                                onMouseLeave={handleMouseHelperLeave}
                              >
                                <LiveHelpIcon />
                              </span>
                            </OverlayTrigger>
                          </div>
                        </h2>
                      </div>
                      {questions[currentQuestion]?.type ===
                      QuestionTypeEnum.MultipleChoice ? (
                        <MultipleAnswersContainer>
                          {questions[currentQuestion]?.answersOptions?.map(
                            (answer) => {
                              const isSelected = questions[
                                currentQuestion
                              ].selectedAnswers.includes(answer._id);
                              return (
                                <div
                                  className={`answer my-2 animate__fadeInUp animate__animated animate__faster animate__delay-0.5s me-3 py-2 px-4 ${
                                    isSelected ? "selected" : ""
                                  }`}
                                  key={answer._id}
                                  onClick={() =>
                                    handleAnswerClick(
                                      answer._id,
                                      questions[currentQuestion].type
                                    )
                                  }
                                >
                                  {answer.name}
                                </div>
                              );
                            }
                          )}
                        </MultipleAnswersContainer>
                      ) : (
                        <SingleAnswerContainer>
                          {Array.from({ length: 10 }, (_, index) => {
                            const score = questions[currentQuestion]?.score;
                            const backgroundColor = calculateBackgroundColor(
                              index,
                              score
                            );
                            return (
                              <div
                                className={`answer animate__fadeInUp animate__animated animate__faster animate__delay-0.5s py-2 px-4 ${
                                  questions &&
                                  questions[currentQuestion]
                                    ?.selectedScoreAnswer ===
                                    index + 1
                                    ? "selected"
                                    : ""
                                }`}
                                key={index + 1}
                                onClick={() => {
                                  const updatedQuestions = [...questions];
                                  updatedQuestions[
                                    currentQuestion
                                  ].selectedScoreAnswer = index + 1;
                                  const isAnswered =
                                    updatedQuestions[currentQuestion].type ===
                                    QuestionTypeEnum.SingleChoice
                                      ? updatedQuestions[currentQuestion]
                                          ?.selectedScoreAnswer !== null
                                      : updatedQuestions[currentQuestion]
                                          ?.selectedAnswers.length > 0;
                                  setQuestionAnswered((prevAnswered) =>
                                    prevAnswered.map((val, index) =>
                                      index === currentQuestion
                                        ? isAnswered
                                        : val
                                    )
                                  );
                                  setQuestions(updatedQuestions);
                                }}
                                style={{ backgroundColor }}
                              >
                                {index + 1}
                              </div>
                            );
                          })}
                        </SingleAnswerContainer>
                      )}
                    </div>
                    <ModalControlButton
                      id="next-button"
                      onClick={
                        currentQuestion === questions.length - 1
                          ? submitForm
                          : handleNext
                      }
                      className={
                        currentQuestion === questions.length - 1
                          ? "submit-button"
                          : ""
                      }
                    >
                      <ArrowForwardIcon />
                    </ModalControlButton>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    {questions.map((_, index) => (
                      <Dot
                        key={index}
                        className={`${
                          index === activeDot ? "active mx-1" : "mx-1"
                        } ${
                          !questionAnswered[index] && formSubmitted
                            ? "unanswered"
                            : ""
                        }`}
                        onClick={() => handleDotClick(index)}
                      />
                    ))}
                  </div>
                </>
              )}
            </ModalContent>
          </ModalOverlay>
        </>
      )}
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 10000,
          }}
        >
          <RingLoader color="#00df70" loading={true} />
        </div>
      )}
    </div>
  );
};

export default QuestionnaireModal;
