import { requestService } from "../../../core/services/request.service";
import DELETE_ARTICLE from "./mutations/deleteArticle.mutation";
import EDIT_ARTICLE from "./mutations/editArticle.mutation";
import INSERT_ARTICLE from "./mutations/insertArticle.mutation";
import GET_ARTICLE from "./queries/getArticle.query";
import LIST_ARTICLES from "./queries/listArticle.query";

export class ArticleService {
  async listArticles(
    page: number,
    perPage: number,
    sort?: string,
    sortDir?: string,
    filter?: string
  ): Promise<{ items: ArticleItem[]; total: number }> {
    try {
      const result = await requestService.get(LIST_ARTICLES, {
        page,
        perPage,
        sort,
        sortDir,
        filter,
      });

      const listArticles = result?.data?.listArticles;
      return {
        items: listArticles?.items || [],
        total: listArticles?.total || 0,
      };
    } catch (error: any) {
      throw error;
    }
  }

  async deleteArticle(data: { id: string }) {
    try {
      const result = await requestService.mutate(DELETE_ARTICLE, {
        input: { articleId: data.id },
      });
      return result;
    } catch (error: any) {
      throw error;
    }
  }

  async getArticle(articleId: string): Promise<ArticleItem | null> {
    try {
      const result = await requestService.get(GET_ARTICLE, {
        articleId,
      });
      return result?.data?.getArticle || null;
    } catch (error: any) {
      throw error;
    }
  }

  async editArticle(data: {
    articleId: string;
    title: string;
    description: string;
    image: string;
  }) {
    try {
      const result = await requestService.mutate(EDIT_ARTICLE, {
        input: {
          articleId: data.articleId,
          title: data.title,
          description: data.description,
          image: data.image,
        },
      });
      return result;
    } catch (error: any) {
      throw error;
    }
  }

  async insertArticle(data: {
    title: string;
    description: string;
    image: string;
  }) {
    try {
      const result = await requestService.mutate(INSERT_ARTICLE, {
        input: {
          title: data.title,
          description: data.description,
          image: data.image,
        },
      });
      return result;
    } catch (error: any) {
      throw error;
    }
  }
}

export interface ArticleItem {
  _id: string;
  title: string;
  description: string;
  image?: string;
}
