/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import PageTitle from "../../shared/components/page-title";
import TaskComponent from "../../shared/components/task";
import {
  MainContainer,
  TopBall,
} from "../../shared/components/templates/main-container/main-container";
import { IDashboard } from "../../shared/models/dashboard/dashboard.model";
import {
  IPillarsDashboard,
  TDashboardFavorites,
  TDashboardMood,
  TQuickTask,
} from "../../shared/models/dashboard/pillars.model";
import DashboardService from "../../shared/services/dashboard/dashboard.service";
import TaskModal from "../activities/components/modal-task";
import DashBoardCalendar from "./components/calendar";
import Favorites from "./components/favorites";
import Humor from "./components/humor";
import Pillar from "./components/pillar";
import {
  CalendarCard,
  CardPillar,
  DashboardContainer,
  HumorCard,
  MainContent,
  Session,
  SessionArea,
  Title,
} from "./style";

const DashBoard = () => {
  const _dashboardService = new DashboardService();
  const setDefault = () => {
    return {
      throw: [],
      tasks: [],
      exercises: [],
      habits: { toAbandon: [], toAdopt: [] },
    };
  };
  const [dataTasks] = useState(setDefault());
  const isMobile: boolean = /Mobi|Android/i.test(navigator.userAgent);
  const [tasks, setTasksItems] = useState<TQuickTask[]>(dataTasks.tasks);
  const [modalTaskData, setModalTaskData] = useState<TQuickTask | null>(null);
  const [pillarsData, setPillarsData] = useState<IPillarsDashboard | null>(
    null
  );
  const [showTaskModal, setShowModal] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const data = {
    title: "Dashboard",
    subtitle:
      "Esse quadro apresenta informações relacionadas ao seu momento atual de bem-estar e saúde mental.",
  };
  const [filterCompletedTasks, setFilterCompletedTasks] = useState(false);
  const [filterFavoritedTasks, setFilterFavoritedTasks] = useState(false);
  const [filterCycleTasks, setFilterCycleTasks] = useState(false);
  const [humorData, setHumorData] = useState<TDashboardMood | null>(null);
  const [favoriteData, setFavoriteData] = useState<TDashboardFavorites[] | []>(
    []
  );
  const [loadingPillars, setLoadingPillars] = useState(true);
  const [loadingTasks, setLoadingTasks] = useState(true);
  const [loadingMood, setLoadingMood] = useState(true);
  const [loadingFavorites, setLoadingFavorites] = useState(true);

  const loadData = async (
    completed: boolean,
    favorited: boolean,
    cycle: boolean
  ) => {
    const responseDashboard: IDashboard | null =
      await _dashboardService.getDashboard(completed, favorited, cycle);

    const updateData = (
      dataToUpdate: any,
      responseData: any,
      setLoading: React.Dispatch<React.SetStateAction<boolean>>,
      setData: React.Dispatch<React.SetStateAction<any>>,
      lengthCheck?: boolean
    ) => {
      if (!isDataEqual(dataToUpdate, responseData)) {
        setLoading(true);
        setData(responseData || (lengthCheck ? [] : null));
        setLoading(false);
      } else if (lengthCheck && responseData?.length === 0) {
        setLoading(false);
      }
    };

    updateData(
      pillarsData,
      responseDashboard?.pillarsData,
      setLoadingPillars,
      setPillarsData
    );
    updateData(
      tasks,
      responseDashboard?.tasksData,
      setLoadingTasks,
      setTasksItems,
      true
    );
    updateData(
      humorData,
      responseDashboard?.moodData,
      setLoadingMood,
      setHumorData
    );
    updateData(
      favoriteData,
      responseDashboard?.favoritesData,
      setLoadingFavorites,
      setFavoriteData,
      true
    );
  };

  const handleFilterChange = async (
    completed: boolean,
    favorited: boolean,
    cycle: boolean
  ) => {
    setFilterCompletedTasks(completed);
    setFilterFavoritedTasks(favorited);
    setFilterCycleTasks(cycle);
    await loadData(completed, favorited, cycle);
  };

  useEffect(() => {
    if (isMounted) {
      loadData(filterCompletedTasks, filterFavoritedTasks, filterCycleTasks);
    }
    return () => {
      setIsMounted(false);
    };
  }, [isMounted]);

  const closeTaskModal = () => {
    setShowModal(false);
    loadData(filterCompletedTasks, filterFavoritedTasks, filterCycleTasks);
  };

  const isDataEqual = (prevData: any, newData: any) => {
    return JSON.stringify(prevData) === JSON.stringify(newData);
  };

  const openTaskModal = (task: any) => {
    setModalTaskData(task);
    setShowModal(true);
  };

  return (
    <MainContainer>
      <>
        <TaskModal
          showModal={showTaskModal}
          closeModal={closeTaskModal}
          modalData={modalTaskData}
        />
        <TopBall style={{ display: isMobile ? "none" : "" }} />
        <DashboardContainer>
          <PageTitle prop={data} />
          {!isMobile && (
            <MainContent>
              <Session className="row">
                <SessionArea className="w-50 me-3">
                  <CardPillar>
                    <Pillar
                      pillars={pillarsData?.pillars || []}
                      monthPerformanceValue={
                        pillarsData?.monthPerformanceValue || 0
                      }
                      overallMonthlyAssessment={
                        pillarsData?.overallMonthlyAssessment || 0
                      }
                      monthPerformance={pillarsData?.monthPerformance || 0}
                      loading={loadingPillars}
                    />
                  </CardPillar>
                </SessionArea>
                <SessionArea className="w-45">
                  <TaskComponent
                    tasks={tasks}
                    isToView={true}
                    isMobile={isMobile}
                    openTaskModal={openTaskModal}
                    loading={loadingTasks}
                    filterCompletedTasks={filterCompletedTasks}
                    filterFavoritedTasks={filterFavoritedTasks}
                    filterCycleTasks={filterCycleTasks}
                    onFilterChange={handleFilterChange}
                  ></TaskComponent>
                </SessionArea>
              </Session>
              <Session className="row">
                <div className="d-flex short-cards flex-column me-3 w-50 p-0">
                  <SessionArea>
                    <HumorCard>
                      <Humor
                        prop={humorData || null}
                        loading={loadingMood}
                        onCloseMoodSelection={handleFilterChange}
                      ></Humor>
                    </HumorCard>
                  </SessionArea>
                  <SessionArea className="mt-3">
                    <Favorites
                      closeFavoriteModal={async () => {
                        setLoadingFavorites(true);
                        await loadData(
                          filterCompletedTasks,
                          filterFavoritedTasks,
                          filterCycleTasks
                        );
                        setLoadingFavorites(false);
                      }}
                      loading={loadingFavorites}
                      openTaskModal={openTaskModal}
                      favoriteItems={favoriteData}
                    />
                  </SessionArea>
                </div>
                <SessionArea className="w-45">
                  <div className="d-flex justify-content-between w-100 align-items-center">
                    <Title>Próximas Atividades</Title>
                    {/* <TitleCalendarButton>
                    <Link to="/calendar">
                      <BsCalendar3Event size={15} />
                    </Link>
                  </TitleCalendarButton> */}
                  </div>
                  <CalendarCard>
                    <DashBoardCalendar></DashBoardCalendar>
                  </CalendarCard>
                </SessionArea>
              </Session>
            </MainContent>
          )}
          {isMobile && (
            <MainContent className="px-4">
              <Session className="row">
                <SessionArea>
                  <HumorCard>
                    <Humor
                      prop={humorData || null}
                      loading={loadingMood}
                      onCloseMoodSelection={handleFilterChange}
                    ></Humor>
                  </HumorCard>
                </SessionArea>
                <SessionArea className="w-50 me-3">
                  <CardPillar>
                    <Pillar
                      pillars={pillarsData?.pillars || []}
                      monthPerformanceValue={
                        pillarsData?.monthPerformanceValue || 0
                      }
                      overallMonthlyAssessment={
                        pillarsData?.overallMonthlyAssessment || 0
                      }
                      monthPerformance={pillarsData?.monthPerformance || 0}
                      loading={loadingPillars}
                    />
                  </CardPillar>
                </SessionArea>
                <SessionArea className="w-100">
                  <TaskComponent
                    tasks={tasks}
                    isToView={true}
                    isMobile={isMobile}
                    openTaskModal={openTaskModal}
                    loading={loadingTasks}
                    filterCompletedTasks={filterCompletedTasks}
                    filterFavoritedTasks={filterFavoritedTasks}
                    filterCycleTasks={filterCycleTasks}
                    onFilterChange={handleFilterChange}
                  ></TaskComponent>
                </SessionArea>
                <SessionArea className="mt-3">
                  <Favorites
                    closeFavoriteModal={async () => {
                      setLoadingFavorites(true);
                      await loadData(
                        filterCompletedTasks,
                        filterFavoritedTasks,
                        filterCycleTasks
                      );
                      setLoadingFavorites(false);
                    }}
                    loading={loadingFavorites}
                    openTaskModal={openTaskModal}
                    favoriteItems={favoriteData}
                  />
                </SessionArea>
              </Session>
            </MainContent>
          )}
        </DashboardContainer>
      </>
    </MainContainer>
  );
};

export default DashBoard;
