import styled from "styled-components";

export const LeftSideLogin = styled.div`
  height: 100vh;
  background: #03001c;
  width: 55vw;
  @media (max-width: 762px) {
    display: none;
  }
`;

export const RightSideLogin = styled.div`
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  @media (max-width: 762px) {
    width: 100%;
  }
  form {
    width: 50%;
    height: 65%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 762px) {
      width: 80% !important;
    }
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: black !important;
  }

  .MuiFormLabel-root.Mui-focused {
    color: black !important;
  }

  .MuiOutlinedInput-root {
    border-radius: 9px;
  }

  .MuiFormControlLabel-label {
    font-size: 0.9rem !important;
  }

  .subscribe-text a,
  .forgot-passoword {
    font-size: 0.9rem !important;
    color: black;
    transition: 0.25s;
    cursor: pointer;
  }

  .subscribe-text a:hover,
  .forgot-passoword:hover {
    color: #3eff9f;
  }

  .submit-button {
    text-decoration: none;
    border: none;
    background: #03001c;
    border-radius: 12px;
    color: white;
    font-weight: 300;
    font-size: 1.3rem;
    transition: 0.25s;
  }

  .submit-button:hover {
    background: #3eff9f;
  }

  .subscribe-text {
    color: #626262 !important;
    font-size: 0.8rem;
  }

  .subscribe-text a {
    font-size: 0.8rem;
  }
`;
