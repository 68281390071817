/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { AiFillPlusCircle } from "react-icons/ai";
import { IoMdAdd } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { RingLoader } from "react-spinners";
import TaskComponent from "../../../../shared/components/task";
import { TQuickTask } from "../../../../shared/models/dashboard/pillars.model";
import {
  ActivitiesServices,
  CreateSelfCareHabitInput,
  ExerciseMemoryItem,
  ListSelfCareHabitsResponse,
  SelfCareHabitsTypeEnum,
  SelfCareHabitsTypeResponseEnum,
  SelfCareItem,
  ThoughtJournalItem,
} from "../../../../shared/services/activities/activities.service";
import { MessageServices } from "../../../../shared/services/message/message.service";
import CareModal from "../modal-care";
import ExerciciesModal from "../modal-execisies";
import ModalInsertTask from "../modal-insert-task";
import TaskModal from "../modal-task";
import ThrowModal from "../modal-throw";
import {
  AutoCareAddCard,
  AutoCareCardAction,
  AutoCareCardBody,
  AutoCareCardTitle,
  AutoCareItems,
  Card,
  CardActions,
  CardHead,
  CardTaskDate,
  CardTaskTitle,
  CardTitle,
  Container,
  ExercisiesActions,
  ExercisiesCard,
  ExercisiesCardBody,
  MobileContainer,
  MobileContainer2,
  MobileContainer3,
  ThoughtCard,
  ThoughtCardBody,
  ThoughtCardTop,
  ThoughtText,
} from "./style";
import "./style.css";

const ActivityCard = () => {
  const _activitiesService = new ActivitiesServices();
  const _messageService = new MessageServices();
  const [loading, setLoading] = useState(true);

  const setDefault = () => {
    return {
      throw: [],
      tasks: [],
      exercises: [],
      habits: { toAbandon: [], toAdopt: [] },
    };
  };

  const isMobile: boolean = /Mobi|Android/i.test(navigator.userAgent);
  const [throws, setThrowsItems] = useState<ThoughtJournalItem[] | null>(null);
  const [tasks, setTasksItems] = useState<TQuickTask[]>([]);
  const [exercises, setExercisiesItems] = useState<ExerciseMemoryItem[] | null>(
    []
  );
  const [habitsAbandon, setHabitsAbandonItems] = useState<
    ListSelfCareHabitsResponse["listSelfCareHabits"]
  >([]);
  const [habitsApdot, setHabitsAdoptItems] = useState<
    ListSelfCareHabitsResponse["listSelfCareHabits"]
  >([]);
  const [showTaskModal, setShowModal] = useState(false);
  const [showCareModal, setShowCareModal] = useState(false);
  const [showThrowModal, setShowThrowModal] = useState(false);
  const [showInsertTaskModal, setShowInsertTaskModal] = useState(false);
  const [showExercisiesModal, setShowExercisiesModal] = useState(false);
  const [modalTaskData, setModalTaskData] = useState(null);
  const [modalCareData, setModalCareData] =
    useState<CreateSelfCareHabitInput | null>(null);
  const [selfCareType, setSelfCareType] =
    useState<SelfCareHabitsTypeEnum | null>(null);
  const [modalThrowData, setModalThrowData] =
    useState<ThoughtJournalItem | null>(null);
  const [modalExercisiesData, setModalExercisiesData] =
    useState<ExerciseMemoryItem | null>(null);
  const navigate = useNavigate();

  const openTaskModal = (task: any) => {
    setModalTaskData(task);
    setShowModal(true);
  };

  const openEditTask = (task: any) => {
    setModalTaskData(task);
    setShowInsertTaskModal(true);
  };

  const closeTaskModal = () => {
    setShowModal(false);
  };

  const openCareModal = (
    selfCareType: SelfCareHabitsTypeEnum,
    item?: SelfCareItem
  ) => {
    setSelfCareType(selfCareType);
    setModalCareData(item ? { ...item, id: item._id } : null);
    setShowCareModal(true);
  };

  const closeCareModal = () => {
    setShowCareModal(false);
  };

  const openThrowModal = (item?: ThoughtJournalItem) => {
    setModalThrowData(item ? item : null);
    setShowThrowModal(true);
  };

  const closeThrowModal = () => {
    setShowThrowModal(false);
  };

  const loadDataQuickTask = async (isOnInit?: boolean) => {
    isOnInit && setLoading(true);
    const response = await _activitiesService.listQuickTasks(1, 100);
    setTasksItems(response);
    isOnInit && setLoading(false);
  };

  const loadDataSelfCare = async (isOnInit?: boolean) => {
    isOnInit && setLoading(true);
    const response = await _activitiesService.listSelfCareHabits(1, 100);
    const careItensToAbadon = response.filter(
      (selfCareItem) =>
        selfCareItem.type === SelfCareHabitsTypeResponseEnum.ToAbandon
    );
    const careItensToAdopt = response.filter(
      (selfCareItem) =>
        selfCareItem.type === SelfCareHabitsTypeResponseEnum.ToAdopt
    );
    setHabitsAbandonItems(careItensToAbadon);
    setHabitsAdoptItems(careItensToAdopt);
    isOnInit && setLoading(false);
  };

  const loadDataThowghtData = async (isOnInit?: boolean) => {
    isOnInit && setLoading(true);
    const response = await _activitiesService.listThoughtJournals(1, 100);
    setThrowsItems(response);
    isOnInit && setLoading(false);
  };

  const loadDataExerciseMemoryData = async (isOnInit?: boolean) => {
    isOnInit && setLoading(true);
    const response = await _activitiesService.listExerciseMemories(1, 100);
    setExercisiesItems(response);
    isOnInit && setLoading(false);
  };

  const loadData = async () => {
    if (isMobile) {
      setLoading(false);
      return;
    }
    setLoading(true);
    await loadDataQuickTask(true);
    await loadDataSelfCare(true);
    await loadDataThowghtData(true);
    await loadDataExerciseMemoryData(true);
    setLoading(false);
  };

  const openExerciciesModal = (item?: ExerciseMemoryItem) => {
    setModalExercisiesData(item ? item : null);
    setShowExercisiesModal(true);
  };

  const closeExerciciesModal = () => {
    setShowExercisiesModal(false);
  };

  const formatDate = (date: string): string => {
    const parsedDate = new Date(date);
    return format(parsedDate, "dd/MM - hh:mma");
  };

  const removeThow = async (item: ThoughtJournalItem): Promise<void> => {
    await _activitiesService.deleteThoughtJournal({ journalId: item._id });
    await loadDataThowghtData(true);
    _messageService.success("Sucesso!", "Item removido com sucesso.");
  };

  const removeExercisies = async (item: ExerciseMemoryItem): Promise<void> => {
    await _activitiesService.deleteExerciseMemory({ memoryId: item._id });
    await loadDataExerciseMemoryData(true);
    _messageService.success("Sucesso!", "Item removido com sucesso.");
  };

  const removeHabitsAbandon = async (item: SelfCareItem): Promise<void> => {
    await _activitiesService.deleteSelfCareHabit({ habitId: item._id });
    await loadDataSelfCare(true);
    _messageService.success("Sucesso!", "Item removido com sucesso.");
  };

  const removeHabitsApdot = async (item: SelfCareItem): Promise<void> => {
    await _activitiesService.deleteSelfCareHabit({ habitId: item._id });
    await loadDataSelfCare(true);
    _messageService.success("Sucesso!", "Item removido com sucesso.");
  };

  useEffect(() => {    
    loadData();
  }, []);

  return (
    <Container className="row">
      {!loading && (
        <>
          <TaskModal
            showModal={showTaskModal}
            closeModal={closeTaskModal}
            modalData={modalTaskData}
          />
          <ModalInsertTask
            refresh={() => {
              loadDataQuickTask();
            }}
            showModal={showInsertTaskModal}
            closeModal={() => {
              setShowInsertTaskModal(false);
              setModalTaskData(null);
            }}
            modalData={modalTaskData}
          />
          <ThrowModal
            showModal={showThrowModal}
            closeModal={closeThrowModal}
            modalData={modalThrowData}
            onSave={async function (): Promise<void> {
              await loadDataThowghtData(true);
            }}
          />
          <CareModal
            showModal={showCareModal}
            closeModal={closeCareModal}
            modalData={modalCareData}
            selfCareType={selfCareType}
            onSave={async function (): Promise<void> {
              await loadDataSelfCare(true);
            }}
          />
          <ExerciciesModal
            showModal={showExercisiesModal}
            closeModal={closeExerciciesModal}
            modalData={modalExercisiesData}
            onSave={async function (): Promise<void> {
              await loadDataExerciseMemoryData(true);
            }}
          />
          {!isMobile && (
            <div className="col-12 col-md-12 col-lg-6 card-activity">
              <TaskComponent
                onOpenCreateTask={() => {
                  setShowInsertTaskModal(true);
                }}
                tasks={tasks}
                refresh={() => {
                  loadDataQuickTask();
                }}
                isToView={false}
                isMobile={isMobile}
                openTaskModal={openTaskModal}
                onHandleEditTask={openEditTask}
              ></TaskComponent>
            </div>
          )}
          {!isMobile && (
            <div className="col-12 col-md-12 col-lg-6 card-activity mb-4">
              <Card>
                <CardHead style={{ backgroundColor: "#5b8fb9" }}>
                  <CardTitle>Hábitos para autocuidado</CardTitle>
                </CardHead>
                <AutoCareCardBody
                  style={{ overflow: "auto", maxHeight: "80%" }}
                  className="row"
                >
                  <div className="col-12 col-md-12 col-lg-6 card-activity ">
                    <AutoCareAddCard>
                      <AutoCareCardTitle>
                        Hábitos que quero <br /> <strong>adotar</strong>
                      </AutoCareCardTitle>
                      <AutoCareCardAction>
                        <IoMdAdd
                          size={20}
                          color="white"
                          onClick={() =>
                            openCareModal(SelfCareHabitsTypeEnum.ToAdopt)
                          }
                        />
                      </AutoCareCardAction>
                    </AutoCareAddCard>
                    <AutoCareItems
                      style={{
                        height:
                          habitsAbandon.length > 3 || isMobile ? "55%" : "100%",
                      }}
                    >
                      {habitsApdot.map((item: SelfCareItem) => (
                        <div className="col-12 mb-2" key={item._id}>
                          <ExercisiesCard>
                            {item.description}
                            <ExercisiesActions>
                              <MdEdit style={{ float: "right" }} size={20} />
                              <Dropdown>
                                <Dropdown.Toggle
                                  style={{ marginTop: -20, marginLeft: -15 }}
                                  variant="secondary"
                                  id="dropdown-button"
                                ></Dropdown.Toggle>
                                <Dropdown.Menu className="options-dropdown">
                                  <Dropdown.Item
                                    style={{
                                      color: "#ccc",
                                      fontWeight: "bold",
                                    }}
                                    onClick={() =>
                                      openCareModal(
                                        SelfCareHabitsTypeEnum.ToAdopt,
                                        item
                                      )
                                    }
                                  >
                                    Editar
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    style={{ color: "red", fontWeight: "bold" }}
                                    onClick={() => removeHabitsApdot(item)}
                                  >
                                    Excluir
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </ExercisiesActions>
                          </ExercisiesCard>
                        </div>
                      ))}
                    </AutoCareItems>
                  </div>
                  <div className="col-12 col-md-12 col-lg-6 card-activity ">
                    <AutoCareAddCard>
                      <AutoCareCardTitle>
                        Hábitos que quero <br />
                        <strong>abandonar</strong>
                      </AutoCareCardTitle>
                      <AutoCareCardAction>
                        <IoMdAdd
                          size={20}
                          color="white"
                          onClick={() =>
                            openCareModal(SelfCareHabitsTypeEnum.ToAbandon)
                          }
                        />
                      </AutoCareCardAction>
                    </AutoCareAddCard>
                    <AutoCareItems
                      style={{
                        height:
                          habitsAbandon.length > 3 || isMobile ? "55%" : "100%",
                      }}
                    >
                      {habitsAbandon.map((item: SelfCareItem) => (
                        <div className="col-12 mb-2" key={item._id}>
                          <ExercisiesCard>
                            {item.description}
                            <ExercisiesActions>
                              <MdEdit style={{ float: "right" }} size={20} />
                              <Dropdown>
                                <Dropdown.Toggle
                                  style={{ marginTop: -20, marginLeft: -15 }}
                                  variant="secondary"
                                  id="dropdown-button"
                                ></Dropdown.Toggle>
                                <Dropdown.Menu className="options-dropdown">
                                  <Dropdown.Item
                                    style={{
                                      color: "#ccc",
                                      fontWeight: "bold",
                                    }}
                                    onClick={() =>
                                      openCareModal(
                                        SelfCareHabitsTypeEnum.ToAbandon,
                                        item
                                      )
                                    }
                                  >
                                    Editar
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    style={{ color: "red", fontWeight: "bold" }}
                                    onClick={() => removeHabitsAbandon(item)}
                                  >
                                    Excluir
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </ExercisiesActions>
                          </ExercisiesCard>
                        </div>
                      ))}
                    </AutoCareItems>
                  </div>
                </AutoCareCardBody>
              </Card>
            </div>
          )}
          {isMobile && (
            <MobileContainer
              style={{ backgroundColor: "#35db88" }}
              className="my-3 animate__animated animate__fadeInDown"
              onClick={() => {
                navigate("/activities/quickTask");
              }}
            >
              <MobileContainer2>Tarefas diárias fáceis</MobileContainer2>
              <MobileContainer3>
                De acordo com suas necessidades,
                <br />
                iremos cuidar de você! De acordo com suas necessidades, iremos
                cuidar de você!
              </MobileContainer3>
            </MobileContainer>
          )}
          {isMobile && (
            <MobileContainer
              className="my-3 animate__animated animate__fadeInDown"
              onClick={() => {
                navigate("/activities/selfCareHabitsChoice");
              }}
            >
              <MobileContainer2>Hábitos para autocuidado</MobileContainer2>
              <MobileContainer3>
                De acordo com suas necessidades,
                <br />
                iremos cuidar de você! De acordo com suas necessidades, iremos
                cuidar de você!
              </MobileContainer3>
            </MobileContainer>
          )}
          {isMobile && (
            <MobileContainer
              style={{ backgroundColor: "#01C2B5" }}
              className="my-3 animate__animated animate__fadeInDown"
              onClick={() => {
                navigate("/activities/thoughtJornals");
              }}
            >
              <MobileContainer2>Diário de pensamento</MobileContainer2>
              <MobileContainer3>
                De acordo com suas necessidades,
                <br />
                iremos cuidar de você! De acordo com suas necessidades, iremos
                cuidar de você
              </MobileContainer3>
            </MobileContainer>
          )}
          {isMobile && (
            <MobileContainer
              style={{ backgroundColor: "#482aa7" }}
              className="my-3 animate__animated animate__fadeInDown"
              onClick={() => {
                navigate("/activities/exerciseMemory");
              }}
            >
              <MobileContainer2>Exercício de trazer memória</MobileContainer2>
              <MobileContainer3>
                De acordo com suas necessidades,
                <br />
                iremos cuidar de você! De acordo com suas necessidades, iremos
                cuidar de você
              </MobileContainer3>
            </MobileContainer>
          )}
          {!isMobile && (
            <div className="col-12 col-md-12 col-lg-6 card-activity mb-4">
              <Card>
                <CardHead style={{ backgroundColor: "#01c2b5" }}>
                  <CardTitle>Diário de pensamento</CardTitle>
                  <AiFillPlusCircle
                    size={25}
                    style={{
                      color: "#fff",
                      marginRight: 25,
                      cursor: "pointer",
                    }}
                    onClick={() => openThrowModal()}
                  />
                </CardHead>
                <ThoughtCardBody
                  className="row"
                  style={{
                    overflowY:
                      (throws && throws?.length > 2) || isMobile
                        ? "auto"
                        : "hidden",
                  }}
                >
                  {throws && throws?.length > 0 ? (
                    throws?.map((item: ThoughtJournalItem) => (
                      <div
                        className="col-12 col-md-12 col-lg-6 card-activity mb-2 h-100"
                        key={item._id}
                      >
                        <ThoughtCard style={{ overflow: "hidden" }}>
                          <ThoughtCardTop>
                            <CardTaskTitle
                              className="mb-3"
                              style={{
                                overflow: "hidden",
                                pointerEvents: "none",
                              }}
                            >
                              {item.title}
                            </CardTaskTitle>
                            <CardActions
                              style={{ position: "relative", top: "-15px" }}
                            >
                              <MdEdit size={20} />
                              <Dropdown>
                                <Dropdown.Toggle
                                  style={{ marginTop: -20, marginLeft: -15 }}
                                  variant="secondary"
                                  id="dropdown-button"
                                ></Dropdown.Toggle>
                                <Dropdown.Menu className="options-dropdown">
                                  <Dropdown.Item
                                    style={{
                                      color: "#ccc",
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => openThrowModal(item)}
                                  >
                                    Editar
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    style={{
                                      color: "red",
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => removeThow(item)}
                                  >
                                    Excluir
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </CardActions>
                            <CardTaskDate>{formatDate(item.date)}</CardTaskDate>
                          </ThoughtCardTop>
                          <ThoughtText>
                            <p
                              style={{
                                overflow: "hidden",
                                wordWrap: "break-word",
                              }}
                            >
                              {item.description}
                            </p>
                          </ThoughtText>
                        </ThoughtCard>
                      </div>
                    ))
                  ) : (
                    <h4 className="d-flex justify-content-center align-items-center">
                      Nenhum item foi encontrado para exibição.
                    </h4>
                  )}
                </ThoughtCardBody>
              </Card>
            </div>
          )}
          {!isMobile && (
            <div className="col-12 col-md-12 col-lg-6 card-activity mb-4">
              <Card>
                <CardHead style={{ backgroundColor: "#482aa7" }}>
                  <CardTitle>Exercício de trazer a memória</CardTitle>
                  <AiFillPlusCircle
                    size={25}
                    style={{
                      color: "#fff",
                      marginRight: 25,
                      cursor: "pointer",
                    }}
                    onClick={() => openExerciciesModal()}
                  />
                </CardHead>
                <ExercisiesCardBody
                  className="row"
                  style={{
                    overflowY:
                      (exercises && exercises?.length > 5) || isMobile
                        ? "auto"
                        : "hidden",
                    height: exercises && exercises?.length > 5 ? "80%" : "90%",
                  }}
                >
                  {exercises && exercises?.length > 0 ? (
                    exercises?.map((item: ExerciseMemoryItem) => (
                      <div className="col-12 mb-2" key={item._id}>
                        <ExercisiesCard>
                          {item.description}
                          <ExercisiesActions>
                            <MdEdit style={{ float: "right" }} size={20} />
                            <Dropdown>
                              <Dropdown.Toggle
                                style={{ marginTop: -20, marginLeft: -15 }}
                                variant="secondary"
                                id="dropdown-button"
                              ></Dropdown.Toggle>
                              <Dropdown.Menu className="options-dropdown">
                                <Dropdown.Item
                                  style={{ color: "#ccc", fontWeight: "bold" }}
                                  onClick={() => openExerciciesModal(item)}
                                >
                                  Editar
                                </Dropdown.Item>
                                <Dropdown.Item
                                  style={{ color: "red", fontWeight: "bold" }}
                                  onClick={() => removeExercisies(item)}
                                >
                                  Excluir
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </ExercisiesActions>
                        </ExercisiesCard>
                      </div>
                    ))
                  ) : (
                    <h4 className="d-flex justify-content-center align-items-center">
                      Nenhum item foi encontrado para exibição.
                    </h4>
                  )}
                </ExercisiesCardBody>
              </Card>
            </div>
          )}
        </>
      )}
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RingLoader color="#00df70" loading={true} />
        </div>
      )}
    </Container>
  );
};
export default ActivityCard;
