/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { MdEdit } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { RingLoader } from "react-spinners";
import styled from "styled-components";
import {
  ActivitiesServices,
  CreateSelfCareHabitInput,
  ListSelfCareHabitsResponse,
  SelfCareHabitsTypeEnum,
  SelfCareHabitsTypeResponseEnum,
  SelfCareItem,
} from "../../../../../shared/services/activities/activities.service";
import { MessageServices } from "../../../../../shared/services/message/message.service";
import { ExercisiesActions } from "../../../components/activity-card/style";
import CareModal from "../../../components/modal-care";

export function SelfCareHabits() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [habitsAbandon, setHabitsAbandonItems] = useState<
    ListSelfCareHabitsResponse["listSelfCareHabits"]
  >([]);
  const [modalCareData, setModalCareData] =
    useState<CreateSelfCareHabitInput | null>(null);
  const [selfCareType, setSelfCareType] =
    useState<SelfCareHabitsTypeEnum | null>(null);
  const [habitsAdopt, setHabitsAdoptItems] = useState<
    ListSelfCareHabitsResponse["listSelfCareHabits"]
  >([]);
  const [showCareModal, setShowCareModal] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const _activitiesService = new ActivitiesServices();
  const _messageService = new MessageServices();

  const loadDataSelfCare = useCallback(
    async (pageNum: number) => {
      setLoading(true);
      const response = await _activitiesService.listSelfCareHabits(pageNum, 40);
      const careItemsToAbandon = response.filter(
        (selfCareItem) =>
          selfCareItem.type === SelfCareHabitsTypeResponseEnum.ToAbandon
      );
      const careItemsToAdopt = response.filter(
        (selfCareItem) =>
          selfCareItem.type === SelfCareHabitsTypeResponseEnum.ToAdopt
      );
      if (pageNum === 1) {
        setHabitsAbandonItems(careItemsToAbandon);
        setHabitsAdoptItems(careItemsToAdopt);
      } else {
        setHabitsAbandonItems((prev) => [...prev, ...careItemsToAbandon]);
        setHabitsAdoptItems((prev) => [...prev, ...careItemsToAdopt]);
      }
      setHasMore(response.length === 10);
      setLoading(false);
    },
    [_activitiesService]
  );

  useEffect(() => {
    loadDataSelfCare(page);
  }, [page]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    )
      return;
    if (hasMore && !loading) {
      setPage((prev) => prev + 1);
    }
  }, [hasMore, loading]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const openCareModal = (
    selfCareType: SelfCareHabitsTypeEnum,
    item?: SelfCareItem
  ) => {
    setSelfCareType(selfCareType);
    setModalCareData(item ? { ...item, id: item._id } : null);
    setShowCareModal(true);
  };

  const closeCareModal = () => {
    setShowCareModal(false);
  };

  const isAbandonPage = location.pathname.includes(
    "/activities/selfCareHabits/abadon"
  );
  const isAdoptPage = location.pathname.includes(
    "/activities/selfCareHabits/adopt"
  );

  const removeHabitsApdot = async (item: SelfCareItem): Promise<void> => {
    await _activitiesService.deleteSelfCareHabit({ habitId: item._id });
    await loadDataSelfCare(1);
    _messageService.success("Sucesso!", "Item removido com sucesso.");
  };

  return (
    <>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 100000000000,
          }}
        >
          <RingLoader color="#00df70" loading={true} />
        </div>
      )}
      <CareModal
        showModal={showCareModal}
        closeModal={closeCareModal}
        modalData={modalCareData}
        selfCareType={selfCareType}
        onSave={async function (): Promise<void> {
          await loadDataSelfCare(1);
        }}
      />
      <Div>
        <Div2>
          <Div3
            className="animate__animated animate__fadeInDown"
            onClick={() => {
              navigate("/activities/selfCareHabitsChoice");
            }}
          >
            <Img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/7ffbf144e6de71b0b5b542f4d11ee768668213ff334c878b386f4b0f777ba839?apiKey=a7d67dd512364d7190520da778c77636&"
            />
            <Div4>
              Hábitos que quero
              <span
                className="ms-1"
                style={{ fontWeight: 700, color: "rgba(0,0,0,1)" }}
              >
                {isAbandonPage ? "abandonar" : "adotar"}
              </span>
            </Div4>
          </Div3>
          <Img2
            onClick={() =>
              openCareModal(
                isAbandonPage
                  ? SelfCareHabitsTypeEnum.ToAbandon
                  : SelfCareHabitsTypeEnum.ToAdopt
              )
            }
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/1fcc0d6a27459c90ef9a04c386588551300765a74202f97754b83adca60baafc?apiKey=a7d67dd512364d7190520da778c77636&"
          />
        </Div2>
        <Div5>
          {isAbandonPage && habitsAbandon.length === 0 && (
            <Div6>Não há hábitos para abandonar.</Div6>
          )}
          {isAbandonPage &&
            habitsAbandon.map((habit) => (
              <Div6
                className="my-2 d-flex  justify-content-between align-items-center"
                key={habit._id}
              >
                {habit.description}
                <ExercisiesActions>
                  <MdEdit style={{ float: "right" }} size={20} />
                  <Dropdown>
                    <Dropdown.Toggle
                      style={{ marginTop: -20, marginLeft: -15 }}
                      variant="secondary"
                      id="dropdown-button"
                    ></Dropdown.Toggle>
                    <Dropdown.Menu className="options-dropdown">
                      <Dropdown.Item
                        style={{
                          color: "#ccc",
                          fontWeight: "bold",
                        }}
                        onClick={() =>
                          openCareModal(SelfCareHabitsTypeEnum.ToAbandon, habit)
                        }
                      >
                        Editar
                      </Dropdown.Item>
                      <Dropdown.Item
                        style={{ color: "red", fontWeight: "bold" }}
                        onClick={() => removeHabitsApdot(habit)}
                      >
                        Excluir
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </ExercisiesActions>
              </Div6>
            ))}
          {isAdoptPage && habitsAdopt.length === 0 && (
            <Div6>Não há hábitos para adotar.</Div6>
          )}
          {isAdoptPage &&
            habitsAdopt.map((habit) => (
              <Div6
                className="my-2 d-flex justify-content-between align-items-center "
                key={habit._id}
              >
                {habit.description}
                <ExercisiesActions>
                  <MdEdit style={{ float: "right" }} size={20} />
                  <Dropdown>
                    <Dropdown.Toggle
                      style={{ marginTop: -20, marginLeft: -15 }}
                      variant="secondary"
                      id="dropdown-button"
                    ></Dropdown.Toggle>
                    <Dropdown.Menu className="options-dropdown">
                      <Dropdown.Item
                        style={{
                          color: "#ccc",
                          fontWeight: "bold",
                        }}
                        onClick={() =>
                          openCareModal(SelfCareHabitsTypeEnum.ToAdopt, habit)
                        }
                      >
                        Editar
                      </Dropdown.Item>
                      <Dropdown.Item
                        style={{ color: "red", fontWeight: "bold" }}
                        onClick={() => removeHabitsApdot(habit)}
                      >
                        Excluir
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </ExercisiesActions>
              </Div6>
            ))}
        </Div5>
      </Div>
    </>
  );
}

const Div = styled.div`
  background-color: #fdfdfd;
  display: flex;
  max-width: 480px;
  width: 100%;
  padding-bottom: 80px;
  flex-direction: column;
  font-size: 13px;
  color: #000;
  font-weight: 500;
  line-height: 100.5%;
  margin: 0 auto;
`;

const Div2 = styled.div`
  backdrop-filter: blur(10px);
  border-color: rgba(246, 246, 246, 1);
  border-style: solid;
  border-width: 1px;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  width: 100%;
  align-items: start;
  gap: 20px;
  justify-content: space-between;
  padding: 56px 18px 29px;
`;

const Div3 = styled.div`
  display: flex;
  gap: 6px;
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 16px;
`;

const Div4 = styled.div`
  font-family: Poppins, sans-serif;
  flex-grow: 1;
  flex-basis: auto;
`;

const Img2 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 19px;
`;

const Div5 = styled.div`
  align-self: center;
  display: flex;
  margin-top: 14px;
  width: 100%;
  max-width: 350px;
  flex-direction: column;
`;

const Div6 = styled.div`
  font-family: Poppins, sans-serif;
  border-radius: 8px;
  border-color: rgba(248, 248, 248, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #f6f6f6;
  align-items: start;
  justify-content: center;
  padding: 20px 14px;
`;
