/* eslint-disable react-hooks/exhaustive-deps */
import ptLocale from "@fullcalendar/core/locales/pt-br";
import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import { Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";

import { createRoot } from "react-dom/client";
import { AiOutlineClose } from "react-icons/ai";
import badFaceIcon from "../../../assets/img/bad_face_icon.svg";
import emptyFaceIcon from "../../../assets/img/empty_face_icon.svg";
import happyFaceIcon from "../../../assets/img/happy_face_icon.svg";
import neutralFaceIcon from "../../../assets/img/neutral_face_icon.svg";
import sadFaceIcon from "../../../assets/img/sad_face_icon.svg";
import smileFaceIcon from "../../../assets/img/smile_face_icon.svg";
import { Mood } from "../../enum/moodEnum";
import {
  CalendarHumorProps,
  IMoodCalendarData,
} from "../../models/humor/humor.modal";
import MoodService from "../../services/mood/mood.service";
import MoodSelection from "../mood-selection";
import "./calendar.scss";
import { CalendarMoodImage, ModalContent, ModalOverlay } from "./style";

const CalendarHumor: React.FC<CalendarHumorProps> = ({ onClose }) => {
  const [moodData, setMoodData] = useState<IMoodCalendarData[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [showMoodSelection, setShowMoodSelection] = useState<boolean>(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const _moodService = new MoodService();
  const headerToolbarOptions = {
    left: "title prev,next",
    center: "",
    right: "",
  };

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const loadData = async (start: Date) => {
    const result = await _moodService.getMoodByMonth(start);
    const formattedResult: IMoodCalendarData[] = result?.map(
      (data: IMoodCalendarData) => {
        const formattedDate = new Date(data.date).toISOString().split("T")[0];
        return {
          ...data,
          date: formattedDate,
        };
      }
    );
    const currentDate = new Date(start);
    const endDate = new Date(currentDate);
    endDate.setMonth(endDate.getMonth() + 1);
    while (currentDate <= endDate) {
      const dateStr = formatDate(currentDate);
      if (!formattedResult?.some((data: any) => data.date === dateStr)) {
        formattedResult?.push({
          date: dateStr,
          type: Mood.Empty,
        });
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    formattedResult.sort(
      (a: any, b: any) =>
        new Date(a.date).getTime() - new Date(b.date).getTime()
    );
    setMoodData(formattedResult);
    setLoading(false);
  };

  const getEventIcon = (mood: Mood) => {
    switch (mood) {
      case Mood.Happy:
        return <CalendarMoodImage src={happyFaceIcon} alt="" />;
      case Mood.Sad:
        return <CalendarMoodImage src={sadFaceIcon} alt="" />;
      case Mood.Smile:
        return <CalendarMoodImage src={smileFaceIcon} alt="" />;
      case Mood.Bad:
        return <CalendarMoodImage src={badFaceIcon} alt="" />;
      case Mood.Neutral:
        return <CalendarMoodImage src={neutralFaceIcon} alt="" />;
      default:
        return <CalendarMoodImage src={emptyFaceIcon} alt="" />;
    }
  };

  const toggleMoodSelection = () => {
    setShowMoodSelection(!showMoodSelection);
    if (showMoodSelection) loadData(currentMonth);
  };

  useEffect(() => {
    loadData(currentMonth);
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        document.body.style.overflow = "auto";
        onClose();
      }
    };
    window.addEventListener("keydown", handleEscKey);

    const handleClickOutside = (event: MouseEvent) => {
      const modalContent = document.querySelector(".modalContent");
      if (modalContent && !modalContent.contains(event.target as Node)) {
        document.body.style.overflow = "auto";
        onClose();
      }
    };
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("keydown", handleEscKey);
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [currentMonth, onClose]);

  const eventContent = (info: any) => {
    const mood = info.event.extendedProps.type;
    const moodIcon = getEventIcon(mood);
    const container = document.createElement("div");
    const moodIconContainer = document.createElement("div");
    const timeText = document.createElement("div");
    const titleText = document.createElement("div");
    timeText.innerText = info.timeText;
    titleText.innerText = info.event.title;
    container.appendChild(timeText);
    container.appendChild(titleText);
    container.appendChild(moodIconContainer);
    const root = createRoot(container);
    root.render(moodIcon);
    container.addEventListener("click", () => {
      setSelectedDate(info.event.startStr);
      toggleMoodSelection();
    });
    return { domNodes: [container] };
  };

  return (
    <>
      {showMoodSelection ? (
        <MoodSelection
          onClose={toggleMoodSelection}
          receivedDate={selectedDate}
        />
      ) : (
        <ModalOverlay className="modal-overlay">
          <ModalContent className="p-5 modalContent animate__animated animate__fadeInDown animate__faster position-relative">
            <div
              onClick={onClose}
              className="close-button px-2 py-1 position-absolute"
            >
              <AiOutlineClose />
            </div>
            <div className="w-100 h-100">
              {!loading ? (
                <FullCalendar
                  plugins={[dayGridPlugin]}
                  initialView="dayGridMonth"
                  events={moodData}
                  eventContent={eventContent}
                  showNonCurrentDates={false}
                  headerToolbar={headerToolbarOptions}
                  locale={ptLocale}
                  dayHeaderContent={(args) => {
                    const firstLetter = args.text.charAt(0);
                    return <div>{firstLetter}</div>;
                  }}
                  datesSet={async (arg) => {
                    setCurrentMonth(arg.start);
                  }}
                />
              ) : (
                <>
                  <Skeleton
                    variant="rounded"
                    className="loading-screen mt-3"
                    width={210}
                    height={520}
                  />
                </>
              )}
            </div>
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
};

export default CalendarHumor;
