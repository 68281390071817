import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background-color: white;
  min-width: 972px;
  min-height: 435px;
  @media (max-width: 762px) {
    min-height: 100dvh !important;
    min-width: 100dvw !important;
  }
  .items {
    @media (max-width: 762px) {
      scale: 0.6;
    }
  }
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  .submit-mood-selection {
    button {
      color: white;
      font-size: 1.3rem;
      min-width: 340px;
      background: black;

      &:hover {
        background: #00df70;
      }
    }
  }
`;

export const MoodChoiceButton = styled.img`
  transition: 0.25s all;
  border-radius: 50%;
  padding: 3px;
  cursor: pointer;
  object-fit: cover;
  &:hover {
    transform: scale(1.2);
    outline: 3px solid #00df70;
  }
  &.selected {
    outline: 3px solid #00df70;
  }
`;
