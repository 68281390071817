import dayGridPlugin from "@fullcalendar/daygrid";
import { CalendarArea, StyledFullCalendar } from "./style"; // Importe a área da listagem de eventos
import "./style.scss";

const DashBoardCalendar = () => {
  const dayHeaderContent = ({ date }: { date: Date }) => {
    const day = date
      .toLocaleDateString("pt-BR", { weekday: "short" })
      .charAt(0);
    return <div>{day}</div>;
  };

  return (
    <CalendarArea>
      <StyledFullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        dayHeaderContent={dayHeaderContent}
        locale="pt-br"
        headerToolbar={{
          start: "",
          center: "title",
          end: "prev,next",
        }}
        views={{
          customDayGrid: {
            type: "dayGrid",
            dayHeaderContent: dayHeaderContent,
          },
        }}
        titleFormat={{
          year: "numeric",
          month: "long",
        }}
      />

      {/* <EventList>
        {upcomingEvents.map((event: any) => (
          <div
            className="d-flex align-items-center event-content mb-4"
            key={event.date}
          >
            <div className="icon me-3">
              <MdGroup></MdGroup>
            </div>
            <div className="event-container d-flex flex-column">
              <h5 className="m-0">{event.title}</h5>
              <span className="mt-1">{event.date}</span>
            </div>
          </div>
        ))}
      </EventList> */}
    </CalendarArea>
  );
};

export default DashBoardCalendar;
