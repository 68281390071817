import {
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import styled from "styled-components";

export const StyledTableContainer: any = styled(TableContainer)`
  margin-top: "20px";
  border-radius: "8px";
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  tr {
    height: 10px;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 10;
  }

  tr td {
    height: auto !important;
  }
`;

export const StyledTablePagination: any = styled(TablePagination)`
  p {
    margin: 0 !important;
  }
`;

export const StyledTableHead = styled(TableHead)({
  backgroundColor: "#00df70",
});

export const StyledTableRow = styled(TableRow)`
  transition: 0.25s;
  cursor: pointer;
  &:hover {
    * {
      color: white;
    }
    background: #9cffcd;
  }
`;

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  color: "#fff",
}));

export const StyledTableSortLabel = styled(TableSortLabel)({
  color: "#fff !important",
  "&.Mui-active": {
    color: "#fff !important",
  },
  "& .MuiTableSortLabel-icon": {
    color: "#fff !important",
  },
});

export const ActionTableCell = styled(TableCell)({
  width: "150px",
  textAlign: "center",
  color: "#fff !important",
  "&.Mui-active": {
    color: "#fff !important",
  },
  "& .MuiTableSortLabel-icon": {
    color: "#fff !important",
  },
});

export const HighlightedTableCell = styled(TableCell)({
  fontWeight: "bold",
});
