import { gql } from "@apollo/client";

const GET_USER = gql`
  query GetUser {
    getUser {
      name
      email
      phoneNumber
      isContentCreator
    }
  }
`;

export default GET_USER;
